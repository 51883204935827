import React, {useContext, useEffect, useState} from "react";
import './Forms.scss';
import {useForm} from 'react-hook-form'
import {submitForm, VerifyUserData} from "../../utils/helpers/submitForm";
import {LoginContext} from "../../context/login.context";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";
import {REACT_APP_VERIFY_USER} from '../../utils/helpers/urlConstant';
import {LogoContainer} from "../../components/Logo/LogoContainer";
import raceInformationIcon from '../../assets/race_information_icon.svg'
import liveFeedsIcon from '../../assets/live_feed_icon.svg'
import replaysIcon from '../../assets/replay_icon.svg'

interface Content {
    title: string,
    icon: string,
    text: string
}

interface cookieObj {
    httpOnly: boolean,
    secure: boolean,
    sameSite: string,
    maxAge: number,
    domain: string
}



const LoginPage = () => {
    const {register, handleSubmit, formState: {errors}} = useForm({mode: 'onBlur'});
    const {setLoginStatus} = useContext(LoginContext)
    const navigate = useHistory();
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

    const popoverContent: Content[] = [
        {
            title: 'Race Information',
            icon: raceInformationIcon,
            text: 'Real-time access to live dashboard statistics, such as boat positions, time, current leg, ride height and speeds. With over 30,000 data points coming out of an F50, there is something interesting for everyone.'
        }, {
            title: 'Live Race Feeds',
            icon: liveFeedsIcon,
            text: 'To complement the live broadcast and live race statistics,  insights provides 360 onboard video feeds to watch the race from any angle of choice, and to follow your favourite team.'
        },
        {
            title: 'Replays',
            icon: replaysIcon,
            text: 'Watch the races live, or catch the full Race Day 1 and Race Day 2 replay videos at any time. The replay option supports the live video and live statistic feeds, so you never have to worry about missing a moment of the action.'
        }]

    useEffect(() => {
        (async () => {
            let search = `${window.location.search}`;
            let urlParams = new URLSearchParams(search);

            let token = null;
            if (urlParams.get('token')) {

                // @ts-ignore
                token = decodeURIComponent(urlParams.get('token'));
            }

            if (token && token !== "null" && token.length !== 0) {

                const verifyUserData: VerifyUserData = {
                    Token: token
                }
                try {
                    let verifyUserResponse = await submitForm(verifyUserData, REACT_APP_VERIFY_USER);
                    if (verifyUserResponse != undefined && verifyUserResponse.response.status != undefined && verifyUserResponse.response.status == "success") {
                        toast.success(verifyUserResponse.response.description, {
                            position: toast.POSITION.TOP_CENTER, autoClose: 2000
                        });
                    } else if (verifyUserResponse.response.status == "error") {
                        toast.error(verifyUserResponse.response.status, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: false,
                        });
                    } else {
                        toast.error("Invalid token", {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: false,
                        });
                    }
                } catch (e) {
                    // Error
                }
                navigate.push(`${window.location.pathname}`);
            }

        })()
    }, [])

    const onSubmit = async (data: any) => {

        const cookieObj = {
            httpOnly: true,
            secure: true,
            sameSite: 'None',
            domain:'.dev.sailgp.com',
            maxAge: 20 * 60 * 1000 // Set to 20- minutes				
        }
        let localCookieData = localStorage.getItem('cookieObj');
        if(localCookieData == null){
            localStorage.setItem('cookieObj', JSON.stringify(cookieObj));
            localCookieData = localStorage.getItem('cookieObj');
        }

        const loginData = {
            ...data,
            successUrl: `${window.location.origin}/schedule`,
            successUrl2: `${window.location.origin}/schedule`,
            localStorageData : localCookieData,
            applicationName: process.env.REACT_APP_APPLICATION_NAME,
        };
        const loginToast = toast.loading("Logging in...");
        if (process.env.REACT_APP_LOGIN_URL) {
            const loginResponse = await submitForm(
                loginData,
                process.env.REACT_APP_LOGIN_URL
            );
            toast.dismiss(loginToast);

            if (loginResponse && loginResponse.status === "success") {
                setLoginStatus(true, loginResponse.userDetails);
            } else if (
                loginResponse &&
                loginResponse.status === "failed" &&
                loginResponse.error_description
            ) {
                toast.error(loginResponse.error_description);
            } else {
                toast.dismiss(loginToast);
                toast.error("There has been a problem logging in.");
            }
        } else {
            toast.dismiss(loginToast);
            toast.error("There has been a problem logging in.");
        }
    };

    return (
        <>
            <div className={`insightsBackgroundContainer ${isPopoverOpen ? 'popoverOpen' : ''}`}>
                <LogoContainer/>
                <div className='insightsForm__homepageContainer'>
                    <div className='insightsForm__signUpSection'>
                        <div className='insightsForm__section'>
                            <h1 className='insightsForm__title-large'>Sign up for free</h1>
                            <p className='insightsForm__text insightsForm__text-info'>Get rewarded for interacting with
                                all
                                things SailGP and
                                earn exclusive experiences, discounts
                                and
                                more.</p>
                            <a
                                href="/register"
                                className="insightsForm__button insightsForm__button--primary insightsForm__button--margin"
                            >
                                <span className='insightsForm__button__label'>
                                   Register
                                </span>

                            </a>
                        </div>

                    </div>
                    <div className='insightsForm__loginSection'>
                        <form className="insightsForm" onSubmit={handleSubmit(onSubmit)}>
                            <h1 className='insightsForm__title-large'>Log in</h1>
                            <label className="insightsForm__label insightsForm__label-top" htmlFor="emailAddress">
                                E-mail Address
                            </label>
                            <input
                                className={`insightsForm__input ${
                                    errors.emailAddress ? "insightsForm__input--error" : ""
                                } `}
                                type="text"
                                {...register("emailAddress", {
                                    required: true,
                                    pattern: /^\S+@\S+\.\S+$/g,
                                })}
                            />
                            {errors.emailAddress && errors.emailAddress.type === "required" && (
                                <p className="insightsForm__error">This field is required</p>
                            )}
                            {errors.emailAddress && errors.emailAddress.type === "pattern" && (
                                <p className="insightsForm__error">Invalid email format</p>
                            )}
                            <label className="insightsForm__label" htmlFor="password">
                                Password
                            </label>
                            <input
                                className={`insightsForm__input ${
                                    errors.password ? "insightsForm__input--error" : ""
                                } `}
                                type="password"
                                {...register("password", {required: true})}
                            />
                            {errors.password && (
                                <p className="insightsForm__error">This field is required</p>
                            )}
                            <div className="insightsForm__forgotPassword">
                                <a
                                    target="_blank"
                                    href="/forgot-password"
                                    className="insightsForm__link"
                                >
                                    Forgot Password?
                                </a>
                            </div>
                            <button
                                className={`insightsForm__submit ${
                                    Object.keys(errors).length > 0
                                        ? "insightsForm__submit--disabled"
                                        : ""
                                }`}
                                onClick={handleSubmit(onSubmit)}
                                type="submit"
                                disabled={Object.keys(errors).length > 0}
                            >
                                <p className="insightsForm__email">Continue</p>
                                <img
                                    className="insightsForm__icon"
                                    src={
                                        Object.keys(errors).length > 0
                                            ? "/Sign_in_circle_disabled.svg"
                                            : "/Sign_in_circle_enabled.svg"
                                    }
                                    alt=""
                                />
                            </button>
                        </form>
                    </div>
                </div>

            </div>
            <div className={`insightsForm__popoverContainer ${isPopoverOpen ? 'popoverOpen' : ''}`}>
                <div className={`insightsForm__popoverContent ${isPopoverOpen ? 'popoverOpen' : ''}`}>
                    <div className='insightsForm__popoverContent--container'>
                        <div className={`insightsForm__popoverContent--text ${isPopoverOpen ? 'popoverOpen' : ''}`}>
                            <h2>What is SailGP Insights?</h2>
                            <p>Powered by Oracle, SailGP Insights is an online dashboard which provides fans with huge
                                amounts of real-time race data and live broadcast videos from our cutting-edge racing
                                events. Every second of every race is tracked by SailGP Insights, and
                                fans can follow every team on the dashboard to discover and understand key in-race
                                statistics and incidents, with all the data pulled from the boats in real-time.</p>
                            <p>
                                SailGP's Global Data Partner, Oracle, powers SailGP Insights, with Oracle Cloud
                                Infrastructure delivering the real-time data to fans worldwide - as well as the
                                teams themselves and also SailGP’s broadcast partners.
                            </p>
                            <p>
                                By creating an account you also get access to The Dock, SailGP's free loyalty program
                                which brings fans closer than ever before to the world's most exciting racing on water.
                                Each season is your chance to connect, engage, get inspired and get rewarded - levelling
                                up through tiers to prove your fandom. Head over to <a target='_blank' rel="noreferrer"
                                                                                       href='https://www.sailgp.com'
                                                                                       className='insightsForm__popoverContent--link'>SailGP.com</a> to
                                start earning points and collecting rewards.
                            </p>
                        </div>

                        <ul className={`insightsForm__contentCardsList ${isPopoverOpen ? 'popoverOpen' : ''}`}>
                            {isPopoverOpen ?
                                popoverContent.map(content => {
                                    return <li className='insightsForm__contentCard'>
                                        <div className='insightsForm__contentCard--container'>
                                            <img className='insightsForm__cardIcon' src={content.icon} alt=''/>
                                            <h3>{content.title}</h3>
                                            <p>{content.text}</p>
                                        </div>

                                    </li>
                                })
                                : null}

                        </ul>
                    </div>
                </div>
                <button className={`insightsForm__popoverButton ${isPopoverOpen ? 'popoverOpen' : ''}`}
                        onClick={() => setIsPopoverOpen(prevState => !prevState)}>
                    <p className={`insightsForm__popoverButton--content ${isPopoverOpen ? 'popoverOpen' : ''}`}>
                        {isPopoverOpen ? 'Close' : 'What is SailGP Insights?'}
                    </p>
                </button>
            </div>
        </>

    );
};

export default LoginPage;
