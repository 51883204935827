import {useEffect, useState} from "react";

export function useWindowSize() {
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        function updateSize() {
            setHeight(window.innerHeight);
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return {height, width};
}