import React, {PropsWithChildren, useState} from 'react';
import {AutoPlayContext} from "./autoPlay.context"


const AutoPlayProvider = ({children}:PropsWithChildren<{}>) => {
    const [currentTime, setCurrentTime] = useState<number>(0)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [videoDuration, setVideoDuration] = useState<number>(0)
    const [autoPlayNext, setAutoPlayNext] = useState<boolean>(false)
    const [isAtEndOfVideo, setIsAtEndOfVideo] = useState<boolean>(false)
    const [nextRaceShortName, setNextRaceShortName] = useState<string>('')
    const [nextRaceSubtitle, setNextRaceSubtitle] = useState<string>("")

    return (
        <AutoPlayContext.Provider value={{
            isOpen,
            setIsOpen,
            videoDuration,
            setVideoDuration,
            autoPlayNext,
            setAutoPlayNext,
            currentTime,
            setCurrentTime,
            isAtEndOfVideo,
            setIsAtEndOfVideo,
            nextRaceShortName,
            setNextRaceShortName,
            nextRaceSubtitle,
            setNextRaceSubtitle
        }}>
            {children}
        </AutoPlayContext.Provider>
    );
};

export default AutoPlayProvider;