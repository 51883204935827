import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import './EventStreamWidget.scss';
import ReactPlayer from "react-player";
import {ExternalVideo} from '../../models/contentProxyModel';
import {CurrentRaceEventContext} from "../../context/currentRaceEvent.context";


const EventStreamWidget = (props: { streamIndex?: number }) => {

    const [stream, setStream] = useState<ExternalVideo>();
    const videoElementRef = useRef<ReactPlayer>(null);
    const {streamsAvailableToUser} = useContext(CurrentRaceEventContext);

    useEffect(() => {
        if (streamsAvailableToUser && (props?.streamIndex || props?.streamIndex === 0)) setStream(streamsAvailableToUser[props.streamIndex])
    }, [streamsAvailableToUser])


    useEffect(() => {
        if (!videoElementRef.current) {
            throw new Error('Tried to initialise Player before video element exists in DOM!');
        }
    }, []);


    return (
        <div className="eventStreamWidgetContainer">
            <div className="streamName"> {stream?.title}</div>
            <ReactPlayer
                width="100%"
                height="100%"
                className="videoContainerAbsolute"
                playing={true}
                ref={videoElementRef}
                url={stream?.videoId}
                controls={true}
                loop={false}
                muted={true}
                onError={(a, b) => console.warn('error', a, b)}
            />

        </div>

    );
};


export default EventStreamWidget;
