import {createContext} from "react";
import {Layout} from "react-grid-layout";

export interface RaceDashboardWidgetDefinitions {
    [gridKey: string]: () => JSX.Element;
}

export interface RaceDashboardDefinition {
    path: string;
    isAuthenticated?: boolean;
    layout: Layout[];
    numRows: number;
}

interface RaceDashboardLayoutContextState {
    raceDashboardDefinitions : RaceDashboardDefinition[]
    raceDashboardWidgets: RaceDashboardWidgetDefinitions
    dashboardRoutePaths: string[]
}


export const RaceDashboardLayoutContext = createContext<RaceDashboardLayoutContextState>({
    raceDashboardDefinitions: [],
    raceDashboardWidgets: {},
    dashboardRoutePaths: []
})