import {environmentVariables} from "./environmentVariables";

export async function fetchFromContentProxy<T>(
    path: string,
    queryParams?: string,
    signal?: AbortSignal
): Promise<T> {
    const username = environmentVariables.contentProxyUsername();
    const password = environmentVariables.contentProxyPassword();
    const contentfulEnv = environmentVariables.contentfulEnv();

    // Only for dev environments - provide HTTP basic auth header
    const headers: HeadersInit = {};
    if (username && password) {
        headers.Authorization = 'Basic ' + btoa(username + ':' + password);
    }

    let params = '';
    if (contentfulEnv === 'live') {
        params = queryParams ? `${path}?${queryParams}` : path;
    } else {
        params = queryParams ? `${path}?env=${contentfulEnv}&${queryParams}` : `${path}?env=${contentfulEnv}`;
    }

    try {
        const response = await fetch(environmentVariables.contentProxyRoot() + params, {
            headers,
            signal,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        if (signal?.aborted) {
            throw new Error('Request aborted');
        }
        throw error;
    }
}