import React, {
    Dispatch,
    SetStateAction,
    useContext,
    useRef,
} from "react";
import Modal from "@mui/material/Modal";
import "./raceSelectModal.scss";
import {
    ContentProxyRaceDay,
    ContentProxyRaceDayStatus,
    ContentProxyRaceEvent,
    TicketAvailableEnum,
} from "../../../../../models/contentProxyModel";
import {formatRaceTimeFriendlyText} from "../../../../../utils/helpers";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {TimerContext} from "../../../../../context/timer.context";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {makeStyles} from '@mui/styles';
import {CurrentRaceEventContext} from "../../../../../context/currentRaceEvent.context";
import {LoginContext} from "../../../../../context/login.context";

const useStyles = makeStyles({
    tooltip: {
        backgroundColor: 'white',
        color: '#061a1b',
        fontSize: '14px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        padding: '12px',
        maxWidth: '200px',
        textAlign: 'center',
        '& .MuiTooltip-arrow': {
            color: 'white',
        },
    },
    tooltipContent: {
        whiteSpace: 'pre-line',
    },
    arrow: {
        color: 'white',
    },
});

interface RaceSelectModalProps {
    isRaceSelectorModalOpen: boolean;
    setRaceSelectorModalOpen: Dispatch<SetStateAction<boolean>>;
    event: ContentProxyRaceEvent;
    changeRaceEvent: (
        eventId: string,
        raceDay: ContentProxyRaceDay,
        isLiveRace: boolean
    ) => void;
}

const RaceSelectModal = ({
                             setRaceSelectorModalOpen,
                             isRaceSelectorModalOpen,
                             event,
                             changeRaceEvent,
                         }: RaceSelectModalProps) => {
    const {
        timeForReward,
        start,
        reset,
        rewardPoints,
        isActivitySearchSuccess,
        isActivitySearchFailure,
    } = useContext(TimerContext);
    const {broadcasterRegionalConfig} = useContext(CurrentRaceEventContext)
    const {isInternalUser} = useContext(LoginContext)
    const activitySearchRef = useRef(null);
    const classes = useStyles();
    const getOption = (raceDayOption?: ContentProxyRaceDay, index?: number) => {
        const setPlayedValue = () => {
            localStorage.setItem("isVideoPlayed", "true");

            if (event.contentfulId === localStorage.getItem("EventID")) {
                start();
            } else {
                reset();
            }
            localStorage.setItem("EventID", event.contentfulId);
            raceDayOption &&
            changeRaceEvent(
                event.contentfulId,
                raceDayOption,
                raceDayOption.state === ContentProxyRaceDayStatus.InProgress
            );
        };


        const afterReplayDelayHours = () => {
           if(raceDayOption) {
               //handle broadcast delay restrictions by region
               const millisecondsInHour = 3600000;
               const currentDateToCompare = new Date();
               let currentEventEndDateAndTime = new Date(raceDayOption.endDateTime);
               if ((broadcasterRegionalConfig?.replayDelayHours &&
                       (currentDateToCompare.getTime() > (currentEventEndDateAndTime.getTime() + (broadcasterRegionalConfig?.replayDelayHours * millisecondsInHour))))
                   || (currentDateToCompare.getTime() > currentEventEndDateAndTime.getTime() && !broadcasterRegionalConfig?.replayDelayHours)
                   || (isInternalUser && raceDayOption.state === ContentProxyRaceDayStatus.ReplayAvailable)) {
                   return true
               } else {
                   return false
               }
           }

        }


        return (
            <div className="modalRacesContainer--option" key={index}>
                <img
                    className="modalRacesContainer--option__img"
                    src={event.venueImage?.file.url}
                    alt=""
                />

                <div className="modalRacesContainer--option__info">
                    <h2 className="modalRacesContainer--option__info-title">
                        {raceDayOption
                            ? raceDayOption.shortName.toUpperCase()
                            : event.shortName.toUpperCase()}
                    </h2>
                    <p className="modalRacesContainer--option__info-description">
                        {raceDayOption ? raceDayOption.name : event.name}
                    </p>
                    {!raceDayOption &&
                        event.ticketAvailableState === TicketAvailableEnum.no && (
                            <p className="modalRacesContainer--option__info-description">
                                {event.insightsTicketsUnavailableText
                                    ? event.insightsTicketsUnavailableText
                                    : "Check back soon to get tickets"}
                            </p>
                        )}
                </div>
                <div className="modalRacesContainer--option__button-container">
                    {raceDayOption &&
                        (raceDayOption.state === ContentProxyRaceDayStatus.NotStarted &&
                        event.ticketAvailableState !== TicketAvailableEnum.no ? (
                            <Link
                                className="modalRacesContainer--option__tickets"
                                to={{pathname: event.ticketsUrl}}
                                target="_blank"
                                style={{
                                    pointerEvents:
                                        event.ticketAvailableState !== TicketAvailableEnum.soldOut
                                            ? undefined
                                            : "none",
                                }}
                            >
                                <Button
                                    className={`modalRacesContainer--option__button ${
                                        event.ticketAvailableState === TicketAvailableEnum.yes
                                            ? ""
                                            : "modalRacesContainer--option__button--sold-out"
                                    }`}
                                >
                                    {event.ticketAvailableState === TicketAvailableEnum.yes
                                        ? "GET TICKETS"
                                        : "SOLD OUT"}
                                </Button>
                            </Link>
                        ) : raceDayOption &&
                        (raceDayOption.state === ContentProxyRaceDayStatus.InProgress ||
                            (raceDayOption.state ===
                            ContentProxyRaceDayStatus.ReplayAvailable && afterReplayDelayHours())) ? (
                            <Button
                                className="modalRacesContainer--option__button"
                                variant="contained"
                                onClick={setPlayedValue}
                            >
                                {raceDayOption &&
                                    (raceDayOption.state === ContentProxyRaceDayStatus.InProgress
                                        ? "Watch Live"
                                        : "Play")}
                            </Button>
                        ) : (
                            raceDayOption &&
                            (raceDayOption.state === ContentProxyRaceDayStatus.Finished || (raceDayOption.state ===
                                ContentProxyRaceDayStatus.ReplayAvailable && !afterReplayDelayHours())) && (
                                <Button
                                    className="modalRacesContainer--option__label"
                                    variant="contained"
                                >
                                    Replay Soon
                                </Button>
                            )
                        ))}

                    {!raceDayOption &&
                        event.ticketAvailableState !== TicketAvailableEnum.no && (
                            <Link
                                className="modalRacesContainer--option__tickets"
                                to={{pathname: event.ticketsUrl}}
                                target="_blank"
                                style={{
                                    pointerEvents:
                                        event.ticketAvailableState !== TicketAvailableEnum.soldOut
                                            ? undefined
                                            : "none",
                                }}
                            >
                                <Button
                                    className={`modalRacesContainer--option__button ${
                                        event.ticketAvailableState === TicketAvailableEnum.yes
                                            ? ""
                                            : "modalRacesContainer--option__button--sold-out"
                                    }`}
                                >
                                    {event.ticketAvailableState === TicketAvailableEnum.yes
                                        ? "GET TICKETS"
                                        : "SOLD OUT"}
                                </Button>
                            </Link>
                        )}
                </div>
            </div>
        );
    };

    return (
        <Modal
            open={isRaceSelectorModalOpen}
            onClose={() => setRaceSelectorModalOpen(false)}
            style={{backgroundColor: "rgba(0, 0, 0, 0.6)"}}
        >
            <div className="raceSelectModal">
                <button
                    onClick={() => setRaceSelectorModalOpen(false)}
                    className="closeIcon"
                    type="button"
                >
                    <CloseIcon fontSize={"medium"} color={"action"}/>
                </button>

                <div className="modalInfoContainer">
                    <div className="modalInfoBackground">
                        <div className="modalInfoBackgroundShadow"/>
                        <div
                            className="modalInfoBackgroundImage"
                            style={{
                                backgroundImage: `url(${
                                    event.eventBannerLogo
                                        ? event.eventBannerLogo?.file.url
                                        : event.venueImage?.file.url
                                })`,
                            }}
                        />
                    </div>
                    <div className="modalInfo">
                        <div className="modalInfo--title-container">
                            <h1 className="modalInfo--title">{event.shortName}</h1>
                        </div>

                        {isActivitySearchSuccess && (
                            <div className="activitySearch" ref={activitySearchRef}>
                                <div className="activity-info-wrapper">
                                    <span className="points">+{rewardPoints} Points</span>
                                    <Tooltip
                                        arrow
                                        title={
                                            <div className={classes.tooltipContent}>
                                                {`To gain these points you must watch this video for at least ${timeForReward} minutes.
Your account will then be credited with the points displayed.`}
                                            </div>
                                        }
                                        placement="right"
                                        classes={{
                                            tooltip: classes.tooltip,
                                            arrow: classes.arrow,
                                        }}
                                        PopperProps={{
                                            anchorEl: activitySearchRef.current,
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 8],
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                    <span className="description">
                      <IconButton>
                        <InfoOutlinedIcon/>
                      </IconButton>
                      How to gain points
                    </span>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                        {!isActivitySearchSuccess && isActivitySearchFailure && (
                            <div className="activitySearch">
                                <div className="activity-info-wrapper completed">
                                    <CheckCircleIcon/>
                                    <span className="complete">Completed</span>
                                </div>
                            </div>
                        )}
                        <p className="modalInfo--location">{event.locationName}</p>

                        <p className="modalInfo--date">
                            {formatRaceTimeFriendlyText(
                                event.startDateTime,
                                event.endDateTime
                            )}
                        </p>
                        <p className="modalInfo--description">
                            {event.preRaceSummary} {localStorage.getItem("pointsAwarded")}
                        </p>
                    </div>
                </div>
                <div className="modalRacesContainer">
                    {event?.raceDays
                        ? event?.raceDays?.map((raceDay: ContentProxyRaceDay, index) =>
                            getOption(raceDay, index)
                        )
                        : getOption()}
                </div>
            </div>
        </Modal>
    );
};

export default RaceSelectModal;