import { createContext} from 'react';
import { ContentProxyTeam } from '../models/contentProxyModel';

interface TeamDataContextState {
    teams: ContentProxyTeam[];
    getTeamByCountryCode: (code: string) => ContentProxyTeam | undefined;
}

export const TeamDataContext = createContext<TeamDataContextState>({
    teams: [],
    getTeamByCountryCode: () => undefined
});