import { useContext } from "react";
import { Paper } from "@mui/material";
import "./ChatterApp.scss";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import LoginPage from "./pages/Login";
import { RaceSchedule } from "./pages/RaceSchedule";
import { LoginContext } from "./context/login.context";
import { RaceDashboard } from "./pages/RaceDashboard";
import { CurrentRaceEventContext } from "./context/currentRaceEvent.context";
import AutoPlayPopover from "./components/AutoPlayPopover";
import { useAutoPlayEvents } from "./hooks/useAutoPlayEvents";
import { RaceDashboardLayoutContext } from "./context/raceDashboardLayout.context";
import RaceEndedOverlay from "./components/RaceEndedOverlay/RaceEndedOverlay";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordConfirmation from "./pages/ForgotPasswordConfirmation";
import ResetPassword from "./pages/ResetPassword";
import RegisterPage from "./pages/Register";
import EmailVerifyNotificaion from "./pages/EmailVerifyNotificaion";
import SettingsPage from "./pages/Settings";
import DeleteAccount from "./pages/DeleteAccount";
import ResetPasswordWithLogin from "./pages/ResetPasswordWithLogin";

const ChatterApp = () => {
  const { currentEvent } = useContext(CurrentRaceEventContext);
  const { isLoggedIn, isVip } = useContext(LoginContext);
  const location = useLocation();
  const { dashboardRoutePaths } = useContext(RaceDashboardLayoutContext);
  const shouldRedirectToRace = (location.state as any)?.isLoginRedirectFrom;

  useAutoPlayEvents();
  return (
    <Paper className="root">
      {!isLoggedIn && !isVip ? (
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route
            exact
            path="/forgot-password-confirmation"
            component={ForgotPasswordConfirmation}
          />
          <Route exact path="/new-password" component={ResetPassword} />
          <Route exact path="/register" component={RegisterPage} />
          <Route
            exact
            path="/email-verification"
            component={EmailVerifyNotificaion}
          />
          <Route
            exact
            path="*"
            render={() => (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    isLoginRedirectFrom: location.pathname + location.search,
                  },
                }}
              />
            )}
          />
        </Switch>
      ) : (
        <>
          {currentEvent ? (
            <Switch>
              <Redirect exact path="/" to="/schedule" />
              <Route exact path="/schedule" component={RaceSchedule} />
              <Route
                exact
                path={dashboardRoutePaths}
                component={RaceDashboard}
              />
              <Route exact path="/settings" component={SettingsPage} />
              <Route
                exact
                path="/new-password-login"
                component={ResetPasswordWithLogin}
              />
              <Route
                exact
                path="/delete-account-confirm"
                component={DeleteAccount}
              />

              {/* Schedule is default "home" page in case of unknown path */}
              <Route
                exact
                path="*"
                render={() => <Redirect to="/schedule" />}
              />
            </Switch>
          ) : (
            <Switch>
              {/* Schedule when no event selected */}
              <Redirect exact path="/" to="/schedule" />
              <Route
                exact
                path={dashboardRoutePaths}
                component={RaceDashboard}
              />
              <Route exact path="/schedule" component={RaceSchedule} />
              <Route exact path="/eventStreams" component={RaceDashboard} />
              <Route exact path="/settings" component={SettingsPage} />
              <Route
                exact
                path="/new-password-login"
                component={ResetPasswordWithLogin}
              />
              <Route
                exact
                path="/delete-account-confirm"
                component={DeleteAccount}
              />
              <Route
                path="/"
                render={() => {
                  if (shouldRedirectToRace)
                    return <Redirect to={shouldRedirectToRace} />;

                  return <Redirect to="/schedule" />;
                }}
              />
            </Switch>
          )}
          <AutoPlayPopover />
          <RaceEndedOverlay />
        </>
      )}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </Paper>
  );
};

export default ChatterApp;
