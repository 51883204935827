export enum ReactGaEnum {
    RaceThumbnail = 'RaceThumbnail',
    RaceDayView = 'RaceDayView',
    ReplayVideoStream = 'ReplayVideoStream',
    LiveVideoStream = 'LiveVideoStream',
    ScrubToRaceStart = 'ScrubToRaceStart',
    MovePipPosition = 'MovePipPosition',
    SwitchRaceEvent = 'SwitchRaceEvent',
    HandleRaceSelect = 'HandleRaceSelect',
    Login ='Login',
    MobileLinkPress = 'MobileLinkPress'
}
