import React from "react";
import {useGetAppSettings} from "../../hooks/useGetAppSettings";
import './Logo.scss'

export const Logo = () => {
    const oracleLogoUrl = useGetAppSettings()

    return <> {oracleLogoUrl ?
        <img className='insightsOracleLogo' src={oracleLogoUrl} alt="SailGP Insights Powered by Oracle"/> : null}
    </>

}