import { useContext, useEffect, useMemo, useState, useRef } from "react";
import { ItemCallback, Responsive, WidthProvider } from "react-grid-layout";
import { useRouteMatch, useLocation, useHistory } from "react-router";
import { DashboardHeader } from "../../components/DashboardHeader";
import { FullLoadingIndicator } from "../../components/FullLoadingIndicator/FullLoadingIndicator";
import { CurrentRaceEventContext } from "../../context/currentRaceEvent.context";
import RaceScheduleWidget from "../../widgets/RaceScheduleWidget";
import { useWindowSize } from "../../hooks/useWindowSize";
import { LoginContext } from "../../context/login.context";
import "./RaceDashboard.scss";
import { getGridRowHeight } from "../../utils/helpers/getRowHeight";
import { environmentVariables } from "../../utils/helpers/environmentVariables";
import { RaceDashboardLayoutContext } from "../../context/raceDashboardLayout.context";
import { VideoReplaySyncContext } from "../../context/videoReplaySync.context";
import {
  ContentProxyRaceDay,
  ContentProxyRaceEvent,
} from "../../models/contentProxyModel";
import { toast } from "react-toastify";

const ResponsiveGridLayout = WidthProvider(Responsive);

const marginPx = 15;
// NB: this must be kept in-sync with the `height` property in MobileDownloadPrompt.scss at all times
const dashboardHeightPx = 75;

export const RaceDashboard = () => {
  const match = useRouteMatch();
  const { height, width } = useWindowSize();
  const { switchRaceEvent, allRaceEvents, isLoading, currentEvent } =
    useContext(CurrentRaceEventContext);
  const { isLoggedIn, isInternalUser, isVip } = useContext(LoginContext);
  const { raceDashboardDefinitions, raceDashboardWidgets } = useContext(
    RaceDashboardLayoutContext
  );
  const [alertSizeClass, setAlertSizeClass] = useState<string>("");

  const { switchEventAndResetTime } = useContext(VideoReplaySyncContext);
  const routeLocation = useLocation();
  const urlParams = new URLSearchParams(routeLocation.search);
  const params = Object.fromEntries(urlParams);
  const history = useHistory();
  const widgetRef = useRef<HTMLDivElement| null>(null);

  useEffect(()=> {
      if (match.path !== '/chatter') {
          setAlertSizeClass('');
          return
      }

      let widgetWidth;
      let alertSize = 'alertSize'
      if (widgetRef.current) {
          widgetWidth = widgetRef.current.getBoundingClientRect().width;
          if (widgetWidth < 500) {
              alertSize = ''
          } else if (widgetWidth < 900) {
              alertSize += 'Md'
          } else {
              alertSize += 'Lg'
          }

          setAlertSizeClass(alertSize);
      }
      
  }, [width, widgetRef.current, match.path])

  useEffect(() => {
    return () => {
      localStorage.removeItem('video1');
      localStorage.removeItem('video2');
    };
  }, []);

  useEffect(() => {
    const isParamsExist =
      params?.eventId && params?.raceDayId && params?.isLive.length;
    const selectedEvent: ContentProxyRaceEvent | undefined =
      allRaceEvents?.filter(
        (raceEvent) => raceEvent.contentfulId === params.eventId
      )[0];
    const selectedRaceDay: ContentProxyRaceDay | undefined =
      selectedEvent?.raceDays?.filter(
        (raceDay) => raceDay.contentfulId === params.raceDayId
      )[0];

    if (selectedEvent && selectedRaceDay && isParamsExist) {
      switchRaceEvent(
        params.eventId,
        params.raceDayId,
        params.isLive === "true"
      );
      switchEventAndResetTime(0);
    } else if (isParamsExist && allRaceEvents) {
      // params exist, but incorrect
      toast("Race not found", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      history.push("/schedule");
    } else if (!isParamsExist && !currentEvent) {
      // no params and no current race
      history.push("/schedule");
    }
  }, [allRaceEvents, params?.eventId, params?.raceDay, params?.isLive]);

  const dashboardDefinition = useMemo(() => {
    const matchedDefinition = raceDashboardDefinitions.find((def) => {
      // to support partner custom sponsor builds which gives an internal view with no auth
      if (isVip) {
        return (
          def.path === match.path &&
          (def.isAuthenticated === undefined || def.isAuthenticated)
        );
      }
      // to support partner custom build pre replay going live
      if (environmentVariables.specialVersion() === "partner") {
        return (
          def.path === match.path &&
          (def.isAuthenticated === undefined || !def.isAuthenticated)
        );
      }

      return (
        def.path === match.path &&
        (def.isAuthenticated === undefined ||
          isInternalUser === def.isAuthenticated)
      );
    });

    if (!matchedDefinition) {
      throw new Error(
        `Race dashboard cannot find an appropriate layout for path "${match.path}" with auth status ${isLoggedIn}`
      );
    }

    return matchedDefinition;
  }, [match.path, isLoggedIn]);

  const gridContents = useMemo(() => {
    return dashboardDefinition.layout.map((item) => {
      const matchedWidget = raceDashboardWidgets[item.i];

      const alertSizeClassName = item.i === "alerts" ? alertSizeClass : "";

      if (!matchedWidget) {
        throw new Error(
          `The dashboard at path "${match.path}" contains unknown widget "${item.i}"`
        );
      }

      if (item.i === "alerts") {
        return (
            <div ref={widgetRef} className={`gridItem ${alertSizeClassName}`} key={item.i}>
            {matchedWidget()}
        </div>
        )
      }

      return (
        <div className={`gridItem ${alertSizeClassName}`} key={item.i}>
          {matchedWidget()}
        </div>
      );
    });
  }, [dashboardDefinition, match.path, alertSizeClass]);

  const rowHeight = useMemo(
    () =>
      getGridRowHeight(
        dashboardDefinition.numRows,
        marginPx,
        height - dashboardHeightPx
      ),
    [dashboardDefinition.numRows, height]
  );

  const handleResizeStop: ItemCallback = (_, __, newItem): void => {
    if (newItem.i === "alerts" && newItem.isResizable) {
      let alertSize = "alertSize";
      if (newItem.w <= 4) alertSize = "";
      if (newItem.w > 4 && newItem.w <= 8) alertSize = `${alertSize}Md`;
      if (newItem.w > 8) alertSize = `${alertSize}Lg`;

      setAlertSizeClass(alertSize);
    }
  };

  return (
    <div className="raceDashboardContainer">
      {isLoading && <FullLoadingIndicator />}
      <div className="headerContainer">
        <DashboardHeader
          isInRaceDashboard={true}
          eventId={params.eventId}
          leftContent={<RaceScheduleWidget isInRaceSchedule={false} />}
        />
      </div>
      <ResponsiveGridLayout
        className="grid"
        margin={[marginPx, marginPx]}
        layouts={{
          lg: dashboardDefinition.layout,
          md: dashboardDefinition.layout,
          sm: dashboardDefinition.layout,
        }}
        cols={{ lg: 12, md: 12, sm: 12 }}
        rowHeight={rowHeight}
        breakpoints={{ lg: 1920, md: 1366, sm: 768 }}
        onResizeStop={handleResizeStop}
      >
        {gridContents}
      </ResponsiveGridLayout>
    </div>
  );
};
