import { CircularProgress } from '@mui/material';
import React from 'react';
import './FullLoadingIndicator.scss';

export const FullLoadingIndicator = () => {
    return (
        <div className="fullLoadingIndicator">
            <div className="progressContainer">
                <CircularProgress size={70} />
            </div>
        </div>
    )
};
