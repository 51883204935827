import { useRef } from "react";

export function useStableCallback<TArgs extends any[], TReturnValue>(callback: (...args: TArgs) => TReturnValue) {
    const innerCallbackRef = useRef(callback);
    innerCallbackRef.current = callback;

    const outerCallbackRef = useRef<(...args: TArgs) => TReturnValue>((...args) => innerCallbackRef.current(...args));

    return outerCallbackRef.current;
}
