import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import EventSelected from './EventSelectedComponent'
import './SliderComponent.scss'
import {
    ContentProxyRaceDay,
    ContentProxyRaceDayStatus,
    ContentProxyRaceEvent
} from "../../../../models/contentProxyModel";
import {BroadcasterConfigModel} from "../../../../models/broadcasterConfigModel";
import SingleSlider from "./SingleSlider";
import {CurrentRaceEventContext} from "../../../../context/currentRaceEvent.context";

export interface SliderRaceEventHeaderProps {
    activeSlide: ContentProxyRaceEvent | undefined;
    allRaceEvents: ContentProxyRaceEvent[];
    changeRaceEvent: (eventId: string, raceDay: ContentProxyRaceDay, isLiveRace: boolean) => void
    nextEvent: ContentProxyRaceEvent | undefined
    currentSlide: ContentProxyRaceEvent | undefined;
    handleSelect: (event: ContentProxyRaceEvent) => void;
    selectedRaceDayId: string | undefined;
    broadcasterRegionalConfig: BroadcasterConfigModel | undefined;
    separatedSeasons: object | undefined;
    seasonNumbers: string[] | undefined;
    switchRaceEvent: (eventId: string | undefined, raceDayId: string | undefined, isLiveRaceDay: boolean) => void;
    switchEventAndResetTime: (offsetSeconds?: number) => void;
    showWatchButton: boolean;
    setShowWatchButton: Dispatch<SetStateAction<boolean>>
}

const SliderComponent = ({
                             activeSlide,
                             changeRaceEvent,
                             nextEvent,
                             currentSlide,
                             handleSelect,
                             broadcasterRegionalConfig,
                             separatedSeasons,
                             seasonNumbers,
                             switchRaceEvent,
                             switchEventAndResetTime,
                             showWatchButton,
                             setShowWatchButton
                         }: SliderRaceEventHeaderProps) => {

    const {resetEvent} = useContext(CurrentRaceEventContext)
    const [firstRaceDay, setFirstRaceDay] = useState<ContentProxyRaceDay | undefined>()

    useEffect(() => {
        if (!activeSlide) return
        handleSelect(activeSlide)
    }, [activeSlide, handleSelect])

    useEffect(() => {
        if (currentSlide && currentSlide?.raceDays) {
            const firstRace = currentSlide.raceDays.find(day => day.state === ContentProxyRaceDayStatus.InProgress || day.state === ContentProxyRaceDayStatus.ReplayAvailable)
            switchRaceEvent(currentSlide?.contentfulId, firstRace?.contentfulId, firstRace?.state === ContentProxyRaceDayStatus.InProgress);
            switchEventAndResetTime(60)
            setFirstRaceDay(firstRace)
        } else resetEvent()
    }, [currentSlide])

    return (
        <div className="sliderContainer" id="sliderContainer">
            {currentSlide &&
              <EventSelected event={currentSlide}
                             changeRaceEvent={changeRaceEvent}
                             nextEvent={nextEvent}
                             broadcasterRegionalConfig={broadcasterRegionalConfig}
                             hasVideo={currentSlide?.raceDays && !!currentSlide?.raceDays[0]}
                             firstRaceDay={firstRaceDay}
                             showWatchButton={showWatchButton}
              />}
            <div className='seasonsContainer'>
                {seasonNumbers && seasonNumbers.map(season => {
                    if (+season > 0) {
                        return <SingleSlider key={season} separatedSeasons={separatedSeasons}
                                             currentSlide={currentSlide}
                                             handleSelect={handleSelect} season={season}
                                             changeRaceEvent={changeRaceEvent}
                                             setShowWatchButton={setShowWatchButton}
                        />
                    } else return null

                })}
            </div>
        </div>
    );
};

export default SliderComponent;
