import {ContentProxyRaceDayStatus, ContentProxyRaceEvent} from "../../models/contentProxyModel";
import {Dispatch} from "react";

export const getNextRaceTitle = (
    currentEvent: ContentProxyRaceEvent | undefined,
    allRaceEvents: ContentProxyRaceEvent[] | undefined,
    setNextRaceShortName: Dispatch<string>,
    setNextRaceSubtitle: Dispatch<string>,
    selectedRaceDayId: string | undefined,
) => {
    let currentRaceDayIndex;
    let raceDaysLength;
    if(currentEvent?.raceDays && allRaceEvents) {
        for (let i = 0; i < currentEvent?.raceDays?.length; i++) {
            raceDaysLength = currentEvent?.raceDays?.length;

            if (currentEvent?.raceDays[i].contentfulId === selectedRaceDayId) {
                currentRaceDayIndex = i;
            }
        }

        if (typeof raceDaysLength === 'number' && currentRaceDayIndex !== raceDaysLength - 1 && typeof currentRaceDayIndex === 'number') {

            const nextDayHasReplays = currentEvent?.raceDays[currentRaceDayIndex + 1].state === ContentProxyRaceDayStatus.ReplayAvailable;

            if (nextDayHasReplays) {

                const nextRaceDay = currentEvent?.raceDays[currentRaceDayIndex + 1]

                setNextRaceShortName(currentEvent?.shortName)

                setNextRaceSubtitle(nextRaceDay?.shortName)
            }
        } else {
            let currentEventIndex;
            allRaceEvents?.forEach((event, index) => {
                if (event.contentfulId === currentEvent?.contentfulId) {
                    currentEventIndex = index;
                }
            });

            let nextRaceEvent
            if (typeof currentEventIndex === 'number') nextRaceEvent = allRaceEvents[currentEventIndex + 1];

            if (nextRaceEvent?.raceDays){
                const nextRaceDayReplay = nextRaceEvent.raceDays.find(
                    (raceDay) => raceDay.state === ContentProxyRaceDayStatus.ReplayAvailable
                );
                if (nextRaceDayReplay){
                    setNextRaceShortName(nextRaceEvent.shortName)
                    setNextRaceSubtitle(nextRaceEvent.shortNameSubtitle)
                } else {
                    if (allRaceEvents){
                        setNextRaceShortName(allRaceEvents[0].shortName)
                        setNextRaceSubtitle(allRaceEvents[0].shortNameSubtitle)
                    }
                }
            } else {
                if (allRaceEvents){
                    setNextRaceShortName(allRaceEvents[0].shortName)
                    setNextRaceSubtitle(allRaceEvents[0].shortNameSubtitle)
                }
            }

        }
    }

}