export const countryCodeNameMap: { [countryCode: string]: string | undefined } = {
    usa: 'United States',
    aus: 'Australia',
    fra: 'France',
    gbr: 'Great Britain',
    esp: 'Spain',
    jpn: 'Japan',
    den: 'Denmark',
    nzl: 'New Zealand',
    sui: 'Switzerland',
    can: 'Canada',
    chn: 'China',
    bra: 'Brazil',
    ita: 'Italy',
    ger: 'Germany'
}
