import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import './FanzoneHero.scss';
import DockAccountOverview from "./DockAccountOverview";
import {useContentProxyData} from "../../hooks/useContentProxyData";
import {ContentProxyTeam} from "../../models/contentProxyModel";
import {LoginContext} from "../../context/login.context";
import {CTUserProfileResponse} from "../../models/ctUserProfile";
import {useIsMounted} from "../../hooks/useIsMounted";
import {submitForm} from "../../utils/helpers/submitForm";

interface AlternateComponentContent {
    image?: {
        file: {
            url: string;
        };
    };
    label?: string;
}

interface FanZoneHeroProps {
    data: {
        submitUrl: string;
        desktopBg?: {
            file: {
                url: string;
                title?: string;
            };
        };
        mobileBg?: {
            file: {
                url: string;
                title?: string;
            };
        };
        alternateComponentContent?: AlternateComponentContent[];
    };
}

interface UserPrefs {
    favouriteTeam?: string;
    favouriteTeamObj?: ContentProxyTeam;
    favouriteTeamImgSrc?: string;
}

const fallBackImgUrl = '/dock/SailGP_TD_Desktop Team Header_SailGP.jpg';

function FanZoneHero(props: FanZoneHeroProps) {
    const {data: teams} = useContentProxyData<ContentProxyTeam[]>('teams', 0, 'incGeneral=true&onlyActive=true');
    const [userPrefs, setUserPrefs] = useState<UserPrefs>({});
    const {user, logOut} = useContext(LoginContext);
    const [ctUserProfile, seCtUserProfile] = useState<CTUserProfileResponse>();

    const isMountedRef = useIsMounted();

    useEffect(() => {
        let timer: NodeJS.Timeout;

        async function getCTUserProfile() {
            try {
                let response = await submitForm({}, 'user/ct-user-profile');

                if (response.status === 'success' && isMountedRef.current && response.data) {
                    seCtUserProfile({status: response.status, data: response.data});
                }

                if (!(response.status === 'error' && (typeof response === 'string' && response === 'token invalid'))) {
                    timer = setTimeout(getCTUserProfile, 15e3);
                }
                if (response.status === 'error' && response.response === 'token invalid') {
                    logOut();
                }
            } catch (e) {
                // do nothing
            }
        }

        getCTUserProfile();
        console.log(ctUserProfile)

        return () => {
            clearTimeout(timer);
        };
    }, [isMountedRef]);


    useEffect(() => {
        if (user && teams && user.favouriteTeam) {
            const favouriteTeam = teams.find(team => team.code === user.favouriteTeam);
            if (favouriteTeam) {
                const updatedUserPrefs: UserPrefs = {
                    favouriteTeam: user.favouriteTeam,
                    favouriteTeamObj: favouriteTeam,
                    favouriteTeamImgSrc: favouriteTeam.teamPhoto?.file.url || favouriteTeam.nationPatternTiltShift?.file.url || fallBackImgUrl,
                };
                setUserPrefs(updatedUserPrefs);
            }
        }
    }, [teams, user]);

    return (<>
            <div className={`fanzone-banner-container ${ctUserProfile ? 'show' : ''}`}>
                <div className="teamHeader">
                    {userPrefs.favouriteTeamImgSrc && <img src={userPrefs.favouriteTeamImgSrc} alt=""/>}
                    <DockAccountOverview
                        teamColor={userPrefs.favouriteTeamObj?.primaryTeamHexColour}
                        ctUserProfile={ctUserProfile}
                    />
                </div>
            </div>
        </>


    );
}

FanZoneHero.propTypes = {
    data: PropTypes.object,
};

FanZoneHero.defaultProps = {
    data: {},
};

export default FanZoneHero;