import React, { useContext, useState } from "react";
import HeadBrandingWidget from "../../widgets/HeadBrandingWidget";
import "./DashboardHeader.scss";

import { useHistory } from "react-router-dom";
import Select from "react-select";

import { LoginContext } from "../../context/login.context";

import { TimerContext } from "../../context/timer.context";

export interface RaceScheduleHeaderProps {
  leftContent?: React.ReactNode;
  isInRaceDashboard?: boolean;
  eventId?: string;
}

const classNames = {
  control: () => "selectControl",
  indicatorsContainer: () => "indicatorsContainer",
  indicatorSeparator: () => "indicatorSeparator",
  valueContainer: () => "valueContainer",
  input: () => "selectInput",
  singleValue: () => "singleValue",
  placeholder: () => "selectPlaceholder",
  container: () => "selectContainer",
  menuList: () => "menuList",
  option: () => "selectOption",
  menu: () => "selectMenu",
};

export const DashboardHeader = (props: RaceScheduleHeaderProps) => {
  const history = useHistory();

  const { pause } = useContext(TimerContext);
  const options = [
    { value: "/raceManagement", label: "RACE DASHBOARD" },
    { value: "/video", label: "VIDEO DASHBOARD" },
    { value: "/chatter", label: "CHATTER DASHBOARD" },
  ];
  const { setLoginStatus, isVip, logOut } = useContext(LoginContext);

  const onLogout = async (data: any) => {
    logOut();
  };

  return (
    <div className="dashboardHeader">
      <button
        onClick={() => {
          pause();
          history.push("/schedule");
        }}
        className={`headerBrandingContainer ${
          !props.isInRaceDashboard ? "headerBrandingContainer--schedule" : ""
        }`}
      >
        <HeadBrandingWidget />
      </button>

      <div className="leftContentContainer">{props.leftContent ?? null}</div>
      {props.isInRaceDashboard && (
        <div className="headerNavigationContainer">
          <Select
            className="dashboardSelector"
            placeholder="CHANGE LAYOUT"
            onChange={(e) => e && e.value && history.push(e.value)}
            options={options}
            unstyled
            openMenuOnClick={true}
            isClearable={false}
            isSearchable={false}
            classNames={classNames}
          />
        </div>
      )}

      {!isVip && (
        <div className="dashboardProfile">
          <button
            className="dashboardButton dashboardButton__settings"
            onClick={() => {
              pause();
              history.push("/settings");
            }}
          >
            <img
              className="dashboardButton__icon"
              src="/Setting_fill.svg"
              alt=""
            />
            SETTINGS
          </button>
          <a onClick={onLogout} className="dashboardButton" href="/">
            <img
              className="dashboardButton__icon"
              src="/Sign_in_circle_disabled.svg"
              alt=""
            />
            LOG OUT
          </a>
        </div>
      )}
    </div>
  );
};
