import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useInterval} from "../hooks/useInterval";
import {ContentProxyRaceLiveStream} from "../models/contentProxyModel";
import {environmentVariables} from "../utils/helpers/environmentVariables";
import {getSelectedRaceDay} from "../utils/helpers/getSelectedRaceDay";
import {CurrentRaceEventContext, RaceTypeEnum} from "./currentRaceEvent.context";
import {LoginContext} from "./login.context";
import {StreamDetailsContext, StreamDetailsContextState} from "./streamDetails.context";
import {useIsMounted} from "../hooks/useIsMounted";

export enum CommentaryEnum {
    Commentary = 'Commentary',
    Podcast = 'Podcast',
}

export type CommentaryType = {
    key: CommentaryEnum;
    isSelected: boolean;
};


export const StreamDetailsProvider = ({children}: React.PropsWithChildren<{}>) => {
    const {isLoggedIn, isInternalUser, isVip} = useContext(LoginContext);
    const [localVdnUrl, setLocalVdnUrl] = useState<string>();
    const [commentaryType, setCommentaryType] = useState<CommentaryType[]>([
        {key: CommentaryEnum.Commentary, isSelected: true},
        {key: CommentaryEnum.Podcast, isSelected: false},
    ]);

    const [raceDayLiveStreamHyperlink, setRaceDayLiveStreamHyperlink] = useState<string>();
    const {currentEvent, raceType, selectedRaceDayId, broadcasterRegionalConfig} = useContext(CurrentRaceEventContext);
    const isMountedRef = useIsMounted();


    const handleCommentarySelect = (selectedType: CommentaryType) => {
        setCommentaryType(currentTypes =>
            currentTypes.map(type => ({
                ...type,
                isSelected: type.key === selectedType.key,
            }))
        );
    };


    const streamDetails = useMemo((): StreamDetailsContextState | undefined => {
        if (!currentEvent || !broadcasterRegionalConfig) {
            return undefined;
        }

        const selectedRaceDay = getSelectedRaceDay(currentEvent, raceType, selectedRaceDayId);

        if (!selectedRaceDay) {
            return undefined;
        }


        if (raceType === RaceTypeEnum.replay && currentEvent.raceDays) {
            const replayStreams = currentEvent.raceDays.filter(day => day.contentfulId === selectedRaceDayId)
                .map(streams => streams?.replayStreams);
            //handle broadcast delay restrictions by region
            const millisecondsInHour = 3600000;
            const currentDateToCompare = new Date();
            const currentEventEndDateAndTime = new Date(currentEvent.endDateTime);

            if ((broadcasterRegionalConfig.replayDelayHours &&
                    (currentDateToCompare.getTime() > (currentEventEndDateAndTime.getTime() + (broadcasterRegionalConfig?.replayDelayHours * millisecondsInHour))))
                || ((currentDateToCompare.getTime() > currentEventEndDateAndTime.getTime() && !broadcasterRegionalConfig?.replayDelayHours)) || isInternalUser) {

                return {
                    replayStreams: replayStreams[0]
                };
            } else {
                return
            }
        }


        if (raceType === RaceTypeEnum.live && currentEvent.raceDays) {
            const raceDayLiveStream: ContentProxyRaceLiveStream | undefined = selectedRaceDay.liveStreams?.[0];


            if (!raceDayLiveStream) {
                return undefined;
            }
            if (!raceDayLiveStream.config) {
                return
            }


            let raceDayStreamIds: string[] | undefined;
            if ((isLoggedIn && isInternalUser) || isVip) {
                raceDayStreamIds = Object.values(raceDayLiveStream.config).find(stream => stream.broadcastRegion === 'default')?.streams;

            } else {
                raceDayStreamIds = raceDayLiveStream.config[broadcasterRegionalConfig.streamsId]?.streams;
            }
            if (!raceDayStreamIds) {
                return undefined;
            }

            setRaceDayLiveStreamHyperlink(raceDayLiveStream.hyperlinkUrl)

            return {
                host: localVdnUrl ? localVdnUrl : raceDayLiveStream.vdnUrl,
                streamIds: raceDayStreamIds,
                insightsWebLatencyMilliseconds: raceDayLiveStream.insightsWebLatencyMilliseconds,
                dataOnlyDelayMillis: raceDayLiveStream.dataOnlyDelayMillis,
                commentaryType: commentaryType,
                handleCommentarySelect: handleCommentarySelect
            };
        }

    }, [currentEvent, broadcasterRegionalConfig, isLoggedIn, raceType, selectedRaceDayId, raceDayLiveStreamHyperlink, localVdnUrl, commentaryType]);


    const fetchData = useCallback(async (fetchUrl: string) => {
        try {
            const controller = new AbortController();
            // we use a abort controller to make sure unreachable host check won't risk taking too long
            setTimeout(() => {
                controller.abort()
            }, 2000);

            const response = await fetch(fetchUrl, {signal: controller.signal})
            const value = await response.text()


            if (value) {
                const nextRequest = await fetch("http" + value.substring(2), {
                    mode: "no-cors",
                    signal: controller.signal
                })
                if (nextRequest) {
                    setLocalVdnUrl(value)
                }
            }
        } catch (e) {
            setLocalVdnUrl(undefined)
            console.warn(`Failed to fetch: ${fetchUrl}`, e);
        }
    }, [isMountedRef]);


    useEffect(() => {
        if (raceDayLiveStreamHyperlink) {
            fetchData(raceDayLiveStreamHyperlink)
        }
    }, [fetchData, raceDayLiveStreamHyperlink]);

    useInterval(() => {
        if (raceDayLiveStreamHyperlink) {
            fetchData(raceDayLiveStreamHyperlink);
        }
    }, 60000);


    return (
        <StreamDetailsContext.Provider value={streamDetails}>
            {children}
        </StreamDetailsContext.Provider>
    );
};
