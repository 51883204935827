import React, {useContext, useMemo} from 'react';
import './RaceScheduleWidget.scss';
import {RaceDataContext} from '../../context/raceData.context';
import {CurrentRaceEventContext, RaceTypeEnum} from "../../context/currentRaceEvent.context";
import {getSelectedRaceDay} from '../../utils/helpers/getSelectedRaceDay';
import {ContentProxyRace, ContentProxyRaceDay} from '../../models/contentProxyModel';
import clsx from 'clsx';
import {VideoReplaySyncContext} from '../../context/videoReplaySync.context';
import {useHistory, useLocation} from "react-router-dom";
import {RaceStatusEnum} from "../../models/enums/raceStatusEnum";
import {DateTime} from "luxon";
import RolexCountdownWidget from "../RolexCountdownWidget";

const RaceScheduleWidget = (props: { isInRaceSchedule: boolean | undefined; } | undefined) => {
    const history = useHistory();
    const raceData = useContext(RaceDataContext);
    const {
        currentEvent,
        raceType,
        selectedRaceDayId,
    } = useContext(CurrentRaceEventContext);
    const {handleRaceSelect} = useContext(VideoReplaySyncContext);
    const location = useLocation();

    const currentRaceDay = useMemo((): ContentProxyRaceDay | undefined => {
        if (!currentEvent) {
            return undefined;
        }
        return getSelectedRaceDay(currentEvent, raceType, selectedRaceDayId);
    }, [currentEvent, raceType, selectedRaceDayId]);


    const seekToRace = (race: ContentProxyRace, raceDay: ContentProxyRaceDay) => {
        if (raceType === RaceTypeEnum.live) return
        if (location.pathname === '/schedule') {
            history.goBack()
        }
        handleRaceSelect(race, raceDay);
    };

    return (
        <div className="raceScheduleWidgetContainer">

            {currentEvent && props && !props.isInRaceSchedule ? <>
                    <div className="selectedRaceEvent">{currentEvent?.name} </div>
                    <div className="subtitleAndDate">{currentEvent?.shortNameSubtitle} : {currentRaceDay?.name}</div>
                    <div className="raceDayTimes">
                        {
                            currentRaceDay?.races?.map(race =>
                                    <span
                                        onClick={() => seekToRace(race, currentRaceDay)}
                                        className={clsx({
                                            raceInfo: true,
                                            selectable: raceType === RaceTypeEnum.replay,
                                            active: (
                                                raceData?.raceId === race.raceId &&
                                                (
                                                    raceData?.status === RaceStatusEnum.Preparatory ||
                                                    raceData?.status === RaceStatusEnum.Started ||
                                                    raceData?.status === RaceStatusEnum.Warning
                                                )
                                            )
                                        })}
                                        key={race.contentfulId}
                                    >

                        {race.shortName} :{" "}
                                        {
                                            DateTime.fromISO(race.startDateTime)
                                                .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                                .toFormat('HH:mm')
                                                .toLowerCase()
                                        }
                    </span>
                            ) ?? null
                        }
                    </div>
                </>
                : <div className="eventRolex"><RolexCountdownWidget/></div>
            }
        </div>
    );
};

export default RaceScheduleWidget;
