import React, {PropsWithChildren, useEffect, useState, useContext} from "react";
import {ContentProxyRaceEvent} from "../models/contentProxyModel";
import {NavigationSliderContext} from "./navigationSlider.context";
import {CurrentRaceEventContext} from "./currentRaceEvent.context";
import ReactGA from "react-ga";
import {ReactGaEnum} from "../models/enums/reactGAEnum";
import {isEqual} from "lodash";


export interface PipPositionInterface {
    x: number
    y: number
}


export const NavigationSliderProvider = ({children}: PropsWithChildren<{}>) => {
    const [currentSlide, setCurrentSlide] = useState<ContentProxyRaceEvent>();
    const [currentPipPosition, setCurrentPipPositionContext] = useState<PipPositionInterface>({x: 0, y: 0});
    const { allRaceEvents } = useContext(CurrentRaceEventContext)

    useEffect(() =>{
        if(currentSlide){
            const matchingEvent : ContentProxyRaceEvent | undefined = allRaceEvents?.filter(raceEvent => raceEvent.contentfulId === currentSlide.contentfulId)[0]
            if (matchingEvent && !isEqual(matchingEvent, currentSlide)) {
                setCurrentSlide(matchingEvent)
            }
        }
    }, [allRaceEvents])

    const handleSelect = (event: ContentProxyRaceEvent) => {
        ReactGA.event({
            category: ReactGaEnum.RaceThumbnail,
            action: `thumbnail click on ${event.name} in Season ${event.eventSeason}`
        });
        setCurrentSlide(event);
    }

    const setCurrentPipPosition = (position: PipPositionInterface) => {
        ReactGA.event({
            category: ReactGaEnum.MovePipPosition,
            action: `Moved pip to X-${position.x}: Y-${position.y}`
        });
        setCurrentPipPositionContext(position);
    }

    return (
        <NavigationSliderContext.Provider value={{
            currentSlide,
            handleSelect,
            currentPipPosition,
            setCurrentPipPosition,
        }}>
            {children}
        </NavigationSliderContext.Provider>
    );
};
