import React, { useCallback, useEffect, useRef, useState } from "react";
import "./RolexCountdownWidget.scss";
import { fetchFromContentProxy } from "../../utils/helpers/fetchFromContentProxy";
import {
  ContentfulType,
  ContentProxyNextRaceDateAndTime,
} from "../../models/contentProxyModel";

const RolexCountdownWidget = () => {
  const initialState = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  const interval = useRef<NodeJS.Timeout>();
  const [state, setState] = useState(initialState);
  const [contentType, setContentType] = useState<ContentfulType>();

  const getNextRace = useCallback(async () => {
    try {
      const response =
        await fetchFromContentProxy<ContentProxyNextRaceDateAndTime>(
          "next-race"
        );
      let diff = -1;
      if (response.startDateTime) {
        diff =
          (new Date(response.startDateTime).getTime() - new Date().getTime()) /
          1000;
      }
      setContentType(response.contentfulType);
      if (diff > 0) {
        interval.current = setInterval(() => {
          const date = calculateCountdown(response.startDateTime);
          date
            ? setState({
                days: date.days,
                hours: date.hours,
                minutes: date.minutes,
                seconds: date.seconds,
              })
            : stop();
        }, 1000) as unknown as NodeJS.Timeout;
      } else {
        interval.current = setInterval(() => {
          const now = new Date();
          setState({
            hours: now.getHours(),
            minutes: now.getMinutes(),
            seconds: now.getSeconds(),
            days: -1,
          });
        }, 1000) as unknown as NodeJS.Timeout;
      }
    } catch (error) {
      console.warn(error);
    }
    return () => {
      stop();
    };
  }, []);

  const calculateCountdown = (endDate: any) => {
    let diff =
      (Date.parse(`${new Date(endDate)}`) - Date.parse(`${new Date()}`)) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }

    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }

    if (diff >= 60) {
      timeLeft.minutes = Math.floor(diff / 60);
      diff -= timeLeft.minutes * 60;
    }

    timeLeft.seconds = diff;

    return timeLeft;
  };

  const stop = () => {
    clearInterval(interval.current as NodeJS.Timeout);
  };

  useEffect(() => {
    getNextRace();
    return () => {
      stop();
    };
  }, [getNextRace]);

  const { days, hours, minutes, seconds } = state;

  return (
    <div className="rolex-watch-container">
      {days > -1 ? (
        <span>
          To the next{" "}
          {contentType && contentType === ContentfulType.RaceEvent
            ? "grand prix"
            : "race day"}
        </span>
      ) : (
        <span>Your local time</span>
      )}
      <ul>
        {days > -1 && (
          <li>
            <span>{days}</span>days
          </li>
        )}
        <li>
          <span>{hours}</span>Hours
        </li>
        <li>
          <span>{minutes}</span>Minutes
        </li>
        <li>
          <span>{seconds}</span>Seconds
        </li>
      </ul>
    </div>
  );
};

export default RolexCountdownWidget;
