import React from 'react';
import './DockAccountOverview.scss';
import {CTUserProfileResponse} from "../../../models/ctUserProfile";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';

interface DockAccountOverviewProps {
    teamColor?: string;
    ctUserProfile: CTUserProfileResponse | undefined
}

const formatPoints = (points: number): string => {
    if (points >= 1000) {
        return `${(points / 1000).toFixed(1)}k`;
    }
    return points.toString();
};

const tooltipContent = (
    <ul>
        <li>Earn points by taking part in activities and engaging with content</li>
        <li>Quizzes, surveys and polls are just some of the activities we offer to increase your points total</li>
        <li>As a valued community member, you'll have access to exclusive rewards and content</li>
        <li>Claim more points than your friends to prove you are the biggest SailGP fan!</li>
    </ul>
);

const DockAccountOverview: React.FC<DockAccountOverviewProps> = (props) => {
    const {first_name, last_name, tier_info, total_points} = props.ctUserProfile?.data || {};

    return first_name && last_name && tier_info ? (
        <div className={`dock-account-overview`}>
            <div className="dock-account-overview-container">
                <div className="dock-account-overview-profile">Hello, <span className="dock-account-overview-profile__name">{first_name} {last_name}!</span></div>

                <div className="total-points" style={{backgroundColor: props.teamColor}}>
                    {formatPoints(total_points || 0)} Total Points
                    <Tooltip
                        title={tooltipContent}
                        placement="right"
                        arrow
                        classes={{
                            tooltip: 'points-tooltip',
                            arrow: 'points-tooltip-arrow'
                        }}
                    >
                        <IconButton size="small" className="info-button">
                            <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    ) : null;
};

export default DockAccountOverview;