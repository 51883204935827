import {
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { TimerContext } from "./timer.context";
import { useStopwatch } from "react-timer-hook";

const TimerProvider = ({ children }: PropsWithChildren<{}>) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });
  const [timeForReward, setTimeForReward] = useState(0);
  const [rewardPoints, setRewardPoints] = useState(0);
  const [isActivitySearchSuccess, setActivitySearchSuccess] = useState(false);
  const [isActivitySearchFailure, setActivitySearchFailure] = useState(false);
  const setResponse = (
    timeForRewardResponse: SetStateAction<number>,
    rewardPointsResponse: SetStateAction<number>
  ) => {
    setTimeForReward(timeForRewardResponse);
    setRewardPoints(rewardPointsResponse);
  };

  return (
    <TimerContext.Provider
      value={{
        seconds,
        minutes,
        hours,
        days,
        start,
        pause,
        reset,
        timeForReward,
        rewardPoints,
        setResponse,
        isActivitySearchSuccess,
        setActivitySearchSuccess,
        isActivitySearchFailure,
        setActivitySearchFailure,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export default TimerProvider;
