import { AwsChatterEntryModel, RaceData } from "../models/awsDataModel";

export enum RaceDataWorkerMessageType {
    AwsData,
    ChatterAlerts,

    ChatterClear,
    Log,
    SlackNotification
}

export interface SlackNotificationWorkerDataMessage {
    type: RaceDataWorkerMessageType.SlackNotification;
    data: any;

}

export interface RaceDataWorkerAwsDataMessage {
    type: RaceDataWorkerMessageType.AwsData;
    data: RaceData;
}

export interface RaceDataWorkerChatterAlertsMessage {
    type: RaceDataWorkerMessageType.ChatterAlerts;
    data: AwsChatterEntryModel[];
}

export enum RaceDataWorkerLogLevel {
    Normal,
    Warning,
    Error
}

export interface RaceDataWorkerLogData {
    level: RaceDataWorkerLogLevel;
    payload: any;
}

export interface RaceDataWorkerLogMessage {
    type: RaceDataWorkerMessageType.Log;
    data: RaceDataWorkerLogData;
}

export type RaceDataWorkerMessage =
    | RaceDataWorkerAwsDataMessage
    | RaceDataWorkerChatterAlertsMessage
    | RaceDataWorkerLogMessage
    | SlackNotificationWorkerDataMessage
    ;

export enum RaceDataWorkerInputMessageType {
    Init,
    Time
}

export interface RaceDataWorkerInitInputMessage {
    type: RaceDataWorkerInputMessageType.Init;
    data: true;
}

export interface RaceDataWorkerTimeInputMessage {
    type: RaceDataWorkerInputMessageType.Time;
    data: number;
}

export type RaceDataWorkerInputMessage =
    | RaceDataWorkerInitInputMessage
    | RaceDataWorkerTimeInputMessage
    ;
