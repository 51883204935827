import React from "react";
import "../Login/Forms.scss";
import {LogoContainer} from "../../components/Logo/LogoContainer";

const ForgotPasswordConfirmation = () => {
    return (
        <div className="insightsBackgroundContainer">
            <LogoContainer/>
            <div className="insightsForm__divSection">
                <h1 className="insightsForm__title">Forgot Password?</h1>
                <p className="insightsForm__text">
                    We have e-mailed you with a link to reset your password. Please check
                    spam folders too.
                </p>
            </div>
        </div>
    );
};

export default ForgotPasswordConfirmation;
