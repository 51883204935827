import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './CombinedBoatStatWidget.scss';
import {CalculateMpsToKmph, FormatTimeInMinutes, FormatTimeInSeconds, Round} from "../../utils/helpers";
import RotatingArrow from '../../components/RotatingArrow';
import {ReactComponent as LeaderBoardTeamIcon} from "../../assets/leaderboard-team-icon.svg";
import {ReactComponent as OutOfRaceTeamIcon} from "../../assets/leaderboard-team-icon-out-of-race.svg";
import {BoatStatusEnum} from '../../models/enums/boatStatusEnum';
import {CountryCodeEnum} from '../../models/enums/countryCodeEnum';
import {RaceStatusEnum} from '../../models/enums/raceStatusEnum';
import {RaceDataContext} from '../../context/raceData.context';
import {BoatDataContext} from '../../context/boatData.context';
import clsx from 'clsx';
import {TimestampContext} from '../../context/timestamp.context';
import {CurrentRaceEventContext} from "../../context/currentRaceEvent.context";

let responsiveTableHeaderHeight = 0;

interface BoatStatsRow {
    position: number | string;
    estimatedTimeFinishMs: number;
    timeBehindNextBoatMs: number | undefined;
    leg: number;
    rideHeight: number;
    rideTime: number;
    windSpeed: number;
    heading: number;
    boatSpeed: number;
    maxSpeed: number | undefined;
    countryCode: string;
    boatStatus: BoatStatusEnum;
    numPenalties: number;
    distanceToLeader: number;
}

function createData(
    position: number | string,
    estimatedTimeFinishMs: number,
    timeBehindNextBoatMs: number | undefined,
    leg: number,
    rideHeight: number,
    rideTime: number,
    windSpeed: number,
    heading: number,
    boatSpeed: number,
    maxSpeed: number | undefined,
    countryCode: CountryCodeEnum,
    boatStatus: BoatStatusEnum,
    numPenalties: number,
    distanceToLeader: number
): BoatStatsRow {
    return {
        position,
        estimatedTimeFinishMs,
        timeBehindNextBoatMs,
        leg,
        rideHeight,
        rideTime,
        windSpeed,
        heading,
        boatSpeed,
        maxSpeed,
        countryCode,
        boatStatus,
        numPenalties,
        distanceToLeader
    };
}

const headCells = [
    {id: 'position', numeric: false, disablePadding: true, label: 'POSITION'},
    {id: 'time', numeric: false, disablePadding: true, label: 'TIME'},
    {id: 'distanceToLeader', numeric: true, disablePadding: true, label: 'DIST TO LEADER (m)'},
    {id: 'leg', numeric: false, disablePadding: true, label: 'LEG'},
    {id: 'rideHeight', numeric: false, disablePadding: false, label: 'RIDE HEIGHT (m)'},
    {id: 'rideTime', numeric: true, disablePadding: false, label: 'RIDE TIME (%)'},
    {id: 'windSpeed', numeric: true, disablePadding: false, label: 'WIND SPEED (km/h)'},
    {id: 'heading', numeric: true, disablePadding: false, label: 'HEADING'},
    {id: 'boatSpeed', numeric: true, disablePadding: false, label: 'BOAT SPEED (km/h)'},
    {id: 'maxSpeed', numeric: true, disablePadding: false, label: 'MAX SPEED (km/h)'}
];

function EnhancedTableHead(props: { order: any; orderBy: any; rowCount: number, width: number }) {
    // const [ref, {height}] = useDimensions();
    const {order, orderBy, width} = props;
    const ref = useRef<HTMLTableRowElement>(null);
    const [headSize, setHeadSize] = useState('')

    useEffect(() => {
        if (ref.current && ref.current.clientHeight) {
            responsiveTableHeaderHeight = ref.current.clientHeight;
        }
    });

    useEffect(() => {
        if (width < 700) {
            setHeadSize('medium')
        }
        if (width > 700) {
            setHeadSize('')
        }
    }, [width])

    return (
        <TableHead>
            <TableRow className='headerRow' ref={ref}>
                {headCells.map((headCell) => (
                    <TableCell
                        className={`headerCell ${headSize}`}
                        key={headCell.id}
                        align='center'
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const CombinedBoatStatWidget = () => {
    // @ts-ignore
    const raceData = useContext(RaceDataContext);
    const boatData = useContext(BoatDataContext);
    const [time] = useContext(TimestampContext);
    const raceStatus = raceData?.status;
    const leadBoatId = raceData?.leadBoatId;
    const {currentEvent} = useContext(CurrentRaceEventContext);

    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0)
    const [tableCellSize, setTableCellSize] = useState('')
    const [iconSize, setIconSize] = useState(25)
    const ref = useRef<HTMLDivElement>(null);

    const getTeamStyle = (countryCode: string) => {
        const teamData = currentEvent?.racingTeams?.find(
            team => team.code === countryCode
        );

        return {
            '--team-color': teamData?.primaryTeamHexColour || '$sailgp-deap-ocean-blue'
        } as React.CSSProperties;
    };

    useEffect(() => {
        if (ref.current && ref.current.clientHeight) {
            setHeight(ref.current.clientHeight);
        }
        if (ref.current && ref.current.clientWidth) {
            setWidth((ref.current.clientWidth))
        }
    });

    useEffect(() => {
        if (width < 700) {
            setTableCellSize('medium')
            setIconSize(20)
        }
        if (width > 700) {
            setTableCellSize('')
            setIconSize(25)
        }
    }, [width])

    const leadBoatRemaining = useMemo(() => {
        if (!leadBoatId) {
            return undefined;
        }

        return boatData.find(boat => boat.boatId === leadBoatId);
    }, [boatData, leadBoatId]);

    const boatDataRows = useMemo(() => {
        if (!boatData || !raceData) {
            return [];
        }

        const rows = boatData
            .slice()
            .sort((a, b) => {
                if (!a.rank && !b.rank) {
                    return a.countryCode.localeCompare(b.countryCode);
                }
                if (a.rank !== 0 && b.rank === 0) return -1;
                if (a.rank === 0 && b.rank !== 0) return 1;
                if (!b.rank) {
                    return -1;
                }
                if (!a.rank) {
                    return 1;
                }

                return a.rank - b.rank;
            })
            .map((boat): BoatStatsRow => createData(
                boat.rank ? boat.rank.toString().padStart(2, '0') : '-',
                boat.estimatedTimeFinishMs,
                boat.estimatedTimeBehindNextBoatMs,
                boat.leg,
                boat.foilingHeight,
                boat.rideTime,
                CalculateMpsToKmph(boat.trueWindSpeed),
                boat.heading,
                CalculateMpsToKmph(boat.boatSpeed),
                boat.maxSpeed ? CalculateMpsToKmph(boat.maxSpeed) : undefined,
                boat.countryCode,
                boat.boatStatus,
                boat.numPenalties,
                boat.distanceToLeader
            ));

        return rows;
    }, [boatData, raceData]);

    function renderBoatTimeCell(row: BoatStatsRow): string {
        if (row.boatStatus === BoatStatusEnum.Finished) {
            return FormatTimeInMinutes(Round((row.estimatedTimeFinishMs - raceData!.startTimeMs) / 1000, 0));
        }

        if (row.countryCode === leadBoatRemaining?.countryCode) {
            return FormatTimeInMinutes(Round((time! - raceData!.startTimeMs) / 1000, 0));
        }

        if (row.timeBehindNextBoatMs && leadBoatRemaining?.countryCode !== row.countryCode) {
            return `+${FormatTimeInSeconds(Round(row.timeBehindNextBoatMs / 1000, 2))}`;
        }

        return FormatTimeInMinutes(0, false);
    }

    return (
        <div className="CombinedBoatStatContainer" ref={ref}>
            <TableContainer className='tableContainerClass'>
                <Table
                    className='table'
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                    stickyHeader
                >
                    <EnhancedTableHead
                        order='asc'
                        orderBy='position'
                        rowCount={boatDataRows.length}
                        width={width}
                    />
                    <TableBody>
                        {boatDataRows.map((row, i) =>
                            <TableRow
                                style={
                                    responsiveTableHeaderHeight && boatDataRows
                                        ? {
                                            height: (height - responsiveTableHeaderHeight) / boatDataRows.length
                                        }
                                        : {}
                                }
                                className={clsx({
                                    tableRow: true,
                                    boatFinished: row.boatStatus === BoatStatusEnum.Finished && (raceStatus === RaceStatusEnum.Started || raceStatus === RaceStatusEnum.Finished),
                                    boatNotInRace: raceStatus === RaceStatusEnum.Started && (
                                        row.boatStatus === BoatStatusEnum.DNS
                                        || row.boatStatus === BoatStatusEnum.DSQ
                                        || row.boatStatus === BoatStatusEnum.DNF)
                                })}
                                tabIndex={-1}
                                key={i}
                            >
                                <TableCell
                                    className={`tableCell ${tableCellSize}`}
                                    align="center"
                                >
                                    <span className="positionLayout">
                                        {
                                            raceStatus === RaceStatusEnum.Started && (
                                                row.boatStatus === BoatStatusEnum.DNS
                                                || row.boatStatus === BoatStatusEnum.DSQ
                                                || row.boatStatus === BoatStatusEnum.DNF)
                                                ? <span className="positionNumber">{row.boatStatus}</span>
                                                : <span className="positionNumber">{row.position}</span>
                                        }
                                        {
                                            row.boatStatus !== BoatStatusEnum.DNS
                                            && row.boatStatus !== BoatStatusEnum.DSQ
                                            && row.boatStatus !== BoatStatusEnum.DNF
                                                ? <LeaderBoardTeamIcon className={clsx('boatIcons', row.countryCode, {
                                                    'finalRace': boatData.length <= 3
                                                })}
                                                                       style={getTeamStyle(row.countryCode)}
                                                />
                                                : <OutOfRaceTeamIcon
                                                    className={clsx('boatIcons', 'boatIconsOutOfRace', row.countryCode)}
                                                    style={getTeamStyle(row.countryCode)}
                                                />
                                        }
                                        {
                                            row.countryCode || null
                                        }
                                        {
                                            row.numPenalties > 0 &&
                                            <span className={`alertIcons penalty ${tableCellSize}`}>P</span>
                                        }
                                    </span>
                                </TableCell>

                                <TableCell
                                    className={clsx({
                                        tableCell: true,
                                        boatTime: true,
                                        boatFinished: row.boatStatus === BoatStatusEnum.Finished && (raceStatus === RaceStatusEnum.Started || raceStatus === RaceStatusEnum.Finished),
                                        medium: tableCellSize
                                    })}
                                    align="center"
                                >
                                    {
                                        time && raceStatus === RaceStatusEnum.Started && (row.boatStatus !== BoatStatusEnum.DNS
                                            && row.boatStatus !== BoatStatusEnum.DSQ
                                            && row.boatStatus !== BoatStatusEnum.DNF)
                                            ? <div className={clsx('boatTime', {
                                                    'finalRace': boatData.length <= 3
                                                }
                                            )}>
                                            <span>
                                                {renderBoatTimeCell(row)}
                                            </span>
                                            </div>
                                            : '-'
                                    }
                                </TableCell>
                                <TableCell
                                    className={`tableCell ${tableCellSize}`}
                                    align='center'
                                >
                                    {row?.distanceToLeader ? <>{`${Round(row?.distanceToLeader, 1)}`} </> : '-'}
                                </TableCell>
                                <TableCell
                                    className={`tableCell ${tableCellSize}`}
                                    align="center"
                                >
                                    {
                                        raceStatus === RaceStatusEnum.Started && (row.boatStatus !== BoatStatusEnum.DNS
                                            && row.boatStatus !== BoatStatusEnum.DSQ
                                            && row.boatStatus !== BoatStatusEnum.DNF)
                                            ? <span>
                                                {row.leg && row.boatStatus !== BoatStatusEnum.Finished ? row.leg : '-'}
                                                /
                                                {raceData?.numberOfLegs ? raceData.numberOfLegs : '-'}
                                            </span>
                                            : '-'
                                    }
                                </TableCell>
                                <TableCell
                                    className={`tableCell ${tableCellSize}`}
                                    align="center"
                                >
                                    {row.rideHeight ? <>{`${Round(row.rideHeight, 1)}`}</> : '-'}
                                </TableCell>
                                <TableCell
                                    className={`tableCell ${tableCellSize}`}
                                    align="center"
                                >
                                    {row.rideTime > 0 ? <>{`${Round(row.rideTime, 0)}`} </> : <>0</>}
                                </TableCell>
                                <TableCell
                                    className={`tableCell ${tableCellSize}`}
                                    align="center"
                                >
                                    {row.windSpeed ? <>{`${Round(row.windSpeed, 1)}`}</> : '-'}
                                </TableCell>
                                <TableCell
                                    className={`tableCell ${tableCellSize}`}
                                    align="center"
                                >
                                    {row.heading ?
                                        <RotatingArrow heading={Round(row.heading, 0)} iconSize={iconSize}/> : '-'}
                                </TableCell>
                                <TableCell
                                    className={`tableCell ${tableCellSize}`}
                                    align="center"
                                >
                                    {row.boatSpeed ? <>{`${Round(row.boatSpeed, 1)}`} </> : '-'}
                                </TableCell>
                                <TableCell
                                    className={`tableCell ${tableCellSize}`}
                                    align="center"
                                >
                                    {row?.maxSpeed ? <>{`${Round(row?.maxSpeed, 1)}`} </> : '-'}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};


export default CombinedBoatStatWidget;
