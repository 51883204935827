import {ChangeEvent, createContext, Dispatch, MouseEvent, SetStateAction} from "react";
import {ReplayVideoStateModel} from "../models/replayVideoStateModel";
import {ContentProxyRace, ContentProxyRaceDay} from "../models/contentProxyModel";


export interface initialPlayingState {
    muted: boolean
    playing: boolean
    played: number
    handlePlayPause: (e: MouseEvent<HTMLElement>) => void;
    handleMuteUnmute: (e: MouseEvent<HTMLElement>) => void;
    handleSeekMouseDown: (e: MouseEvent<HTMLInputElement>) => void;
    handleSeekChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleSeekMouseUp: (e: MouseEvent<HTMLInputElement>) => void;
    handleProgress: (e: ReplayVideoStateModel, useTimestamps: boolean, replayTime: number) => void;
    handleRaceSelect: (race: ContentProxyRace, raceDay: ContentProxyRaceDay) => void;
    seekingTime: number;
    playedSeconds: number;
    volume: number;
    handleVolumeMouseDown: (e: MouseEvent<HTMLInputElement>) => void;
    handleVolumeChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleVolumeMouseUp: (e: MouseEvent<HTMLInputElement>) => void;
    switchEventAndResetTime: (secondsOffset?: number) => void;
    setMuted: Dispatch<SetStateAction<boolean>>
}


export const VideoReplaySyncContext = createContext<initialPlayingState>(
    {
        muted: false,
        playing: true,
        volume: 0,
        handleMuteUnmute: () => null,
        played: 0,
        handlePlayPause: () => null,
        handleSeekMouseDown: () => null,
        handleSeekChange: () => null,
        handleSeekMouseUp: () => null,
        handleProgress: () => null,
        seekingTime: 0,
        playedSeconds: 0,
        handleVolumeMouseDown: () => null,
        handleVolumeChange: () => null,
        handleVolumeMouseUp: () => null,
        handleRaceSelect: () => null,
        switchEventAndResetTime: () => null,
        setMuted: () => null,
    })
