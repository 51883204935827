import React, {useContext, useMemo } from 'react';
import { CurrentRaceEventContext } from './currentRaceEvent.context';
import {TeamDataContext} from "./TeamData.context";

export const TeamDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {


    const { currentEvent } = useContext(CurrentRaceEventContext);

    const contextValue = useMemo(() => ({
        teams: currentEvent?.racingTeams || [],
        getTeamByCountryCode: (code: string) =>
            currentEvent?.racingTeams?.find(team => team.code === code)
    }), [currentEvent?.racingTeams]);

    return (
        <TeamDataContext.Provider value={contextValue}>
            {children}
        </TeamDataContext.Provider>
    );
};