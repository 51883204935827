import { Button, Chip } from '@mui/material';
import { Done } from '@mui/icons-material';
import React, { useMemo, useState } from 'react';
import { areArraysShallowEqual } from '../../../utils/helpers/arrayUtils';
import { alertFilterOptions } from '../alertFilterOptions';
import './AlertFilterControls.scss';

export interface AlertFilterControlsProps {
    filterTypes: string[];
    onApply: (newFilterTypes: string[]) => void;
    onClear: () => void;
}

export const AlertFilterControls = (props: AlertFilterControlsProps) => {
    const [currentFilterTypes, setCurrentFilterTypes] = useState<string[]>(props.filterTypes);

    const isSameSelection = useMemo(() => 
        areArraysShallowEqual(props.filterTypes, currentFilterTypes)
    , [props.filterTypes, currentFilterTypes]);

    const canClear = currentFilterTypes.length > 0;

    function onOptionClicked(alertType: string) {
        if (currentFilterTypes.includes(alertType)) {
            setCurrentFilterTypes(currentFilterTypes.filter(f => f !== alertType));
        } else {
            setCurrentFilterTypes([...currentFilterTypes, alertType]);
        }
    }

    function onApplyClicked() {
        props.onApply(currentFilterTypes);
    }

    function onClearClicked() {
        props.onClear();
    }

    return (
        <div className="alertFilterControls">
            <Button
                className="clearFiltersButton"
                disabled={!canClear}
                onClick={onClearClicked}
            >
                Clear Filters
            </Button>
            <div className="filterOptionsList">
                {alertFilterOptions.map((option) => {
                    const selected = currentFilterTypes.includes(option.alertType);

                    return (
                        <div
                            className="filterOptionContainer"
                            key={option.alertType}
                        >
                            <Chip
                                avatar={
                                    selected
                                        ? <Done className="filterOptionSelectedTick" />
                                        : undefined
                                }
                                className={`
                                filterOptionChip
                                ${selected ? "selected" : ""}
                            `}
                                label={option.displayText}
                                onClick={() => onOptionClicked(option.alertType)}
                                key={option.alertType}
                            />
                        </div>
                    );
                })}
            </div>
            <Button
                className="applyButton"
                variant="contained"
                disabled={isSameSelection}
                disableElevation={isSameSelection}
                onClick={onApplyClicked}
            >
                Apply
            </Button>
        </div>
    );
};
