import {createContext} from "react";
import {ContentProxyRaceEvent} from "../models/contentProxyModel";
import {PipPositionInterface} from "./navigationSliderProvider";

export interface NavigationSliderContextState {
    handleSelect: (event: ContentProxyRaceEvent) => void
    currentSlide: ContentProxyRaceEvent | undefined
    setCurrentPipPosition: (position: PipPositionInterface) => void
    currentPipPosition: PipPositionInterface;
}

export const NavigationSliderContext = createContext<NavigationSliderContextState>({
    setCurrentPipPosition(position: PipPositionInterface): void {
    },
    handleSelect: () => {
    },
    currentSlide: undefined,
    currentPipPosition: {x: 0, y: 0}
});
