import {useCallback, useContext, useEffect, useRef, useState} from "react";
import { fetchFromContentProxy } from "../utils/helpers/fetchFromContentProxy";
import { useInterval } from "./useInterval";
import { useIsMounted } from "./useIsMounted";
import {LoginContext} from "../context/login.context";

export function useContentProxyData<T extends object>(
    pathname: string,
    pollIntervalMs?: number,
    queryParams?: string
): { data: T | undefined, requestsPending: boolean, cancel: () => void } {
    const isMountedRef = useIsMounted();
    const [data, setData] = useState<T>();
    const [numPendingRequests, setNumPendingRequests] = useState(0);
    const abortControllerRef = useRef<AbortController | null>(null);
    const {user} = useContext(LoginContext)

    const fetchData = useCallback(async () => {
        try {
            setNumPendingRequests(prev => prev + 1);

            // Cancel any previous ongoing request
            abortControllerRef.current?.abort();
            abortControllerRef.current = new AbortController();

            const responseData = await fetchFromContentProxy<T>(
                pathname,
                queryParams,
                abortControllerRef.current.signal
            );

            if (!isMountedRef.current) {
                return;
            }

            setData(responseData);
        } catch (e) {
            if (!abortControllerRef.current?.signal.aborted) {
                console.error(`Failed to fetch content proxy data from "${pathname}": `, e);
            }
        } finally {
            setNumPendingRequests(prev => prev - 1);
        }
    }, [isMountedRef, pathname, queryParams, user]);

    useEffect(() => {
        fetchData();

        return () => {
            // Cancel ongoing requests when the component unmounts
            abortControllerRef.current?.abort();
        };
    }, [fetchData]);

    useInterval(() => {
        if (pollIntervalMs) {
            fetchData();
        }
    }, pollIntervalMs ?? 600000);

    const cancel = useCallback(() => {
        abortControllerRef.current?.abort();
    }, []);

    return { data: data, requestsPending: numPendingRequests > 0, cancel };
}