import {countryCodeNameMap} from './helpers/mapCountryCodes';

interface StreamIdRegexRule {
    pattern: RegExp;
    title: (match: RegExpExecArray) => string;
}

const streamIdRules: StreamIdRegexRule[] = [
    // Dev test boat streams
    {
        pattern: /^tata_sailgpboat(?<teamName>[a-z]+)(?<cameraNumber>\d+)_.+$/,
        title: match => {
            const teamName = match.groups!.teamName;

            return `${teamName.charAt(0).toUpperCase() + teamName.slice(1)} Cam ${match.groups!.cameraNumber}`;
        }
    },

    // Production streams
    {
        pattern: /^tata_sailgpworldfeed_ci_.+$/,
        title: () => 'World Feed'
    },
    {
        pattern: /^tata_sailgpumpapp_ci_.+$/,
        title: () => 'UMPAPP'
    },
    {
        pattern: /^tata_sailgpheli_ci_.+$/,
        title: () => 'Helicopter'
    },
    {
        pattern: /^tata_sailgp__event_tv__.+$/,
        title: () => 'Event TV'
    },
    {
        pattern: /^tata_sailgpboat(?<teamName>[a-z]+)(?<cameraNumber>\d+)_.+$/,
        title: match => {
            const teamCode = match.groups!.teamName;
            const cameraNumber = match.groups!.cameraNumber;

            const teamName = countryCodeNameMap[teamCode] ?? '(Unknown)';

            return `${teamName} Boat Cam ${cameraNumber}`;
        }
    }
];

export function getStreamIdTitle(streamId: string): string | undefined {
    for (const rule of streamIdRules) {
        const match = rule.pattern.exec(streamId);

        if (match) {
            return rule.title(match);
        }
    }
}
