import {createContext} from "react";
import {RaceDataModel} from "../models/raceDataModel";

export interface RaceDataContextState extends RaceDataModel {
    startTimeMs: number;
    leadBoatId?: number;
    clearRaceDataDueToUserRaceSwitch?: () => void;
}

export const RaceDataContext = createContext<RaceDataContextState | undefined>({
    numberOfBoats: 0,
    numberOfLegs: 0,
    raceId: 0,
    raceStartTime: "",
    raceType: undefined,
    seaLevel: 0,
    startTimeMs: 0,
    status: undefined,
    windDirection: 0,
    windSpeed: 0,
    msec: 0,
    clearRaceDataDueToUserRaceSwitch: () => null
});
