import {IconButton} from '@mui/material';
import {Close, FilterList} from '@mui/icons-material';
import React, {useEffect, useMemo, useState} from 'react';
import {useLocalStorageState} from '../../hooks/useLocalStorageState';
import {AlertDisplay} from './AlertDisplay';
import {AlertFilterControls} from './AlertFilterControls';
import {alertFilterOptions} from './alertFilterOptions';
import './AlertWidget.scss';

const AlertWidget = () => {
    const [showFilterControls, setShowFilterControls] = useState(false);
    const [displayedAlertTypes, setDisplayedAlertTypes] = useLocalStorageState<string[]>('chatterSelectedAlertFilters', []);

    useEffect(() => {
        // Clean up any selected filters that aren't in the list of options
        // This could happen if we deploy a new version that removes alert types, but the user still has local storage with old values
        const onlyValidFilters = displayedAlertTypes.filter(
            f => alertFilterOptions.some(option => option.alertType === f)
        );

        if (onlyValidFilters.length < displayedAlertTypes.length) {
            setDisplayedAlertTypes(onlyValidFilters);
        }
    });

    const filterNumberTitleText = useMemo(() =>
        alertFilterOptions
            .filter(option => displayedAlertTypes.includes(option.alertType))
            .map(option => option.displayText)
            .join('\n')
            || undefined
    , [displayedAlertTypes]);

    function onShowFilterControlsClicked() {
        setShowFilterControls(true);
    }

    function onCloseFilterControlsClicked() {
        setShowFilterControls(false);
    }

    function onApplyFilters(newFilter: string[]) {
        setShowFilterControls(false);
        setDisplayedAlertTypes(newFilter);
    }

    function onClearFilters() {
        setShowFilterControls(false);
        setDisplayedAlertTypes([]);
    }

    return (
        <div className="AlertWidgetContainer">
            <div className="header">
                <span className="headerText">
                    {
                        showFilterControls
                            ? 'Filter Alerts'
                            : 'Alerts'
                    }
                </span>
                {
                    showFilterControls
                        ? (
                            <IconButton
                                className="closeFilterControlsButton"
                                onClick={onCloseFilterControlsClicked}
                                size="large">
                                <Close />
                            </IconButton>
                        ) : (
                            <div className="showFilterControlsContainer">
                                <IconButton
                                    className="showFilterControlsButton"
                                    onClick={onShowFilterControlsClicked}
                                    size="large">
                                    <FilterList />
                                </IconButton>
                                {
                                    displayedAlertTypes.length > 0
                                        ? (
                                            <span title={filterNumberTitleText}>
                                                ({displayedAlertTypes.length})
                                            </span>
                                        ) : null
                                }
                            </div>
                        )
                }
            </div>
            <div className="contentContainer">
                <div className="alertDisplayContainer">
                    <AlertDisplay
                        displayedAlertTypes={displayedAlertTypes}
                    />
                </div>
                {
                    showFilterControls && (
                        <div className="filterControlsContainer">
                            <AlertFilterControls
                                filterTypes={displayedAlertTypes}
                                onApply={onApplyFilters}
                                onClear={onClearFilters}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
};


export default AlertWidget;
