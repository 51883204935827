import  { SetStateAction, createContext } from "react";

export const TimerContext = createContext({
	
    seconds:0,
    minutes:0,
    hours:0,
    days:0,
    start:()=>{},
    pause:()=>{},
    reset:()=>{},
    timeForReward:0,
    rewardPoints:0,
  setResponse:(value:SetStateAction<number>,value1:SetStateAction<number>)=>{},
  isActivitySearchSuccess:false,
  setActivitySearchSuccess:(value:SetStateAction<boolean>)=>{},
  isActivitySearchFailure:false,
  setActivitySearchFailure:(value:SetStateAction<boolean>)=>{},
});
