import React, {MouseEvent, PropsWithChildren, useContext, useState} from "react";
import {TimestampContext} from "./timestamp.context";
import {useStableCallback} from "../hooks/useStableCallback";
import {Round} from "../utils/helpers";
import {ReplayVideoStateModel} from "../models/replayVideoStateModel";
import {VideoReplaySyncContext} from "./videoReplaySync.context";
import {ContentProxyRace, ContentProxyRaceDay} from "../models/contentProxyModel";
import {DateTime} from "luxon";
import {RaceDataContext} from "./raceData.context";
import ReactGA from "react-ga";
import {ReactGaEnum} from "../models/enums/reactGAEnum";

const videoSyncMaxDurationSeconds = 0.5

export const VideoReplaySyncProvider = ({children}: PropsWithChildren<{}>) => {
    // store the id of the current playing player
    const raceData = useContext(RaceDataContext);
    const [muted, setMuted] = useState<boolean>(false);

    const [playing, setPlaying] = useState(true);
    const [seeking, setSeeking] = useState(false);
    const [seekingTime, setSeekingTime] = useState(0);
    const [volume, setVolume] = useState(0.1);
    const [, setTimestamp] = useContext(TimestampContext);

    const [played, setPlayed] = useState(0);
    const [playedSeconds, setPlayedSeconds] = useState(0);

    const handlePlayPause = () => {
        setPlaying(!playing);
    }

    const handleMuteUnmute = () => {
        setMuted(!muted);
    }

    const handleVolumeMouseDown = (event: MouseEvent<HTMLInputElement>) => {
        let e = event.target as HTMLInputElement;
        setVolume(parseFloat(e.value));
    }

    const handleVolumeMouseUp = (event: MouseEvent<HTMLInputElement>) => {
        let e = event.target as HTMLInputElement;
        setVolume(parseFloat(e.value));
    }

    const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVolume(parseFloat(e.target.value));
    }


    const handleSeekMouseDown = (event: MouseEvent<HTMLInputElement>) => {
        let e = event.target as HTMLInputElement;
        setSeekingTime(parseFloat(e.value));
        setSeeking(true)
    }

    const handleSeekMouseUp = (event: MouseEvent<HTMLInputElement>) => {
        let e = event.target as HTMLInputElement;
        setSeekingTime(parseFloat(e.value));
        setSeeking(false)
    }

    const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSeeking(true);
        setPlayed(parseFloat(e.target.value));
    }

    const onTimestampReceived = useStableCallback((timestamp: number, useTimestamps: boolean) => {
        if (useTimestamps) {
            setTimestamp(timestamp);
        }
    });

    const handleRaceSelect = async (race: ContentProxyRace, raceDay: ContentProxyRaceDay) => {
        if (raceDay && raceDay.replayStreams) {
            const raceDayStart = DateTime.fromISO(raceDay.replayStreams.startTime).valueOf()
            //we want to start the race 2 minutes before the actual race start time
            const preStartTimeMillis = raceDay.replayStreams.raceStartTimeOffsetMillis || 500
            const raceStart = DateTime.fromISO(race.startDateTime).valueOf() - preStartTimeMillis
            const seekTo = (raceStart - raceDayStart) / 1000;
            setSeekingTime(seekTo);
            setPlayedSeconds(seekTo);
            setSeeking(false);
            setPlaying(true)
        }
        if (raceData && raceData.clearRaceDataDueToUserRaceSwitch) {
            raceData.clearRaceDataDueToUserRaceSwitch()
        }

        ReactGA.event({
            category: ReactGaEnum.HandleRaceSelect,
            action: `Race selected ${race.name} on ${race.raceId}`
        });

    }
    const switchEventAndResetTime = (secondsOffset?: number) => {
        setPlayedSeconds(secondsOffset || 0)
        setPlaying(true)
    }


    const handleProgress = async (state: ReplayVideoStateModel, useTimestamps: boolean, replayTime: number) => {
        const timeOffset = state.playedSeconds;

        if (useTimestamps) {
            onTimestampReceived(Round(replayTime + (timeOffset * 1000), 0), useTimestamps);
        }
        if (!seeking && playing) {
            await handleBufferZone(state.playedSeconds)
            setPlayed(state.played);

        }
    }

    const handleBufferZone = async (currentPlayedSecondsFromPlayer: number) => {
        if ((playedSeconds - currentPlayedSecondsFromPlayer) > videoSyncMaxDurationSeconds) {
            console.warn('Video out of sync by, Buffering --> ', currentPlayedSecondsFromPlayer - playedSeconds)
            setSeekingTime(currentPlayedSecondsFromPlayer);
            setPlayedSeconds(currentPlayedSecondsFromPlayer - playedSeconds);
            setSeeking(false);
        } else {
            setPlayedSeconds(currentPlayedSecondsFromPlayer);
        }
    }

    return (
        <VideoReplaySyncContext.Provider value={{
            muted,
            playing,
            played,
            handlePlayPause,
            handleSeekMouseDown,
            handleSeekChange,
            handleSeekMouseUp,
            handleProgress,
            handleRaceSelect,
            seekingTime,
            playedSeconds,
            volume,
            handleMuteUnmute,
            handleVolumeMouseDown,
            handleVolumeChange,
            handleVolumeMouseUp,
            switchEventAndResetTime,
            setMuted
        }}>
            {children}
        </VideoReplaySyncContext.Provider>
    )
};