import React from "react";
import {LogoContainer} from "../../components/Logo/LogoContainer";

const EmailVerifyNotificaion = () => {

    return (
        <div className="insightsBackgroundContainer">
            <LogoContainer/>
            <div className="emailVerification__textContainer">
                <h1>Please verify your e-mail.</h1>
                <h3>We have e-mailed you with a link to verify your account. Please check spam folders too.</h3>
            </div>

        </div>
    )


}

export default EmailVerifyNotificaion;
