import React, {useContext, useEffect, useState} from 'react';
import {Button, Popover} from "@mui/material";
import {LoginContext} from "../../context/login.context";
import './AdditionalUserDataModal.scss'
import '../../pages/Login/Forms.scss'
import {useForm} from "react-hook-form";
import {useContentProxyData} from "../../hooks/useContentProxyData";
import {ContentProxyTeam} from "../../models/contentProxyModel";
import {submitForm} from "../../utils/helpers/submitForm";

const AdditionalUserDataModal = () => {
    const {register, handleSubmit, formState: {errors}} = useForm(({mode: 'onBlur'}))
    const {user, setLoginStatus, setAdditionalDataFailed, additionalDataFailed, isVip} = useContext(LoginContext)
    const [openAdditionalDataPopover, setOpenAdditionalDataPopover] = useState<boolean>(true)
    const {data: teams} = useContentProxyData<ContentProxyTeam[]>('teams', 0, 'incGeneral=true&onlyActive=true')

    useEffect(() => {
        if ((user?.favouriteTeam && user?.gender) || isVip) setOpenAdditionalDataPopover(false)
        if (!user?.favouriteTeam && !user?.gender && !additionalDataFailed && !isVip) setOpenAdditionalDataPopover(true)
    }, [user?.favouriteTeam, user?.gender, additionalDataFailed, isVip])

    const onSubmit = async (data: any) => {
        setOpenAdditionalDataPopover(false)

        if (process.env.REACT_APP_UPDATE_PROFILE) {
            const additionalDataResponse = await submitForm(data, process.env.REACT_APP_UPDATE_PROFILE)

            if (additionalDataResponse.status === 'success') {
                setLoginStatus(true, {...user, ...data})
            } else {
                setAdditionalDataFailed(true)
            }
        }

    }
    const genderOptions = {
        Male: 'MALE',
        Female: 'FEMALE',
        'Non-Binary': 'NONBINARY',
        'Prefer not to identify': 'PREFERNOT',
        Other: 'OTHER'
    }

    return (
        <Popover open={openAdditionalDataPopover} anchorEl={document.getElementById('sliderContainer')}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}
                 anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}
                 style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}>
            <div className='additionalUserDataModal'>
                <div className='modalImage'/>
                <div className='modalTitleContainer'>
                    <h1 className='modalTitle'>
                        Before you continue
                    </h1>
                    <h1 className='modalTitle modalTitle__bold'> we need more information </h1>
                </div>

                <form className='insightsForm modalForm' onSubmit={handleSubmit(onSubmit)}>
                    <div className='modalForm__inputContainer'>
                        <label className='insightsForm__label modalForm__label' htmlFor='gender'>
                            Gender
                        </label>
                        <select
                            className={`insightsForm__input modalForm__input ${errors.gender ? "modalForm__input--error" : ""} `}
                            {...register('gender', {required: true})}>
                            <option
                                value=""
                                className="select-option"
                            >
                                -- Please select --
                            </option>
                            {Object.keys(genderOptions).map(genderOption => {
                                return <option key={genderOption}
                                               value={genderOptions[genderOption]}>{genderOption}</option>
                            })}
                        </select>

                    </div>
                    <div className='modalForm__inputContainer'>
                        <label className='insightsForm__label modalForm__label' htmlFor='favouriteTeam'>
                            Favourite Team
                        </label>
                        <select
                            className={`insightsForm__input modalForm__input ${errors.favouriteTeam ? "modalForm__input--error" : ""} `}
                            {...register('favouriteTeam', {required: true})}>
                            <option
                                value=""
                                className="select-option"
                            >
                                -- Please select --
                            </option>
                            {teams && teams.map(team => {
                                return <option key={team.contentfulId} value={team.code}>{team.name}</option>
                            })}
                        </select>
                    </div>

                    <Button className={`modalForm__submit ${
                        Object.keys(errors).length > 0
                            ? "modalForm__submit--disabled"
                            : ""
                    }`}
                            type='submit'
                            disabled={Object.keys(errors).length > 0}>
                        Continue
                    </Button>
                </form>

            </div>

        </Popover>
    );
};

export default AdditionalUserDataModal;