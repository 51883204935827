import {Dispatch, SetStateAction} from "react";
import {ContentProxyRaceDayStatus, ContentProxyRaceEvent} from "../../models/contentProxyModel";

export function loopEvents(
  currentEvent: ContentProxyRaceEvent | undefined,
  selectedRaceDayId: string | undefined,
  switchRaceEvent: (
    eventId: string,
    raceDayId: string,
    isLiveRaceDay: boolean
  ) => void,
  allRaceEvents: ContentProxyRaceEvent[] | undefined,
  switchEventAndResetTime: () => void,
  setIsOpen: Dispatch<boolean>,
  videoDuration: number,
  playedSeconds: number,
  autoPlayNext: boolean,
  setAutoPlayNext: Dispatch<boolean>,
  setIsAtEndOfVideo: Dispatch<boolean>,
  setEndOfVideoTime: Dispatch<number|undefined>
): void {

    let currentRaceDayIndex;
    let raceDaysLength;
    if(autoPlayNext) {
      if(currentEvent?.raceDays) {
        for (let i = 0; i < currentEvent?.raceDays?.length; i++) {
          raceDaysLength = currentEvent?.raceDays?.length;
          if (currentEvent?.raceDays[i].contentfulId === selectedRaceDayId) {
            currentRaceDayIndex = i;
          }
        }
      }

      if (raceDaysLength && currentRaceDayIndex !== raceDaysLength - 1 && typeof currentRaceDayIndex === 'number' && currentEvent?.raceDays) {
        const nextDayHasReplays = currentEvent?.raceDays[currentRaceDayIndex + 1].state === ContentProxyRaceDayStatus.ReplayAvailable;

        if (nextDayHasReplays) {
          const endOfNextVideo = currentEvent?.raceDays[currentRaceDayIndex + 1]?.replayStreams?.endOfVideoInSeconds
          setEndOfVideoTime(endOfNextVideo)
          switchRaceEvent(currentEvent?.contentfulId, currentEvent?.raceDays[currentRaceDayIndex + 1].contentfulId, false);
          switchEventAndResetTime();
          setIsAtEndOfVideo(false)
        }
      } else {
        let currentEventIndex;
        allRaceEvents?.forEach((event, index) => {
          if (event.contentfulId === currentEvent?.contentfulId) {
            currentEventIndex = index;
          }
        });

        let nextRaceEvent
        if(typeof currentEventIndex === 'number') {

          if (allRaceEvents) {
            nextRaceEvent = allRaceEvents[currentEventIndex + 1]
          }

          if (nextRaceEvent?.raceDays) {
            if (allRaceEvents) {
              const nextRaceDayReplay = nextRaceEvent.raceDays.find(
                  (raceDay) => raceDay.state === ContentProxyRaceDayStatus.ReplayAvailable
              );

              if (nextRaceDayReplay) {
                switchRaceEvent(allRaceEvents[currentEventIndex + 1].contentfulId, nextRaceDayReplay.contentfulId, false);
                switchEventAndResetTime();
                setIsAtEndOfVideo(false)
                const endOfNextVideo = nextRaceDayReplay?.replayStreams?.endOfVideoInSeconds
                setEndOfVideoTime(endOfNextVideo)
              } else {
                if (allRaceEvents && allRaceEvents[0].raceDays) switchRaceEvent(allRaceEvents[0].contentfulId, allRaceEvents[0].raceDays[0].contentfulId, false);
                switchEventAndResetTime();
                setIsAtEndOfVideo(false)
              }
            }
          } else {
            if (allRaceEvents && allRaceEvents[0].raceDays) switchRaceEvent(allRaceEvents[0].contentfulId, allRaceEvents[0].raceDays[0].contentfulId, false);
            switchEventAndResetTime();
            setIsAtEndOfVideo(false)
          }

        }
      }
       setAutoPlayNext(false)
    }
}
