import {useContext, useEffect, useState} from "react";
import {AutoPlayContext} from "../context/autoPlay.context";
import {CurrentRaceEventContext} from "../context/currentRaceEvent.context";
import {VideoReplaySyncContext} from "../context/videoReplaySync.context";
import {loopEvents} from "../utils/helpers/loopEvents";
import {getNextRaceTitle} from "../utils/helpers/getNextRaceTitle";


export const useAutoPlayEvents = () => {
    const [endOfVideoTime, setEndOfVideoTime] = useState<number | undefined>(0)
    const {setIsOpen, videoDuration, autoPlayNext, setAutoPlayNext, setCurrentTime, isAtEndOfVideo, setIsAtEndOfVideo, setNextRaceSubtitle, setNextRaceShortName} = useContext(AutoPlayContext)
    const {currentEvent, selectedRaceDayId, switchRaceEvent, allRaceEvents} = useContext(CurrentRaceEventContext);
    const {switchEventAndResetTime, playedSeconds} =  useContext(VideoReplaySyncContext)

    useEffect(() => {
        if(endOfVideoTime) {
            if (playedSeconds >= endOfVideoTime) setIsAtEndOfVideo(true)
        }

        const defaultSecondsFromEnd = 180
        if (videoDuration > 1) {
           if(videoDuration - playedSeconds <= defaultSecondsFromEnd) setIsAtEndOfVideo(true)
        }
    }, [playedSeconds])

    useEffect(() => {

        if(isAtEndOfVideo){
            setIsOpen(true)
            setCurrentTime(Date.now())
        }

    }, [isAtEndOfVideo])

    useEffect(() => {
        getNextRaceTitle(currentEvent, allRaceEvents, setNextRaceShortName, setNextRaceSubtitle, selectedRaceDayId)

    }, [selectedRaceDayId, currentEvent])

    useEffect(() => {
        loopEvents(
            currentEvent,
            selectedRaceDayId,
            switchRaceEvent,
            allRaceEvents,
            switchEventAndResetTime,
            setIsOpen,
            videoDuration,
            playedSeconds,
            autoPlayNext,
            setAutoPlayNext,
            setIsAtEndOfVideo,
            setEndOfVideoTime
        )

    }, [autoPlayNext])

}