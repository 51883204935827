import {useEffect, useState} from "react";

function useLocalStorageState<T>(key: string, defaultVal: T) {
    const [state, setState] = useState(() => {
        let value: T;
        try {
            const rawItem = localStorage.getItem(key);
            value = rawItem
                ? JSON.parse(rawItem)
                : defaultVal;
        } catch (e) {
            value = defaultVal;
        }
        return value;
    });

    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(state));
    }, [state, key]);

    return [state, setState] as const;
}

export { useLocalStorageState };
