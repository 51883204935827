export enum RaceStatusEnum {
    None = 'None',
    Started = 'Started',
    Finished = 'Finished',
    Terminated = 'Terminated',
    Warning = 'Warning',
    Preparatory = 'Preparatory', //less than 2 mins to the start
    Prestart = 'Prestart'

}
