import {createContext, Dispatch, SetStateAction} from "react";
import {
    ContentProxyAppSettings,
    ContentProxyNextRaceDateAndTime,
    ContentProxyRaceEvent,
    ContentProxyRaceLiveStream,
    ExternalVideo
} from "../models/contentProxyModel";
import {BroadcasterConfigModel} from "../models/broadcasterConfigModel";

export enum RaceTypeEnum {
    live = 'live',
    replay = 'replay'
}


export interface CurrentRaceEventContextState {
    currentEvent: ContentProxyRaceEvent | undefined;
    isLoading: boolean;
    appSettings: ContentProxyAppSettings | undefined;
    allRaceEvents: ContentProxyRaceEvent[] | undefined;
    selectedRaceDayId: string | undefined;
    switchRaceEvent: (eventId: string | undefined, raceDayId: string | undefined, isLiveRaceDay: boolean) => void,
    raceType: RaceTypeEnum | undefined;
    nextEvent: ContentProxyRaceEvent | undefined;
    nextScheduledEventDateAndTime: ContentProxyNextRaceDateAndTime | undefined;
    broadcasterRegionalConfig: BroadcasterConfigModel | undefined;
    resetEvent: () => void;
    streamsAvailableToUser: (ExternalVideo | undefined)[] | undefined;
    raceLiveStreamsSettings: ContentProxyRaceLiveStream | undefined;
    isRaceFinished: boolean,
    broadcastAvailable: boolean,
    setBroadcastAvailable: Dispatch<SetStateAction<boolean>>
}

export const CurrentRaceEventContext = createContext<CurrentRaceEventContextState>({
    currentEvent: undefined,
    isLoading: false,
    appSettings: undefined,
    allRaceEvents: undefined,
    selectedRaceDayId: undefined,
    switchRaceEvent: () => {
    },
    raceType: RaceTypeEnum.live,
    nextEvent: undefined,
    nextScheduledEventDateAndTime: undefined,
    broadcasterRegionalConfig: undefined,
    resetEvent: () => {
    },
    streamsAvailableToUser: undefined,
    raceLiveStreamsSettings: undefined,
    isRaceFinished: false,
    broadcastAvailable: false,
    setBroadcastAvailable: () => {
    }
});
