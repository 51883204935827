import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import "./EventSelected.scss";
import {
  ContentProxyRaceDay,
  ContentProxyRaceDayStatus,
  ContentProxyRaceEvent,
  TicketAvailableEnum,
} from "../../../../../models/contentProxyModel";
import { formatRaceTimeFriendlyText } from "../../../../../utils/helpers";
import { BroadcasterConfigModel } from "../../../../../models/broadcasterConfigModel";
import Countdown from "react-countdown";
import VideoWidget, {
  VideoWidgetDefaultStreamType,
} from "../../../../../widgets/VideoWidget/VideoContainer";
import { CurrentRaceEventContext } from "../../../../../context/currentRaceEvent.context";
import { Button } from "@mui/material";
import RaceSelectModal from "../ThumbnailCardComponent/RaceSelectModal";
import FanZoneHero from "../../../../../components/FanzoneHero";
import { LoginContext } from "../../../../../context/login.context";

export interface RaceScheduleDayHeaderProps {
  event: ContentProxyRaceEvent;
  changeRaceEvent: (
    eventId: string,
    raceDay: ContentProxyRaceDay,
    isLiveRace: boolean
  ) => void;
  nextEvent: ContentProxyRaceEvent | undefined;
  broadcasterRegionalConfig: BroadcasterConfigModel | undefined;
  hasVideo: boolean | undefined;
  firstRaceDay: ContentProxyRaceDay | undefined;
  showWatchButton: boolean;
}

const EventSelected = ({
  event,
  changeRaceEvent,
  broadcasterRegionalConfig,
  hasVideo,
  firstRaceDay,
  showWatchButton,
}: RaceScheduleDayHeaderProps) => {
  const { broadcastAvailable, setBroadcastAvailable } = useContext(
    CurrentRaceEventContext
  );
  const [isRaceSelectorModalOpen, setRaceSelectorModalOpen] =
    useState<boolean>(false);
  const millisecondsInHour = 3600000;
  const { isVip } = useContext(LoginContext);
  const handleEventSelectedClick = () => {
    if (firstRaceDay && !showWatchButton)
      changeRaceEvent(
        event.contentfulId,
        firstRaceDay,
        firstRaceDay.state === ContentProxyRaceDayStatus.InProgress
      );
  };

  return (
    <>
      <div
        className={`eventSelectedContainer ${
          hasVideo && broadcastAvailable ? "eventSelectedContainer--video" : ""
        }`}
        onClick={handleEventSelectedClick}
      >
        <div className="eventSelectedBackground">
          <div className="eventSelectedBackgroundShadow" />
          {hasVideo && broadcastAvailable ? (
            <div className="eventSelectedBackgroundMedia eventSelectedBackgroundMedia--video">
              <div className="eventSelectedVideoWidgetContainer">
                <VideoWidget
                    useTimestamps
                    defaultStreamType={VideoWidgetDefaultStreamType.WorldFeed}
                    isRaceSchedule={true}
                />
              </div>
            </div>
          ) : (
            <div
              className="eventSelectedBackgroundMedia"
              style={{ backgroundImage: `url(${event.venueImage?.file.url})` }}
            />
          )}
        </div>
        <div className="eventSelectedArea">
          {!isVip && <FanZoneHero />}
          <div className={`eventSelectedAreaContainer`}>
            <div className="eventTitle">{event.shortName}</div>

            {event.locationName !== event.shortNameSubtitle && (
              <span className="eventSubtitle">{event.shortNameSubtitle}</span>
            )}
            <div className="eventLocation">{event?.locationName}</div>

            {!broadcastAvailable &&
              event.raceDays &&
              broadcasterRegionalConfig &&
              event.ticketAvailableState !== TicketAvailableEnum.yes && (
                <div className="countdownWrapper">
                  <span className="replayAvailableText">
                    Race replay available in{" "}
                  </span>
                  <Countdown
                    date={Math.abs(
                      new Date(event.endDateTime).getTime() +
                        (broadcasterRegionalConfig.replayDelayHours
                          ? broadcasterRegionalConfig?.replayDelayHours *
                            millisecondsInHour
                          : 0)
                    )}
                    onComplete={() => setBroadcastAvailable(true)}
                  />
                </div>
              )}
            {showWatchButton && firstRaceDay && (
              <div className="eventSelectedArea__button-container">
                <Button
                  onClick={() => setRaceSelectorModalOpen(true)}
                  className="eventSelectedArea__button"
                >
                  Watch
                </Button>
              </div>
            )}
            <div
              className={`eventDate ${
                showWatchButton ? "eventDate__small-margin" : ""
              }`}
            >
              {formatRaceTimeFriendlyText(
                event.startDateTime,
                event.endDateTime
              )}
            </div>
            <div className="eventDescription">{event.preRaceSummary}</div>
          </div>
        </div>
      </div>
      <RaceSelectModal
        isRaceSelectorModalOpen={isRaceSelectorModalOpen}
        setRaceSelectorModalOpen={setRaceSelectorModalOpen}
        event={event}
        changeRaceEvent={changeRaceEvent}
      />
    </>
  );
};

export default EventSelected;
