import React, {useContext, useMemo} from 'react';
import './TimeToStartWidget.scss';
import {FormatTimeInHours, Round} from "../../utils/helpers";
import {BoatStatusEnum} from '../../models/enums/boatStatusEnum';
import {RaceStatusEnum} from '../../models/enums/raceStatusEnum';
import {TimestampContext} from '../../context/timestamp.context';
import {RaceDataContext} from '../../context/raceData.context';
import {BoatDataContext} from '../../context/boatData.context';
import PopStatCard from '../../components/popStatCards';

const TimeToStartWidget = () => {
    const [timestamp] = useContext(TimestampContext);
    const raceData = useContext(RaceDataContext);
    const boatData = useContext(BoatDataContext);

    const leadBoat = useMemo(() => {
        if (!raceData?.leadBoatId) {
            return undefined;
        }

        return boatData.find((boat) => boat.boatId === raceData.leadBoatId);
    }, [boatData, raceData?.leadBoatId]);


    const renderStateLabel = () => {
        if (leadBoat && leadBoat.leg !== raceData?.numberOfLegs && leadBoat.boatStatus !== BoatStatusEnum.Finished) {
            return `TIME TO MARK ${leadBoat.leg ? leadBoat.leg + 1 : 1}`;
        }
        if (raceData?.status === RaceStatusEnum.Started && leadBoat?.leg === raceData.numberOfLegs) {
            return `TIME TO FINISH`;
        }

        if (boatData.length > 0 && boatData.every(boat => boat.boatStatus === BoatStatusEnum.Finished)) {
            return `RACE FINISHED`;
        }

        if (timestamp && raceData?.status === RaceStatusEnum.Preparatory) {
            return `TIME TO START`;
        } else return '';
    };


    const renderStateValue = () => {
        //guard
        if (leadBoat && timestamp && (leadBoat.estimatedTimeFinishMs - timestamp) > 1000000) return '--';

        if (leadBoat && leadBoat.leg !== raceData?.numberOfLegs && leadBoat.boatStatus !== BoatStatusEnum.Finished) {
            return timestamp && leadBoat.estimatedTimeNextMarkMs
                ? FormatTimeInHours(Round((leadBoat.estimatedTimeNextMarkMs - timestamp) / 1000, 0), false)
                : "no data";
        }
        if (leadBoat && raceData?.status === RaceStatusEnum.Started && leadBoat?.leg === raceData.numberOfLegs) {
            return timestamp
                ? FormatTimeInHours(Round((leadBoat.estimatedTimeFinishMs - timestamp) / 1000, 0), false)
                : null;
        }

        if (timestamp && raceData?.status === RaceStatusEnum.Preparatory) {
            // Cowes in 2019 races started at the wrong times. We need to handle this to match the data
            if(raceData.raceId === 19081102){
                return FormatTimeInHours(Round(Math.max((raceData.startTimeMs - timestamp - 6500), 0) / 1000, 0), false);
            }
            if(raceData.raceId === 19081103){
                return FormatTimeInHours(Round(Math.max((raceData.startTimeMs - timestamp - 5500), 0) / 1000, 0), false);
            }
            return FormatTimeInHours(Round(Math.max(raceData.startTimeMs - timestamp, 0) / 1000, 0), false);
        } else return '--';

    };


    return (
        <div className="timeToStartWidgetContainer">
            {(() => {
                return (
                    <PopStatCard data={{
                        label: renderStateLabel(),
                        value: renderStateValue()
                    }}/>
                );
            })()}
        </div>

    );
};


export default TimeToStartWidget;
