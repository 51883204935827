import {createContext, Dispatch, SetStateAction} from 'react'

export interface AutoPlayContextState {
    isOpen: boolean,
    setIsOpen: Dispatch<boolean>,
    videoDuration: number,
    setVideoDuration: Dispatch<number>,
    autoPlayNext: boolean
    setAutoPlayNext: Dispatch<boolean>
    currentTime: number,
    setCurrentTime: Dispatch<number>,
    isAtEndOfVideo: boolean,
    setIsAtEndOfVideo: Dispatch<boolean>,
    nextRaceShortName: string,
    setNextRaceShortName: Dispatch<string>,
    nextRaceSubtitle: string,
    setNextRaceSubtitle: Dispatch<string>
}

export const AutoPlayContext = createContext<AutoPlayContextState>({
    isOpen: false,
    setIsOpen: () => {},
    videoDuration: 0,
    setVideoDuration: () => {},
    autoPlayNext: false,
    setAutoPlayNext: () => {},
    currentTime: 0,
    setCurrentTime: () => {},
    isAtEndOfVideo: false,
    setIsAtEndOfVideo: () => {},
    nextRaceShortName: "",
    setNextRaceShortName: () => {},
    nextRaceSubtitle: "",
    setNextRaceSubtitle: () => {}

})