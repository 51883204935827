export enum BoatStatusEnum {
    None = 'None',
    Prestart = 'Prestart',
    Racing = 'Racing',
    Finished = 'Finished',
    DNS = 'DNS',
    DNF = 'DNF',
    DSQ = 'DSQ',
    OCS = 'OCS',
    DNC = 'DNC',
}
