import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./thumbnailCardComponent.scss";
import {
  ContentProxyRaceDay,
  ContentProxyRaceDayStatus,
  ContentProxyRaceEvent,
  TicketAvailableEnum,
} from "../../../../../models/contentProxyModel";
import clsx from "clsx";
import CountryFlagWithRaceNumber from "../../../../../components/CountryFlagWithRaceNumber";
import RaceSelectModal from "./RaceSelectModal";
import { submitForm } from "../../../../../utils/helpers/submitForm";
import { TimerContext } from "../../../../../context/timer.context";

export interface ThumbnailCardProps {
  event: ContentProxyRaceEvent;
  currentSlide: ContentProxyRaceEvent | undefined;
  handleSelect: (event: ContentProxyRaceEvent) => void;
  changeRaceEvent: (
    eventId: string,
    raceDay: ContentProxyRaceDay,
    isLiveRace: boolean
  ) => void;
  setShowWatchButton: Dispatch<SetStateAction<boolean>>;
}

const ThumbnailCardComponent = ({
  event,
  currentSlide,
  handleSelect,
  changeRaceEvent,
  setShowWatchButton,
}: ThumbnailCardProps) => {
  const [isRaceSelectorModalOpen, setRaceSelectorModalOpen] =
    useState<boolean>(false);
  const isActive =
    currentSlide && currentSlide.contentfulId === event?.contentfulId;

  const isLiveEvent = useMemo(() => {
    if (!event?.raceDays) {
      return false;
    }
    return event?.raceDays?.find(
      (raceDay: ContentProxyRaceDay) =>
        raceDay.state === ContentProxyRaceDayStatus.InProgress
    );
  }, [event?.raceDays]);

  const isUpcomingEvent = useMemo(() => {
    return new Date(Date.now()) < new Date(event.startDateTime);
  }, [event]);

  const hasTicketsAvailable = useMemo(() => {
    return event.ticketAvailableState === TicketAvailableEnum.yes;
  }, [event]);
  const [videoTypeNew, setVideoTypeNew] = useState<string>("");
  const {
    setResponse,

    setActivitySearchSuccess,
    setActivitySearchFailure,
  } = useContext(TimerContext);
  const handleThumbnailClick = async () => {
    handleSelect(event);
    setRaceSelectorModalOpen(true);
    setShowWatchButton(false);
    setActivitySearchSuccess(false);
    setActivitySearchFailure(false);
    let timerData = {
      ctActivityId: event.ctActivityId,
      videotype: videoTypeNew,
    };

    const timerResponse = await submitForm(
      timerData,
      "user/user-activity-search"
    );

    try {
      if (
        timerResponse &&
        timerResponse["status"] !== undefined &&
        timerResponse["status"] === "success" &&
        (timerResponse["user_num_completions"] === 0 ||
          timerResponse["user_num_completions"] === null)
      ) {
        setResponse(timerResponse["time"], timerResponse["num_points"]);
        setActivitySearchSuccess(true);

        localStorage.setItem("ctActivityID", event.ctActivityId);
      } else if (
        timerResponse &&
        timerResponse["status"] !== undefined &&
        timerResponse["status"] === "success" &&
        timerResponse["user_num_completions"] >= 1
      ) {
        setActivitySearchSuccess(false);
        setActivitySearchFailure(true);
      } else if (timerResponse["status"] === "error") {
        setActivitySearchSuccess(false);
        setActivitySearchFailure(false);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (isLiveEvent && isLiveEvent != undefined) {
      setVideoTypeNew("live");
    } else {
      setVideoTypeNew("replay");
    }
  }, []);

  return (
    <div
      key={event.contentfulId}
      className={clsx("eventThumbnail", {
        liveEvent: isLiveEvent,
        upcomingEvent: isUpcomingEvent,
      })}
    >
      <button
        className="eventThumbnail--button"
        onClick={handleThumbnailClick}
        type="button"
      >
        <img className="venueImage" src={event?.venueImage?.file.url} alt="" />

        {isLiveEvent && (
          <div className="liveState">
            <span className="liveText">LIVE</span>
            <span className="liveDot"></span>
          </div>
        )}
        <div className="sliderThumbnailInfo">
          <div className="slideEventInfo">
            <CountryFlagWithRaceNumber event={event} />
            {isUpcomingEvent && (
              <div className="upcomingContainer">
                <div
                  className={`background ${
                    hasTicketsAvailable ? "background--red" : ""
                  }  `}
                >
                  <p
                    className={`upcomingText ${
                      hasTicketsAvailable ? "upcomingText--white" : ""
                    }`}
                  >
                    {hasTicketsAvailable
                      ? "GET TICKETS"
                      : event.insightsUpcomingEventText
                      ? event.insightsUpcomingEventText
                      : "UPCOMING"}
                  </p>
                </div>
              </div>
            )}
            <span className="title">{event.shortName}</span>
            {event.locationName !== event.shortNameSubtitle && (
              <span className="subTitle">{event.shortNameSubtitle}</span>
            )}
            <span className="subTitle">{event.locationName}</span>
          </div>
        </div>
        {isActive && <span className="selectedBorder"></span>}
        {isLiveEvent && <span className="liveBorder"></span>}
      </button>

      <RaceSelectModal
        setRaceSelectorModalOpen={setRaceSelectorModalOpen}
        isRaceSelectorModalOpen={isRaceSelectorModalOpen}
        event={event}
        changeRaceEvent={changeRaceEvent}
      />
    </div>
  );
};

export default ThumbnailCardComponent;
