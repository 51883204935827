export enum ChatterTypeEnum {
    Other = 'Other', // Message from another source
    Protest = 'Protest',// A boat has pressed the Protest (Yankee Flag) button
    Penalty = 'Penalty',// The umpires have assessed a penalty
    MdssMsg = 'MdssMsg',// Information message from MDSS Operator (Abby)
    PROMsg = 'PROMsg', // Information message from Principal Race Officer (PRO)
    PenMsg = 'PenMsg',// Information message from Umpire - reason for penalty
    TechMsg = 'TechMsg',// Information message from Tech Team
    MarkRounding = 'MarkRounding',// Automated mark rounding time message
    RaceStatus = 'RaceStatus',// Automated race status message
    None = 'None'
}
