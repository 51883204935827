import React from "react";
import {useForm} from "react-hook-form";
import "../Login/Forms.scss";
import {submitForm} from "../../utils/helpers/submitForm";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {LogoContainer} from "../../components/Logo/LogoContainer";

const ForgotPassword = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({mode: "onBlur"});
    const history = useHistory();
    const forgotPasswordSubmit = async (submitData: any) => {
        const loadingToast = toast.loading("Loading...");
        try {
            const userName = encodeURIComponent(submitData.emailAddress);
            const redirectUrl =
                window.location.origin +
                process.env.REACT_APP_NEW_PASSWORD_URL;
            submitData.firstName = submitData.emailAddress;
            submitData.redirectURL = redirectUrl;
            submitData.emailTemplate = "InsightsPasswordReset";
            submitData.recipient = submitData.emailAddress;
            submitData.tokenUrl = "user/generate-token";
            submitData.userName = submitData.emailAddress;
            submitData.operation = "FORGOT_PASSWORD";
            if (process.env.REACT_APP_FORGOT_PASSWORD_NOTIFICATION) {
                const forgotPasswordResponse = await submitForm(
                    submitData,
                    process.env.REACT_APP_FORGOT_PASSWORD_NOTIFICATION
                );
                if (forgotPasswordResponse.status === "success") {
                    toast.dismiss(loadingToast);
                    history.push("/forgot-password-confirmation");
                } else if (
                    forgotPasswordResponse.status === "error" &&
                    forgotPasswordResponse.errorDescription
                ) {
                    toast.dismiss(loadingToast);
                    toast.error(forgotPasswordResponse.errorDescription);
                } else {
                    toast.dismiss(loadingToast);
                    toast.error("Something has gone wrong. Please try again later.");
                }
            } else {
                toast.dismiss(loadingToast);
                toast.error("Something has gone wrong. Please try again later.");
            }
        } catch (error) {
            toast.dismiss(loadingToast);
            toast.error("Something has gone wrong. Please try again later.");
        }
    };
    return (
        <div className="insightsBackgroundContainer">
            <LogoContainer/>
            <div className="insightsForm__divSection">
                <label className="insightsForm__title">Forgot Password?</label>
                <form
                    className="insightsForm"
                    onSubmit={handleSubmit(forgotPasswordSubmit)}
                >
                    <label className="insightsForm__label" htmlFor="emailAddress">
                        E-mail Address
                    </label>

                    <input
                        className={`insightsForm__input ${
                            errors.emailAddress ? "insightsForm__input--error" : ""
                        } `}
                        type="text"
                        {...register("emailAddress", {
                            required: true,
                            pattern: /^\S+@\S+\.\S+$/g,
                        })}
                    />
                    {errors.emailAddress && errors.emailAddress.type === "required" && (
                        <p className="insightsForm__error">This field is required</p>
                    )}
                    {errors.emailAddress && errors.emailAddress.type === "pattern" && (
                        <p className="insightsForm__error">Invalid email format</p>
                    )}
                    <button
                        className={`insightsForm__submit ${
                            Object.keys(errors).length > 0
                                ? "insightsForm__submit--disabled"
                                : ""
                        }`}
                        onClick={handleSubmit(forgotPasswordSubmit)}
                        type="submit"
                        disabled={Object.keys(errors).length > 0}
                    >
                        <p className="insightsForm__email">Continue</p>
                        <img
                            className="insightsForm__icon"
                            src={
                                Object.keys(errors).length > 0
                                    ? "/Sign_in_circle_disabled.svg"
                                    : "/Sign_in_circle_enabled.svg"
                            }
                            alt=""
                        />
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
