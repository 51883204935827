import React, { useContext, useMemo } from "react";
import "./PredictedToFinishWidget.scss";
import { FormatTimeInHours, Round } from "../../utils/helpers";
import { RaceDataContext } from "../../context/raceData.context";
import { BoatDataContext } from "../../context/boatData.context";
import { TimestampContext } from "../../context/timestamp.context";
import { CurrentRaceEventContext } from "../../context/currentRaceEvent.context";
import clsx from "clsx";
import PopStatCard from '../../components/popStatCards';
import UseCountryFlag from '../../hooks/useCountryFlag';
import { countryCodeNameMap } from '../../utils/helpers/mapCountryCodes';

const PredictedToFinishWidget = () => {
    const raceData = useContext(RaceDataContext);
    const boatData = useContext(BoatDataContext);
    const [timeData] = useContext(TimestampContext);
    const { currentEvent } = useContext(CurrentRaceEventContext);

    const leadBoatRemaining = useMemo(() => {
        if (!raceData?.leadBoatId) {
            return undefined;
        }

        const boat = boatData.find((boat) => boat.boatId === raceData.leadBoatId);
        if (!boat) return undefined;

        // Find the team data from currentEvent.racingTeams
        const teamData = currentEvent?.racingTeams?.find(
            team => team.code === boat.countryCode
        );

        const teamStyle = {
            '--team-color': teamData?.primaryTeamHexColour || '$sailgp-deap-ocean-blue'
        } as React.CSSProperties;

        return {
            ...boat,
            teamStyle
        };
    }, [boatData, raceData?.leadBoatId, currentEvent?.racingTeams]);

    // 4 hours in milliseconds
    const timeUntilFinishThresholdMs = 4 * 3600 * 1000;

    const renderStateValue = () => {
        if (timeData && leadBoatRemaining && leadBoatRemaining.estimatedTimeFinishMs > 0) {
            const timeUntilFinishMs = leadBoatRemaining.estimatedTimeFinishMs - timeData;
            if (timeUntilFinishMs < timeUntilFinishThresholdMs) {
                return FormatTimeInHours(Round((timeUntilFinishMs) / 1000, 0), false);
            }
        }
        return '--';
    };

    return (
        <div
            className={clsx('boatIcons, predictedToFinishWidgetContainer')}
            style={leadBoatRemaining?.teamStyle}
        >
            <div className="leaderImages">
                <span className="countryImage">
                    {UseCountryFlag(leadBoatRemaining?.countryCode, {
                        width: '48px',
                        height: '28px'
                    })}
                </span>
                {leadBoatRemaining?.countryCode ? (
                    <span className="countryLabel">
                        {countryCodeNameMap[leadBoatRemaining.countryCode.toLowerCase()]?.toUpperCase()}
                    </span>
                ) : (
                    <span className="countryLabel">--</span>
                )}
            </div>
            <span className="popStatWrapper">
                <PopStatCard
                    data={{
                        label: 'PREDICTED TO FINISH IN',
                        value: renderStateValue(),
                        widthPercentage: '56%'
                    }}
                />
            </span>
        </div>
    );
};

export default PredictedToFinishWidget;