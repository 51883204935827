import React, {useContext} from "react";
import {Popover} from "@mui/material";
import {AutoPlayContext} from "../../context/autoPlay.context";
import Countdown from "react-countdown";
import './AutoPlayPopover.scss'
import exitLogo from "../../assets/autoplay-exit.png"
import {CurrentRaceEventContext} from "../../context/currentRaceEvent.context";
import {Button} from "@mui/material";
import {useHistory} from "react-router-dom";
import {ReactComponent as SkipIcon} from "../../assets/skip.svg";


const AutoPlayPopover = () => {
    const {
        isOpen,
        setIsOpen,
        setAutoPlayNext,
        currentTime,
        setIsAtEndOfVideo,
        nextRaceSubtitle,
        nextRaceShortName
    } = useContext(AutoPlayContext)
    
    const {resetEvent} = useContext(CurrentRaceEventContext)

    const history = useHistory()

    const handlePlayNext = () => {
        setIsOpen(false)

        //timeout used to prevent a flash of the next-but-one event title on the overlay
        setTimeout(() => {
            setAutoPlayNext(true)
        }, 100)
    }

    const handleExit = () => {
        setIsOpen(false)
        setAutoPlayNext(false)
        setIsAtEndOfVideo(false)
        resetEvent()
        history.push('/schedule')
    }

    return (
        <div id={'popover'}>
            <Popover open={isOpen} anchorEl={document.getElementById('popover')} className={'popoverContainer'}
                     marginThreshold={0}>
                <div className='exitContainer'>
                    <button className='exitButton' onClick={handleExit}>
                        <img className='exitIcon' src={exitLogo} alt='back arrow'/>
                        <p>EXIT</p>
                    </button>
                </div>
                <div className='middleSectionContainer'>
                    <div className='middleSectionContainer__logoContainer'>
                        <img src={'/rolex-logo.png'} alt='Rolex'/>
                    </div>
                    <div className='textContainer'>
                        <div className='titleContainer'>
                            <h1 className='titleContainer__next'>Next</h1>
                            <h1 className='titleContainer__name'>{nextRaceShortName} |</h1>
                            <h1 className='titleContainer__subtitle'>{nextRaceSubtitle}</h1>
                        </div>
                    </div>
                    <div className='countdownContainer'>
                        <Countdown date={currentTime + 10000000}
                                   renderer={({minutes}) => <p className='countdown'>{minutes}</p>}
                                   onComplete={handlePlayNext}/>
                    </div>

                </div>
                <div className='endSection'>
                    <Button className='skipButton' onClick={handlePlayNext}>
                        <SkipIcon className='skipIcon'/>
                        <p className='skipText'>SKIP</p>
                    </Button>
                </div>
            </Popover>
        </div>
    );
};

export default AutoPlayPopover;
