import React from "react";
import ReactApexChart from "react-apexcharts";
import './BoatGraphProgressWidget.scss';

export default function ChartViewer(props: any) {
    return (
        <div id="chart" className="chartLayout">
            {props.series &&
            <ReactApexChart options={props.options} series={props.series} type='scatter' height="100%"/>
            }</div>
    );
}
