import React, { PropsWithChildren, useMemo, useEffect } from "react";
import { useContentProxyData } from "../hooks/useContentProxyData";
import { ContentProxyTeam } from "../models/contentProxyModel";
import {
    BoatCountryCodesContext,
    BoatCountryCodesContextState,
    BoatCountryCodesMap,
} from "./boatCountryCodes.context";

export const BoatCountryCodesProvider = ({ children }: PropsWithChildren<{}>) => {
    const { data: teams, requestsPending, cancel } = useContentProxyData<ContentProxyTeam[]>(
        "teams",
        300000
    );

    const contextState = useMemo((): BoatCountryCodesContextState => {
        let map: BoatCountryCodesMap = {};
        if (teams) {
            for (const team of teams) {
                if (team.code) {
                    map[team.dataBoatId] = team.code;
                }
            }
        }
        return { boatCountryCodes: map, isLoading: requestsPending };
    }, [teams, requestsPending]);

    useEffect(() => {
        return () => {
            // Cancel the data request when the component unmounts
            cancel();
        };
    }, [cancel]);

    return (
        <BoatCountryCodesContext.Provider value={contextState}>
            {children}
        </BoatCountryCodesContext.Provider>
    );
};