import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import {BrowserRouter} from "react-router-dom";
import ReactGA from 'react-ga';
import {environmentVariables} from './utils/helpers/environmentVariables';

const measurementId = environmentVariables.googleAnalyticsMeasurementId();

export const initGA = () => {
    if (measurementId) {
        ReactGA.initialize(measurementId);
    }
};


ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
