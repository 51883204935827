import React from "react";
import {ContentProxyRaceEvent} from "../../models/contentProxyModel";
import './countryFlagWithRaceNumber.scss'

export interface CountryFlagProps {
    event: ContentProxyRaceEvent;
}

const CountryFlagWithRaceNumber = ({event}: CountryFlagProps) => {

    return (
        <div className="countryFlag">
            <img className="venueCountryImage" src={event?.appCountryFlag?.file.url} alt=""/>
            <span className="raceNumber">{
                event?.eventNumber < 10
                    ? <span className="number">{`0${event?.eventNumber}`}</span>
                    : <span className="number">{event?.eventNumber}</span>
            }
                  </span>
        </div>
    );
};

export default CountryFlagWithRaceNumber;
