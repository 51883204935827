import React, {useCallback, useEffect, useState} from 'react';
import './HeadBrandingWidget.scss';
import {useGetAppSettings} from "../../hooks/useGetAppSettings";
import {Logo} from "../../components/Logo/Logo";

const HeadBrandingWidget = () => {
    const oracleLogoUrl = useGetAppSettings()

    return oracleLogoUrl ? (
        <div className="headBrandingContainer">
            <Logo/>
        </div>
    ) : null;
};


export default HeadBrandingWidget;
