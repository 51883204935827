import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import makeStyles from "@mui/styles/makeStyles";
import {useLocalStorageState} from "../../hooks/useLocalStorageState";


const useStyles = makeStyles({

    cookieModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        backgroundColor: '#061a1b',
        color: 'white',
        padding: '30px 40px 15px 40px',
        fontSize: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '20%',
        justifyContent: 'space-around',
        boxSizing: 'border-box',
        boxShadow: '10px 0px 20px 0px #010606',
        '&:focus-visible': {
            outline: 'none',
        },

    },

    cookieDescription: {
        fontSize: '18px',
        marginTop: '8px',
        textAlign: 'center'
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        marginTop: '30px'
    },
    continue: {
        transform: "skewX(-10deg)",
        width: '40%',
        margin: "12px 12px ",
        backgroundColor: "#e23b25",
        color: 'white',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#010606',
        },
    },
    learnMore: {
        transform: "skewX(-10deg)",
        width: '40%',
        margin: "12px 12px ",
        color: 'white',
        borderRadius: 0,
    }

});

export interface CookieComponentProps {
    acceptPrivacyPolicy: boolean;
    handlePrivacyModalToggle: () => void;
}


export default function CookieComponent({acceptPrivacyPolicy, handlePrivacyModalToggle}: CookieComponentProps) {
    const [, setPrivacyAcceptance] = useLocalStorageState<boolean>('acceptedPrivacyPolicy', false);
    const classes = useStyles();

    const handleLearnMoreClick = () => {
        window.open(
            'https://sailgp.com/general/cookie/',
            '_blank'
        );
    }

    const handleAcceptCookies = () => {
        setPrivacyAcceptance(true)
        handlePrivacyModalToggle()
    }

    return (
        <div>
            <Modal
                open={acceptPrivacyPolicy}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.cookieModal}>
                    <div>
                        THIS WEBSITE USES COOKIES
                    </div>
                    <div className={classes.cookieDescription}>
                        We use them to give you the best experience. If you continue using our website, we'll assume
                        that you are happy to receive all cookies on this website.
                    </div>
                    <div className={classes.buttonWrapper}>
                        <Button
                            className={classes.continue}
                            variant="contained"
                            onClick={handleAcceptCookies}
                        >
                            Continue
                        </Button>
                        <Button
                            className={classes.learnMore}
                            variant="outlined"
                            onClick={handleLearnMoreClick}
                        >
                            Learn More
                        </Button>

                    </div>
                </Box>
            </Modal>
        </div>
    );
}