import React, {useRef} from 'react';
import {motion} from "framer-motion";
import NavigationIcon from '@mui/icons-material/Navigation';

const RotatingArrow = ({heading, iconSize}: { heading: number, iconSize:number }) => {

    const reference = useRef(heading);
    if (heading !== reference.current) reference.current = heading;

    const spinTransition = {
        loop: Infinity,
        // ease: "anticipate",
        duration: 0,
        transform: `translate(0, 0)`
    };

    const animate = {rotate: reference.current};
    return (<>
            <motion.div
                animate={animate}
                transition={spinTransition}
            >
                <NavigationIcon sx={{fontSize:iconSize}}/>
            </motion.div>
        </>
    );
};


export default RotatingArrow;
