import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import { User } from "../models/enums/userModel";
import { checkInternalUser } from "../utils/helpers";
import { useContentProxyData } from "../hooks/useContentProxyData";
import { ContentProxyAppSettings } from "../models/contentProxyModel";
import { environmentVariables } from "../utils/helpers/environmentVariables";
import { ssoLogin, submitLogOut } from "../utils/helpers/submitForm";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export const LoginContext = createContext<{
    isLoggedIn: boolean;
    updateUser: (user: User) => void;
    setLoginStatus: (value: boolean, user: User | undefined) => void;
    user: User | undefined;
    additionalDataFailed: boolean;
    setAdditionalDataFailed: Dispatch<SetStateAction<boolean>>;
    isInternalUser: boolean;
    isVip: boolean;
    logOut(): void;
}>({
    isLoggedIn: false,
    updateUser: () => {},
    setLoginStatus: () => {},
    user: undefined,
    additionalDataFailed: false,
    setAdditionalDataFailed: () => {},
    isInternalUser: false,
    isVip: false,
    logOut: () => {},
});

export function LoginProvider(props: { children: React.ReactNode }) {
    const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
    const [user, setUser] = useState<User | undefined>(undefined);
    const [additionalDataFailed, setAdditionalDataFailed] = useState<boolean>(false);
    const [isInternalUser, setIsInternalUser] = useState<boolean>(false);
    const [isVip, setIsVip] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const { data } = useContentProxyData<ContentProxyAppSettings>('appsettings');
    const history = useHistory();

    const updateUser = (userObj: User | undefined) => {
        if (userObj) {
            setUser(userObj);
        }
    };

    const clearLocalStorage = () => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('userData');
        localStorage.setItem('lastLogoutTime', Date.now().toString());
    };

    const setLoginStatus = (loggedIn: boolean, userObj: User | undefined) => {
        // Don't update login status if we're in the process of logging out
        if (isLoggingOut) return;

        setLoggedIn(loggedIn);
        setUser(userObj);

        if (loggedIn && userObj) {
            const lastLogoutTime = localStorage.getItem('lastLogoutTime');
            const currentTime = Date.now();

            if (lastLogoutTime && (currentTime - parseInt(lastLogoutTime)) < 5000) {
                // If less than 5 seconds since logout, prevent login
                return;
            }

            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('userData', JSON.stringify(userObj));
            const isInternal = checkInternalUser(userObj.emailAddress, data?.app_emailDomainWhitelist);
            setIsInternalUser(!!isInternal);
        } else {
            clearLocalStorage();
        }
    };

    const logOut = async () => {
        if (!process.env.REACT_APP_LOGOUT_URL) {
            toast.error("There has been a problem logging out.");
            return;
        }

        setIsLoggingOut(true);
        clearLocalStorage();

        try {
            const logoutResponse = await submitLogOut(process.env.REACT_APP_LOGOUT_URL);

            if (logoutResponse && typeof logoutResponse === 'object' && 'status' in logoutResponse && (logoutResponse as { status: number }).status === 200) {
                setLoginStatus(false, undefined);

                // Add a small delay before redirecting to allow logout to complete
                setTimeout(() => {
                    setIsLoggingOut(false);
                    history.push("/");
                }, 100);
            } else {
                throw new Error('Logout failed');
            }
        } catch (error) {
            toast.error("There has been a problem logging out.");
            setIsLoggingOut(false);
        }
    };

    useEffect(() => {
        const initializeAuth = async () => {
            const userDetailsString = localStorage.getItem('userData');
            const lastLogoutTime = localStorage.getItem('lastLogoutTime');
            const currentTime = Date.now();

            // If we recently logged out, don't attempt SSO login
            if (lastLogoutTime && (currentTime - parseInt(lastLogoutTime)) < 5000) {
                return;
            }

            if (userDetailsString) {
                const userDetails = JSON.parse(userDetailsString);
                setLoginStatus(true, userDetails);
                const isInternal = checkInternalUser(userDetails.emailAddress, data?.app_emailDomainWhitelist);
                setIsInternalUser(!!isInternal);
            } else {
                try {
                    const ssoData = await ssoLogin();
                    if (ssoData['status'] === "success") {
                        setLoginStatus(true, ssoData.userDetails);
                        const isInternal = checkInternalUser(ssoData.userDetails.emailAddress, data?.app_emailDomainWhitelist);
                        setIsInternalUser(!!isInternal);
                    } else {
                        setLoginStatus(false, undefined);
                    }
                } catch (error) {
                    setLoginStatus(false, undefined);
                }
            }
        };

        if (!isLoggingOut) {
            initializeAuth();
        }

        setIsVip(environmentVariables.specialVersion() === 'vip');
    }, [data]);

    return (
        <LoginContext.Provider value={{
            isLoggedIn,
            setLoginStatus,
            user,
            isInternalUser,
            isVip,
            additionalDataFailed,
            setAdditionalDataFailed,
            updateUser,
            logOut
        }}>
            {props.children}
        </LoginContext.Provider>
    );
}