// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!../workers/raceDataWorker';

import React, {PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {useStableCallback} from "../hooks/useStableCallback";
import {BoatDataModel} from "../models/boatDataModel";
import {RaceDataModel} from "../models/raceDataModel";
import {
    RaceDataWorkerInitInputMessage,
    RaceDataWorkerInputMessageType,
    RaceDataWorkerLogLevel,
    RaceDataWorkerMessage,
    RaceDataWorkerMessageType,
    RaceDataWorkerTimeInputMessage
} from "../workers/raceDataWorkerMessage";
import {RaceDataContext, RaceDataContextState} from './raceData.context';
import {BoatDataContext, BoatDataContextState} from './boatData.context';
import {TimestampContext} from './timestamp.context';
import {ChatterAlertsContext} from './chatterAlerts.context';
import {RaceStatusEnum} from '../models/enums/raceStatusEnum';
import {BoatStatusEnum} from '../models/enums/boatStatusEnum';
import {AwsChatterEntryModel} from '../models/awsDataModel';
import {SlackNotificationContext} from './slackNotifications.context';
import {CountryCodeEnum} from '../models/enums/countryCodeEnum';
import {BoatCountryCodesContext} from './boatCountryCodes.context';
import {LoginContext} from "./login.context";

function createRaceContextState(raceData: RaceDataModel, boatData: BoatDataModel[]): RaceDataContextState {
    let leadBoat: BoatDataModel | undefined;
    if (raceData.status === RaceStatusEnum.Started) {
        const unfinishedBoats = boatData.filter(b => b.boatStatus !== BoatStatusEnum.Finished);

        if (unfinishedBoats.length > 0) {
            leadBoat = unfinishedBoats.reduce((result, boat) => {
                if (!result.rank) {
                    return boat;
                }

                if (boat.rank && boat.rank < result.rank) {
                    return boat;
                }

                return result;
            });
        }
    }

    return {
        ...raceData,
        leadBoatId: leadBoat?.boatId,
        startTimeMs: new Date(raceData.raceStartTime).getTime()
    };
}

function createBoatContextState(
    raceData: RaceDataContextState | undefined,
    boatData: BoatDataModel[],
    boatCountryCodes: { [boatId: number]: CountryCodeEnum | undefined },
    timestamp: number | undefined
): BoatDataContextState[] {

    return boatData.map((boat): BoatDataContextState => {
        // Don't add extra fields if the dependent data isn't ready
        if (!timestamp || !raceData) {
            return boat;
        }

        const countryCode = (boat.countryCode || (boatCountryCodes[boat.boatId] ?? "")) as CountryCodeEnum;

        let estimatedProgress: number | undefined;
        if (
            (boat.boatStatus === BoatStatusEnum.Racing || boat.boatStatus === BoatStatusEnum.OCS) &&
            boat.estimatedTimeFinishMs
        ) {
            const timeSinceRaceStart = timestamp - raceData.startTimeMs;
            const boatRaceLength = boat.estimatedTimeFinishMs - raceData.startTimeMs;

            estimatedProgress = Math.min(1, Math.max(0, (timeSinceRaceStart / boatRaceLength)));

        } else if (boat.boatStatus === BoatStatusEnum.Prestart) {
            estimatedProgress = 0;
        }

        let estimatedTimeBehindNextBoatMs: number | undefined;
        if (boat.rank) {
            const thisBoatRank = boat.rank;
            const allBoatsInFront = boatData.filter(b => b.rank && (b.rank < thisBoatRank));

            if (allBoatsInFront.length > 0) {
                const boatInFrontOfThisBoat = allBoatsInFront.reduce(
                    (result, current) =>
                        // Boats will not be included in this array unless they have a rank, so we are safe to use non-null assertion
                        current.rank! > result.rank! ? current : result
                );

                estimatedTimeBehindNextBoatMs = boat.estimatedTimeFinishMs - boatInFrontOfThisBoat.estimatedTimeFinishMs;
            }
        }

        return {
            ...boat,
            estimatedProgress,
            estimatedTimeBehindNextBoatMs,
            countryCode
        };
    });
}

export const WebWorkerProvider = ({children}: PropsWithChildren<{}>) => {
    const [raceData, setRaceData] = useState<RaceDataContextState | undefined>();
    const [boatData, setBoatData] = useState<BoatDataContextState[]>([]);
    const [timeData, setTimeData] = useState<number>();
    const {isInternalUser, isVip} = useContext(LoginContext)


    const [chatterAlerts, setChatterAlerts] = useState<AwsChatterEntryModel[]>([]);
    const [slackNotifications, setSlackNotifications] = useState<any[]>([]);

    const {boatCountryCodes} = useContext(BoatCountryCodesContext);

    const clearAllRaceStateDueToUserRaceSwitch = () => {
        setChatterAlerts([])
        setBoatData([])
        setRaceData(undefined)
    }

    const worker = useMemo(() => new Worker(), []);

    const onWorkerMessage = useStableCallback((event: MessageEvent<RaceDataWorkerMessage>) => {
        switch (event.data.type) {
            case RaceDataWorkerMessageType.AwsData:
                const newRaceData = createRaceContextState(event.data.data.raceStatus, boatData);
                newRaceData.clearRaceDataDueToUserRaceSwitch = clearAllRaceStateDueToUserRaceSwitch
                setRaceData(newRaceData);
                setBoatData(createBoatContextState(newRaceData, event.data.data.boatStatuses, boatCountryCodes, timeData));
                break;
            case RaceDataWorkerMessageType.ChatterAlerts:
                setChatterAlerts(event.data.data);
                break;
            case RaceDataWorkerMessageType.Log:
                const logData = event.data.data;
                switch (logData.level) {
                    case RaceDataWorkerLogLevel.Error:
                        console.error('[Race data worker]', logData.payload);
                        break;
                    case RaceDataWorkerLogLevel.Warning:
                        console.warn('[Race data worker]', logData.payload);
                        break;
                    default:
                        console.log('[Race data worker]', logData.payload);
                        break;
                }
                break;
            case RaceDataWorkerMessageType.SlackNotification:
                if (isInternalUser || isVip) {
                    setSlackNotifications(event.data.data);
                }
                break;
        }
    });

    const onSetTime = useStableCallback((time: number) => {
        const timeMessage: RaceDataWorkerTimeInputMessage = {
            type: RaceDataWorkerInputMessageType.Time,
            data: time
        };
        worker.postMessage(timeMessage);

        setTimeData(time);
    });

    useEffect(() => {
        worker.onmessage = onWorkerMessage;

        const initMessage: RaceDataWorkerInitInputMessage = {
            type: RaceDataWorkerInputMessageType.Init,
            data: true
        };
        worker.postMessage(initMessage);
    }, []);

    return (
        <RaceDataContext.Provider value={raceData}>
            <BoatDataContext.Provider value={boatData}>
                <TimestampContext.Provider value={[timeData, onSetTime]}>
                    <ChatterAlertsContext.Provider value={chatterAlerts}>
                        <SlackNotificationContext.Provider value={slackNotifications}>
                            {children}
                        </SlackNotificationContext.Provider>
                    </ChatterAlertsContext.Provider>
                </TimestampContext.Provider>
            </BoatDataContext.Provider>
        </RaceDataContext.Provider>
    );
};
