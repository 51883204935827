import {createContext} from "react";
import {ReplayStreams} from '../models/contentProxyModel';
import {CommentaryType} from "./StreamDetailsProvider";

export interface StreamDetailsContextState {
    host?: string;
    streamIds?: string[];
    replayStreams? : ReplayStreams;
    insightsWebLatencyMilliseconds?: number
    dataOnlyDelayMillis?: number
    handleCommentarySelect?: (e: CommentaryType) => void;
    commentaryType?: CommentaryType[];
}

export const StreamDetailsContext = createContext<StreamDetailsContextState | undefined>(undefined);
