import React, {useContext, useEffect, useState} from 'react';
import {CurrentRaceEventContext} from "../../context/currentRaceEvent.context";
import {Button, Popover} from "@mui/material";
import './RaceEndedOverlay.scss'
import {useHistory} from "react-router-dom";


const RaceEndedOverlay = () => {
    const {resetEvent, isRaceFinished} = useContext(CurrentRaceEventContext)
    const [overlayIsOpen, setOverlayIsOpen] = useState(false)


    useEffect(() => {
        setOverlayIsOpen(isRaceFinished)
    }, [isRaceFinished])

    const history = useHistory();

    const handleExit = () => {
        setOverlayIsOpen(false)
        resetEvent()
        history.push('/schedule')
    }

    return (
        <div id='raceEndOverlay'>
            <Popover open={overlayIsOpen} anchorEl={document.getElementById('raceEndOverlay')}
                     className='overlayContainer' marginThreshold={0}>
                <div className='raceEnded'>
                    <h1>Race Ended</h1>
                    <Button className='returnButton' onClick={handleExit}>
                        <p>Return to Race Schedule</p>
                    </Button>
                </div>
            </Popover>
        </div>


    );
};

export default RaceEndedOverlay;