import React from 'react';
import { useContext } from 'react';
import {TeamDataContext} from "../context/TeamData.context";


const UseCountryFlag = (teamCountryCode: string | undefined, layout: { width: string, height: string }) => {
    const { getTeamByCountryCode } = useContext(TeamDataContext);

    if (!teamCountryCode) {
        return '';
    }

    const team = getTeamByCountryCode(teamCountryCode);

    if (!team?.appCountryFlag?.file?.url) {
        return '';
    }

    return (
        <img
            src={team.appCountryFlag.file.url}
            alt={teamCountryCode}
            style={{width: layout.width, height: layout.height}}
        />
    );
};

export default UseCountryFlag;