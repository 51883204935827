import {useCallback, useEffect, useState} from "react";
import {fetchFromContentProxy} from "../utils/helpers/fetchFromContentProxy";
import {ContentProxyAppSponsors} from "../models/contentProxyModel";

export const useGetAppSettings = () => {
    const [oracleLogoUrl, setOracleLogoUrl] = useState<string | undefined>();

    const getAppSettings = useCallback(async () => {
        try {
            const appSettings = await fetchFromContentProxy<any>('appsettings');
            if (appSettings) {
                const oracleLogo = appSettings.app_sponsors.find((sponsor: ContentProxyAppSponsors) => sponsor.name === "Oracle APP");
                if (oracleLogo) setOracleLogoUrl(oracleLogo.logo.file.url);
            }

        } catch (error) {
            console.warn(error);
        }
    }, []);

    useEffect(() => {
        getAppSettings();
    }, [getAppSettings]);

    return oracleLogoUrl
}
