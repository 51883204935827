import {DateTime} from "luxon";

export const Round = (value: number, decimals: number) => {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
}


const padTime = (time: number) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
};

export const FormatTimeInMinutes = (timeSeconds: number, unit?: boolean) => {
    const minutes = Math.floor(timeSeconds / 60);
    const seconds = timeSeconds % 60;
    const showUnit = unit ? 'min' : '';
    if (timeSeconds <= 0) {
        return `0:00 ${showUnit}`;
    }

    return `${minutes}:${padTime(seconds)} ${showUnit}`;
};


export const FormatTimeInHours = (timeSeconds: number, unit?: boolean) => {
    const hours = Math.floor(timeSeconds / 3600);
    const minutes = Math.floor(timeSeconds / 60) - (hours * 60);
    const seconds = timeSeconds % 60;
    const showUnit = unit ? 'min' : '';

    if (timeSeconds <= 0) {
        return `0:00 ${showUnit}`;
    }

    return `${hours}:${padTime(minutes)}:${padTime(seconds)} ${showUnit}`;
};


export const FormatTimeInSeconds = (time: number) => {
    const seconds = time % 60;
    return seconds > 0 ? seconds.toFixed(2) : '0.00';
};

export const CalculateMpsToKmph = (value: number) => {
    return value * 3.6
};

export const formatRaceTimeFriendlyText = ((start: string, end: string) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const startDateTime = DateTime.fromISO(start, {setZone: true}).toFormat('dd').toLowerCase()
    const endDateTime = DateTime.fromISO(end, {setZone: true}).toFormat('dd').toLowerCase()
    const year = DateTime.fromISO(end, {setZone: true}).toFormat('yyyy').toLowerCase()

    const startMonth = Number(DateTime.fromISO(start, {setZone: true}).toFormat('MM').toLowerCase()) - 1
    const endMonth = Number(DateTime.fromISO(end, {setZone: true}).toFormat('MM').toLowerCase()) - 1

    return monthNames[startMonth] === monthNames[endMonth] ? `${startDateTime} - ${endDateTime} ${monthNames[endMonth]} ${year}` : `${startDateTime} ${monthNames[startMonth]} - ${endDateTime} ${monthNames[endMonth]} ${year}`
});

export const checkInternalUser = (emailAddress: string, domainWhitelist?: string[] | undefined) => {
    const isInternal = domainWhitelist && domainWhitelist.find(domain => emailAddress.endsWith(domain))
    return !!isInternal
}
