import React from "react";
import './MobileDownloadPrompt.scss';

import mobileImage from "../../assets/SGP_OPS7031__1_.png";
import {useWindowSize} from "../../hooks/useWindowSize";
import ReactGA from "react-ga";
import {ReactGaEnum} from "../../models/enums/reactGAEnum";

export const MobileDownloadPrompt = () => {
    const {width, height} = useWindowSize()

    function logMobileDownloadClick(device: string) {
        ReactGA.event({
            category: ReactGaEnum.MobileLinkPress,
            action: `Mobile link pressed to app store for ${device} `
        });
    }

    return (
        <div className="mobilePromptContainer" style={{height: height, width: width}}>
            <div className="mobilePromptWrapper">
                <h2 className="heading">SailGP Insights is available on Desktop devices only. Return on a Desktop or Download the SailGP APP</h2>
            <img src={mobileImage} alt="Download the SailGP App"
                 style={{width: '60%'}}/>

            <h4 className="introduction">Your ultimate race companion. Download the SailGP APP
                today.</h4>
            <a href="https://play.google.com/store/apps/details?id=com.sailgp.secondscreen"
               className="contentLink" target="_blank" rel="noopener noreferrer"><span onClick={() => logMobileDownloadClick('google')}>Download from the Google Play Store</span></a>

            <a href="https://itunes.apple.com/gb/app/sailgp/id1445011811" className="contentLink"
               target="_blank" rel="noopener noreferrer"><span onClick={() => logMobileDownloadClick('apple')}>Download from the Apple App Store</span></a>
            </div>
        </div>
    );
};
