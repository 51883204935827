import React from "react";
import './ScheduleFooter.scss';
import {Link} from "react-router-dom";

export const ScheduleFooter = () => {
    return (
        <div className="footerContainer">
                Copyright &copy; 2024 F50 League LLC. All rights reserved.
            <ul className="links">
                <li><Link to={{pathname: "https://sailgp.com/about/contact-us/"}} target="_blank">Contact Us</Link>
                </li>
                <li><Link to={{pathname: "https://sailgp.com/general/terms/"}} target="_blank">Terms Of Use</Link>
                </li>
                <li><Link to={{pathname: "https://sailgp.com/general/accessibility"}}
                          target="_blank">Accessibility</Link></li>
                <li><Link to={{pathname: "https://sailgp.com/general/retention/"}} target="_blank">Retention
                    Policy</Link></li>
                <li><Link to={{pathname: "https://sailgp.com/general/privacy/"}} target="_blank">Privacy
                    Policy</Link></li>
                <li><Link to={{pathname: "https://sailgp.com/general/cookie/"}} target="_blank">Cookie Policy</Link>
                </li>

            </ul>
        </div>
    );
};
