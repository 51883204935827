import React, {Dispatch, SetStateAction} from 'react';
import Slider, {Settings} from "react-slick";
import {ContentProxyRaceDay, ContentProxyRaceEvent} from "../../../../models/contentProxyModel";
import ThumbnailCardComponent from "./ThumbnailCardComponent";


export interface SingleSliderProps {
    separatedSeasons: object | undefined;
    currentSlide: ContentProxyRaceEvent | undefined;
    handleSelect: (event: ContentProxyRaceEvent) => void;
    season: string;
    changeRaceEvent: (eventId: string, raceDay: ContentProxyRaceDay, isLiveRace: boolean) => void;
    setShowWatchButton: Dispatch<SetStateAction<boolean>>
}

const SingleSlider = ({
                          separatedSeasons,
                          currentSlide,
                          handleSelect,
                          season,
                          changeRaceEvent,
                          setShowWatchButton
                      }: SingleSliderProps) => {
    const settings: Settings = {
        dots: false,
        speed: 500,
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: separatedSeasons && separatedSeasons[season].slidesToShow,
        slidesToScroll: 1,
        adaptiveHeight: false,
        lazyLoad: 'progressive',
        initialSlide: separatedSeasons && separatedSeasons[season].initialSlide,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: separatedSeasons && separatedSeasons[season].slidesToShow,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return <div className='sliderWrapper'>
        <h2 className='seasonTitle'>{`Season ${season} `}</h2>
        <Slider {...settings}
                className={`${separatedSeasons && separatedSeasons[season].races.length <= 5 ? 'sliderCentred' : ''}`}>
            {separatedSeasons && separatedSeasons[season].races.map((event: ContentProxyRaceEvent, i: number) => {
                    return (
                        <ThumbnailCardComponent
                            key={i}
                            event={event}
                            currentSlide={currentSlide}
                            handleSelect={handleSelect}
                            changeRaceEvent={changeRaceEvent}
                            setShowWatchButton={setShowWatchButton}
                        />)
                }
            )}
        </Slider>
    </div>
};

export default SingleSlider;