import {useContext, useEffect, useState} from "react";
import "../Login/Forms.scss";
import {DashboardHeader} from "../../components/DashboardHeader";
import RaceScheduleWidget from "../../widgets/RaceScheduleWidget";
import {FullLoadingIndicator} from "../../components/FullLoadingIndicator/FullLoadingIndicator";
import {
  useController,
  useForm,
  useFormContext,
  Controller,
} from "react-hook-form";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";
import {format} from "date-fns";
import "../RaceSchedule/RaceSchedule.scss";
import {submitForm, submitFormNoBody} from "../../utils/helpers/submitForm";
import {useHistory} from "react-router-dom";
import {fetchFromContentProxy} from "../../utils/helpers/fetchFromContentProxy";
import {useContentProxyData} from "../../hooks/useContentProxyData";
import {ContentProxyTeam} from "../../models/contentProxyModel";
import {User} from "../../models/enums/userModel";
import {LoginContext} from "../../context/login.context";

interface Country {
  contentfulId: string;
  contentfulType: string;
  created: string;
  iso: string;
  name: string;
  updated: string;
}

const SettingsPage = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
  } = useForm({mode: "onBlur"});

  const history = useHistory();
  const [countries, setCountries] = useState<Country[] | null>(null);
  const {data: teams} = useContentProxyData<ContentProxyTeam[]>(
      "teams",
      0,
      "incGeneral=true&onlyActive=true"
  );
  const {updateUser} = useContext(LoginContext);

  const [userData, setUserData] = useState<User>();
  const [countryName, setCountryName] = useState<Country>();
  const [favouriteTeam, setFavouriteTeam] = useState<ContentProxyTeam>();
  const [gender, setGender] = useState<string>("");
  const [checkbox, setCheckbox] = useState(true);
  const [isLoadingSettings, setIsLoadingSettings] = useState<boolean>(false);

  const handleChange = (e: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => setCheckbox(e.target.checked);

  const mapGenderOptions = {
    FEMALE: "Female",
    MALE: "Male",
    NONBINARY: "Non-binary",
    OTHER: "Other",
    PREFERNOT: "Prefer not to identify",
  };

  useEffect(() => {
    (async () => {
      setIsLoadingSettings(true);
      let userResponse = await submitFormNoBody("user/get-preferences");

      const countryList: Country[] = await fetchFromContentProxy("country");
      setCountries(countryList);

      if (
          userResponse["status"] !== undefined &&
          userResponse["status"] === "success"
      ) {
        const countryName = countryList.find((country) => {
          return country.iso === userResponse["response"].country;
        });
        const userGender = userResponse.response.gender;
        setCountryName(countryName);
        setGender(userGender);
        setUserData(userResponse["response"]);
        setIsLoadingSettings(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (teams && userData?.favouriteTeam) {
      const userTeam = teams.find((item) => {
        return item.code === userData.favouriteTeam;
      });

      if (userTeam) setFavouriteTeam(userTeam);
    }
  }, [teams, userData]);

  const onUpdateField = (e: any) => {
    if (userData) {
      const data = {...userData, [e.target.name]: e.target.value} as User;
      setUserData(data);
    }
  };

  const onSubmit = async (data: any) => {
    const dob = data.dateOfBirth
        ? format(data.dateOfBirth, "yyyy-MM-dd")
        : userData?.dateOfBirth;
    const favTeam = data.favouriteTeam
        ? data.favouriteTeam
        : userData?.favouriteTeam;
    let settingsData = {
      ...data,
      dateOfBirth: dob,
      favouriteTeam: favTeam,
      applicationName: process.env.REACT_APP_APPLICATION_NAME,
      successUrl: `${window.location.origin}/schedule`,
    };

    const loginToast = toast.loading("Loading...");
    const settingsResponse = await submitForm(
        settingsData,
        "user/update-preferences"
    );

    if (
        settingsResponse &&
        settingsResponse["status"] !== undefined &&
        settingsResponse["status"] === "success"
    ) {
      toast.dismiss(loginToast);
      localStorage.setItem('userData', JSON.stringify({...settingsResponse, ...settingsData}))
      updateUser(settingsData)
      history.goBack()
    } else if (
        settingsResponse &&
        settingsResponse["status"] !== undefined &&
        settingsResponse["status"] === "error" &&
        settingsResponse["errorDescription"] !== undefined
    ) {
      toast.dismiss(loginToast);
      toast.error(settingsResponse["errorDescription"], {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (
        settingsResponse &&
        settingsResponse["status"] !== undefined &&
        settingsResponse["status"] === "failed" &&
        settingsResponse["error_description"] !== undefined
    ) {
      toast.dismiss(loginToast);
      toast.error(settingsResponse["error_description"], {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.dismiss(loginToast);
      toast.error("Server Error!", {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    toast.dismiss(loginToast);
  };

  return (
      <div className="raceScheduleContainer">
        {isLoadingSettings && <FullLoadingIndicator/>}
        <div className="headerContainer">
          <DashboardHeader
              leftContent={<RaceScheduleWidget isInRaceSchedule={true}/>}
          />
        </div>

        {userData && Object.keys(userData).length > 0 && (
            <div className="settingsFormContainer">
              <h1 className="insightsForm__title">ACCOUNT SETTINGS</h1>
              <p className="insightsForm__text insightsForm__text-bold">DETAILS</p>
              <form className="insightsForm" onSubmit={handleSubmit(onSubmit)}>
                <label className="insightsForm__label" htmlFor="firstName">
                  First Name
                </label>
                <input
                    className={`insightsForm__input ${
                        errors.firstName ? "insightsForm__input--error" : ""
                    } `}
                    type="text"
                    {...register("firstName", {
                      required: true,
                      pattern: /^[a-z ,.'-]+$/i,
                    })}
                    defaultValue={userData["firstName"]}
                    onChange={onUpdateField}
                />
                {errors.firstName && errors.firstName.type === "required" && (
                    <p className="insightsForm__error">This field is required.</p>
                )}
                {errors.firstName && errors.firstName.type === "pattern" && (
                    <p className="insightsForm__error">
                      Please enter a valid first name.
                    </p>
                )}
                <label className="insightsForm__label" htmlFor="lastName">
                  Last Name
                </label>
                <input
                    className={`insightsForm__input ${
                        errors.lastName ? "insightsForm__input--error" : ""
                    } `}
                    type="text"
                    {...register("lastName", {
                      required: true,
                      pattern: /^[a-z ,.'-]+$/i,
                    })}
                    defaultValue={userData["lastName"]}
                    onChange={onUpdateField}
                />
                {errors.lastName && errors.lastName.type === "required" && (
                    <p className="insightsForm__error">This field is required.</p>
                )}
                {errors.lastName && errors.lastName.type === "pattern" && (
                    <p className="insightsForm__error">
                      Please enter a valid last name.
                    </p>
                )}
                <label className="insightsForm__label" htmlFor="gender">
                  Gender
                </label>
                <select
                    className={`insightsForm__dropdown ${
                        errors.gender ? "insightsForm__dropdown--error" : ""
                    } `}
                    {...register("gender", {required: true})}
                    onChange={onUpdateField}
                >
                  {gender ? (
                      <option value={gender} className="select-option">
                        {mapGenderOptions[gender]}
                      </option>
                  ) : (
                      <option value="" className="select-option">
                        -- Please select --
                      </option>
                  )}
                  {Object.keys(mapGenderOptions).map((genderOption) => {
                    if (genderOption !== gender)
                      return (
                          <option key={genderOption} value={genderOption}>
                            {mapGenderOptions[genderOption]}
                          </option>
                      );
                  })}
                </select>
                {errors.gender && errors.gender.type === "required" && (
                    <p className="insightsForm__error">This field is required.</p>
                )}
                <label className="insightsForm__label" htmlFor="emailAddress">
                  Email Address
                </label>
                <input
                    className={`insightsForm__input ${
                        errors.emailAddress ? "insightsForm__input--error" : ""
                    } `}
                    type="text"
                    {...register("emailAddress", {
                      required: true,
                      pattern:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                    })}
                    defaultValue={userData["emailAddress"]}
                    onChange={onUpdateField}
                    readOnly={true}
                />
                {errors.emailAddress && errors.emailAddress.type === "required" && (
                    <p className="insightsForm__error">This field is required.</p>
                )}
                {errors.emailAddress && errors.emailAddress.type === "pattern" && (
                    <p className="insightsForm__error">
                      Please enter a valid email address.
                    </p>
                )}
                <label className="insightsForm__label" htmlFor="dateOfBirth">
                  Date of Birth
                </label>
                {/*// @ts-ignore*/}
                <Controller
                    control={control}
                    name="dateOfBirth"
                    render={({field: {onChange, value}}) => {
                      return (
                          <DatePicker
                              name="dateOfBirth"
                              maxDate={
                                new Date(
                                    new Date().setFullYear(new Date().getFullYear() - 16)
                                )
                              }
                              minDate={
                                new Date(
                                    new Date().setFullYear(new Date().getFullYear() - 120)
                                )
                              }
                              selected={value ? value : new Date(userData["dateOfBirth"])}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              dateFormat={"dd/MM/yyyy"}
                              onChange={(date) => {
                                onChange(date);
                              }}
                              placeholderText="DD/MM/YYYY"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                          />
                      );
                    }}
                />
                <label className="insightsForm__label" htmlFor="favouriteTeam">
                  Favourite Team
                </label>
                <select
                    className={`insightsForm__dropdown ${
                        errors.favouriteTeam ? "insightsForm__dropdown--error" : ""
                    } `}
                    {...register("favouriteTeam", {
                      required: !favouriteTeam?.code?.length,
                    })}
                    value={favouriteTeam?.code ?? ""}
                    onChange={onUpdateField}
                >
                  {favouriteTeam ? (
                      <option
                          key={favouriteTeam.contentfulId}
                          value={favouriteTeam.code}
                      >
                        {favouriteTeam.name}
                      </option>
                  ) : (
                      <option value="" key="emptykey">
                        -- Please select --
                      </option>
                  )}
                  {teams?.map((contentfulTeam) => {
                    const skipSelectedTeam =
                        favouriteTeam?.code === contentfulTeam.code;
                    if (skipSelectedTeam) return null;

                    return (
                        <option
                            key={contentfulTeam.contentfulId}
                            value={contentfulTeam.code}
                        >
                          {contentfulTeam.name}
                        </option>
                    );
                  })}
                </select>

                {errors.favouriteTeam &&
                    errors.favouriteTeam.type === "required" && (
                        <p className="insightsForm__error">This field is required.</p>
                    )}
                <label className="insightsForm__label" htmlFor="country">
                  Country
                </label>
                <select
                    className={`insightsForm__dropdown ${
                        errors.country ? "insightsForm__dropdown--error" : ""
                    } `}
                    {...register("country", {required: true})}
                    defaultValue={countryName && countryName["name"]}
                >
                  <option
                      key={countryName && countryName["contentfulId"]}
                      value={countryName && countryName["iso"]}
                  >
                    {countryName && countryName["name"]}
                  </option>
                  {countries &&
                      countries.map((country) => {
                        return (
                            <option key={country.contentfulId} value={country.iso}>
                              {country.name}
                            </option>
                        );
                      })}
                </select>
                {errors.country && errors.country.type === "required" && (
                    <p className="insightsForm__error">This field is required.</p>
                )}
                <label
                    className="insightsForm__label insightsForm__label--not-required"
                    htmlFor="marketingPreferences"
                >
                  Marketing Consent
                </label>
                <p className="insightsForm__checkbox-text">
                  <input
                      type="checkbox"
                      {...register("newsletterConsent", {})}
                      className="insightsForm__custom-checkbox"
                      defaultChecked={userData["newsletterConsent"]}
                      onChange={handleChange}
                  />
                  I want to hear from SailGP about exclusive content, team news,
                  events and tickets.
                </p>
                <p className="insightsForm__checkbox-text">
                  <input
                      type="checkbox"
                      {...register("thirdPartyConsent", {})}
                      className="insightsForm__custom-checkbox"
                      defaultChecked={userData["thirdPartyConsent"]}
                      onChange={handleChange}
                  />
                  I want to hear about occasional news and offers from SailGP's
                  &nbsp;
                  <a
                      className="insightsForm__link"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.sailgp.com/about/partners/overview"
                  >
                    partners
                  </a>
                  .
                </p>
                <div></div>
                <div>
                  <button
                      className={`insightsForm__button insightsForm__button--submit ${
                          Object.keys(errors).length > 0
                              ? "insightsForm__button--disabled"
                              : ""
                      }`}
                      onClick={handleSubmit(onSubmit)}
                      type="submit"
                      disabled={Object.keys(errors).length > 0}
                  >
                    <span className="insightsForm__button__label">Save</span>
                  </button>
                </div>
                <div>
                  <button
                      onClick={() => history.push('/new-password-login')}
                      className="insightsForm__button insightsForm__button--primary"
                      aria-label="Reset Password"
                  >
                <span className="insightsForm__button__label">
                  Reset Password
                </span>
                  </button>
                </div>

                <div>
                  <button
                      onClick={() => history.push('/delete-account-confirm')}
                      className="insightsForm__button insightsForm__button--secondary"
                      aria-label="DeleteAccount"
                  >
                <span className="insightsForm__button__label">
                  Delete Account
                </span>
                  </button>
                </div>
              </form>
            </div>
        )}
      </div>
  );
};
export default SettingsPage;
