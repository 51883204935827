import {ExternalVideo} from "../../models/contentProxyModel";

type layoutDefinitions = {
    w: number,
    h: number,
    x: number,
    y: number,
    i: string,
    moved: boolean,
    static: boolean,
    isDraggable: boolean,
    isResizable: boolean,
    isBounded: boolean
}

export const createEventStreamsLayout = (streams: ( ExternalVideo|undefined )[] | undefined ) => {
    const baseLayout = { "moved": false,
        "static": true,
        "isDraggable": true,
        "isResizable": true,
        "isBounded": true}

    const layout : layoutDefinitions[]  = []
    streams?.forEach((stream, i) => {
        if(i < 4) {
            layout.push({
                "w": 3,
                "h": 8,
                "x": i * 3,
                "y": 0,
                "i": `eventStreamVideo${i + 1}`,
                ...baseLayout
            })
        }
        if(i >= 4 && i < 8) {
            layout.push({
                "w": 3,
                "h": 8,
                "x": (i - 4) * 3,
                "y": 8,
                "i": `eventStreamVideo${i + 1}`,
                ...baseLayout
            })
        }
        if(i >= 8 && i < 12) {
            layout.push({
                "w": 3,
                "h": 8,
                "x": (i - 8) * 3,
                "y": 16,
                "i": `eventStreamVideo${i + 1}`,
                ...baseLayout
            })
        }

    })
     return layout
}