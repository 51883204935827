import React from 'react';
import './popStatCard.scss';

const PopStatCard = (props: { data: { label: string; value: string | null, widthPercentage?: string }; }) => {

    return (
        <div className='popStatContainer'>
            <span className="sailgpWidgetAngle" style={{width: props.data.widthPercentage ? props.data.widthPercentage : '50%'}}/>
            <span className="popStatLabel">{props.data.label}</span>
            <span className="popStatValue">
                   {props.data.value}
            </span>
        </div>
    );
};

export default PopStatCard;
