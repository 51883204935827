import { createTheme, adaptV4Theme } from "@mui/material";

export const darkTheme = createTheme(adaptV4Theme({
    typography:{
        fontFamily:[
        'FoundersGroteskWeb', 
        '-apple-system', 
        'BlinkMacSystemFont', 
        'Segoe UI', 
        'Helvetica', 
        'Arial',
        'sans-serif', 
        'Apple Color Emoji', 
        'Segoe UI Emoji', 
        'Segoe UI Symbol',
        ].join(','),
    },
    palette: {
        mode: 'dark'
    }
}));
