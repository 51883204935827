import { ChatterTypeEnum } from "../../models/enums/chatterTypeEnum";

export interface AlertFilterOption {
    alertType: ChatterTypeEnum;
    displayText: string;
}
export const alertFilterOptions: AlertFilterOption[] = [
    {
        alertType: ChatterTypeEnum.MdssMsg,
        displayText: 'From MDSS Operator'
    },
    {
        alertType: ChatterTypeEnum.PROMsg,
        displayText: 'From race officer'
    },
    {
        alertType: ChatterTypeEnum.MarkRounding,
        displayText: 'Mark rounding times'
    },
    {
        alertType: ChatterTypeEnum.Penalty,
        displayText: 'Penalties'
    },
    {
        alertType: ChatterTypeEnum.Protest,
        displayText: 'Protests'
    },
    {
        alertType: ChatterTypeEnum.RaceStatus,
        displayText: 'Race status'
    },
    {
        alertType: ChatterTypeEnum.PenMsg,
        displayText: 'Reason for penalties'
    },
    {
        alertType: ChatterTypeEnum.TechMsg,
        displayText: 'Technical'
    },
    {
        alertType: ChatterTypeEnum.Other,
        displayText: 'Other'
    },
];