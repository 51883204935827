import React, {useContext} from "react";
import "./RaceCounterWidget.scss";
import {FormatTimeInHours} from "../../utils/helpers";
import {RaceDataContext} from "../../context/raceData.context";
import {TimestampContext} from "../../context/timestamp.context";
import {RaceStatusEnum} from "../../models/enums/raceStatusEnum";
import PopStatCard from '../../components/popStatCards';

const RaceCounterWidget = () => {
    const raceData = useContext(RaceDataContext);
    const [timestamp] = useContext(TimestampContext);

    let displayedTime: number | undefined | string;
    if (
        raceData &&
        timestamp &&
        (
            raceData.status === RaceStatusEnum.Started ||
            raceData.status === RaceStatusEnum.Finished
        )
    ) {
        displayedTime = Math.floor((timestamp - raceData.startTimeMs) / 1000);

        if (timestamp && (raceData.startTimeMs- timestamp) > 620000) displayedTime = '--';
    }

    return (
        <div className="raceCounterWidgetContainer">
            <PopStatCard data={{
                label: 'DURATION', value: displayedTime !== undefined
                    ? typeof displayedTime === 'string' ? displayedTime : FormatTimeInHours(displayedTime, false)
                    : "--"
            }}/>
        </div>
    );
};

export default RaceCounterWidget;
