import React, {PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useContentProxyData} from "../hooks/useContentProxyData";
import {
    ContentProxyAppSettings,
    ContentProxyNextRaceDateAndTime,
    ContentProxyRaceDay,
    ContentProxyRaceEvent,
    RaceDayBroadcastConfig,
    ContentProxyRaceLiveStream,
    ExternalVideo,
    ContentProxyRaceDayStatus
} from "../models/contentProxyModel";
import {CurrentRaceEventContext, CurrentRaceEventContextState, RaceTypeEnum} from "./currentRaceEvent.context";
import {RaceDataContext} from "./raceData.context";
import {BroadcasterConfigModel} from "../models/broadcasterConfigModel";
import {useIsMounted} from "../hooks/useIsMounted";
import {broadcasterConfig} from "../api/api";
import ReactGA from "react-ga";
import {ReactGaEnum} from "../models/enums/reactGAEnum";
import {isEqual} from "lodash"
import {LoginContext} from './login.context'
import {useHistory} from "react-router-dom";

const WHITELISTED_USERS: string[] =
    [
        "pogrady@sailgp.com",
        "wjones@sailgp.com",
        "jkim@sailgp.com",
        "rwalley@sailgp.com",
        "ltrafford@sailgp.com",
        "rclaesson@sailgp.com",
        "pbushell@sailgp.com",
        "agofton@sailgp.com",
        "jdibiase@sailgp.com",
        "athompson@sailgp.com",
        "rsmith@sailgp.com",
        "jlarkin@sailgp.com",
        "jmassie@sailgp.com",
    ]

export const CurrentRaceEventProvider = ({children}: PropsWithChildren<{}>) => {
    const raceData = useContext(RaceDataContext);
    const {user, isInternalUser, isVip} = useContext(LoginContext)
    const {data: globalAppSettings,} = useContentProxyData<ContentProxyAppSettings>('appsettings', 600000);
    const {data: events, requestsPending} = useContentProxyData<ContentProxyRaceEvent[]>('races', 60000);
    const {data: nextContentfulEvent} = useContentProxyData<ContentProxyNextRaceDateAndTime>('next-race', 600000);
    const {data: raceLiveStreams} = useContentProxyData<ContentProxyRaceLiveStream[]>('raceLiveStreams', 60000)
    const [currentEvent, setCurrentEvent] = useState<ContentProxyRaceEvent>();
    const [raceType, setRaceType] = useState<RaceTypeEnum>();
    const [selectedRaceDayId, setSelectedRaceDayId] = useState<string>();
    const [broadcasterRegionalConfig, setBroadcasterRegionalConfig] = useState<BroadcasterConfigModel>();
    const [isRaceFinished, setIsRaceFinished] = useState<boolean>(false)
    const [broadcastAvailable, setBroadcastAvailable] = useState<boolean>(false);

    const isMountedRef = useIsMounted();
    let history = useHistory();

    const fetchBroadcasterConfig = useCallback(async () => {
        try {
            const response = await fetch(broadcasterConfig.baseUrl);
            const config: BroadcasterConfigModel = await response.json();
            if (!isMountedRef.current) {
                return;
            }
            if (config.streamsId) {
                setBroadcasterRegionalConfig(config);
            }
        } catch (e) {
            console.error('Failed to fetch broadcaster config: ', e);
        }
    }, []);

    useEffect(() => {
        fetchBroadcasterConfig();
    }, [isMountedRef]);


    const allRaceEvents = useMemo((): ContentProxyRaceEvent[] | undefined => {
        if (!events) {
            return undefined;
        }

        //handle practice race days
        let raceEventsToPublish = events.map(event => {
            if (!event.raceDays) {
                return event
            }

            let raceDaysToDisplay: ContentProxyRaceDay[] = []
            event.raceDays.forEach((raceDay, i) => {
                if (!raceDay.raceDayBroadcastConfig || raceDay.raceDayBroadcastConfig.length === 0) {
                    raceDaysToDisplay.push(raceDay)
                } else if (raceDay.raceDayBroadcastConfig.includes(RaceDayBroadcastConfig.InsightsWebAllUsers)) {
                    raceDaysToDisplay.push(raceDay)
                } else if (raceDay.raceDayBroadcastConfig.includes(RaceDayBroadcastConfig.InsightsWebInternal)
                    && (isInternalUser || isVip)) {
                    raceDaysToDisplay.push(raceDay)
                }
                event.raceDays = raceDaysToDisplay
            })
            return event
        })

        // @ts-ignore
        return raceEventsToPublish.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime))
    }, [events]);

    useEffect(() => {
        const latestCurrentEvent: ContentProxyRaceEvent | undefined = allRaceEvents?.filter(raceEvent => raceEvent.contentfulId === currentEvent?.contentfulId)[0]
        if (latestCurrentEvent && Object.keys(latestCurrentEvent).length && !isEqual(latestCurrentEvent, currentEvent)) {
            setCurrentEvent(latestCurrentEvent)
        }
    }, [allRaceEvents])

    const raceLiveStreamsSettings = useMemo((): ContentProxyRaceLiveStream | undefined => {
        if (!raceLiveStreams) {
            return undefined;
        }
        return raceLiveStreams[0];
    }, [raceLiveStreams]);

    const nextScheduledEventDateAndTime = useMemo((): ContentProxyNextRaceDateAndTime | undefined => {
        if (!nextContentfulEvent) {
            return undefined;
        }
        return nextContentfulEvent;
    }, [nextContentfulEvent]);

    const appSettings = useMemo((): ContentProxyAppSettings | undefined => {
        if (!globalAppSettings) {
            return undefined;
        }
        return globalAppSettings;
    }, [globalAppSettings]);

    const switchRaceEvent = useCallback(async (eventId: string | undefined, raceDayId: string | undefined, isLiveRaceDay: boolean) => {
        setRaceType(isLiveRaceDay ? RaceTypeEnum.live : RaceTypeEnum.replay)
        if (!allRaceEvents) return [];

        const selectedEvent = allRaceEvents.filter(e => e.contentfulId === eventId);
        setSelectedRaceDayId(raceDayId);
        setCurrentEvent(selectedEvent[0]);
        if (raceData && raceData.clearRaceDataDueToUserRaceSwitch) {
            raceData.clearRaceDataDueToUserRaceSwitch()
        }

        ReactGA.event({
            category: ReactGaEnum.SwitchRaceEvent,
            action: `Race Event Selected on ${selectedEvent[0].name} in Season ${selectedEvent[0].eventSeason}`
        });

    }, [allRaceEvents, raceData]);


    const nextEvent = useMemo((): ContentProxyRaceEvent | undefined => {
        if (!events || events.length === 0) {
            return undefined;
        }

        const unfinishedEvents = events.filter(e => new Date(e.endDateTime) > new Date());

        if (unfinishedEvents.length === 0) {
            return undefined;
        }

        return unfinishedEvents.reduce((earliest, currentEvent) =>
            new Date(currentEvent.startDateTime) < new Date(earliest.startDateTime)
                ? currentEvent
                : earliest
        );
    }, [events]);


    const previousRaceEvent = useMemo((): ContentProxyRaceEvent | undefined => {
        if (!events || events.length === 0) {
            return undefined;
        }

        const finishedEvents = events.filter(e => new Date(e.endDateTime) <= new Date())

        if (finishedEvents.length === 0) {
            return undefined;
        }

        return finishedEvents[finishedEvents.length - 1]

    }, [events]);


    const streamsAvailableToUser = useMemo((): (ExternalVideo | undefined)[] | undefined => {
        const currentTime = new Date().valueOf() / 1000
        const threeDaysInSeconds = 259200

        let nextEventStartDate
        let previousEventEndDate
        if (nextEvent && previousRaceEvent) {
            nextEventStartDate = new Date(nextEvent.startDateTime).valueOf() / 1000
            previousEventEndDate = new Date(previousRaceEvent.endDateTime).valueOf() / 1000
        }

        let isUpcomingEvent
        if (nextEventStartDate && previousEventEndDate) {
            isUpcomingEvent = currentTime >= nextEventStartDate - threeDaysInSeconds || currentTime <= previousEventEndDate + threeDaysInSeconds
        }

        let mostRecentEventIndex
        let mostRecentEvent
        if (events) {
            mostRecentEventIndex = events?.findIndex((event) => event.name === nextEvent?.name) - 1
            mostRecentEvent = events[mostRecentEventIndex]
        }

        let mostRecentEventStartDate
        let mostRecentEventEndDate
        if (mostRecentEvent) {
            mostRecentEventStartDate = new Date(mostRecentEvent.startDateTime).valueOf() / 1000
            mostRecentEventEndDate = new Date(mostRecentEvent.endDateTime).valueOf() / 1000
        }

        let isMostRecentEventOngoing
        if (mostRecentEventStartDate && mostRecentEventEndDate) {
            isMostRecentEventOngoing = currentTime >= mostRecentEventStartDate - threeDaysInSeconds && currentTime <= mostRecentEventEndDate + threeDaysInSeconds
        }

        if (isUpcomingEvent || isMostRecentEventOngoing) {
            if (raceLiveStreams) {
                const availableStreams = raceLiveStreams[0].eventStreams.filter(stream => {
                    if (!stream?.enhancedPermissionsRequired) return stream

                    if (stream.enhancedPermissionsRequired && user?.emailAddress && WHITELISTED_USERS.includes(user?.emailAddress)) return stream

                    return false;
                })
                return availableStreams
            }
        }

        return undefined

    }, [raceLiveStreams, user, nextEvent])

    const resetEvent = () => {
        setSelectedRaceDayId(undefined)
        setCurrentEvent(undefined)
    }

    useEffect(() => {
        const currentRaceDay = currentEvent?.raceDays?.filter(raceDay => {
            return new Date().getTime() <= new Date(raceDay.endDateTime).getTime() && new Date().getTime() >= new Date(raceDay.startDateTime).getTime();
        })

        const finishState = (currentRaceDay && currentRaceDay[0]?.state === ContentProxyRaceDayStatus.Finished) || false;

        if (currentEvent) setIsRaceFinished(finishState)

    }, [currentEvent])

    useEffect(() => {
        if (!broadcasterRegionalConfig) return

        const millisecondsInHour = 3600000;
        const currentDateToCompare = new Date();

        const currentEventEndDateAndTime = currentEvent && new Date(currentEvent?.endDateTime);
        if (!broadcasterRegionalConfig?.replayDelayHours && currentEventEndDateAndTime && currentDateToCompare.getTime() > currentEventEndDateAndTime.getTime()) {
            setBroadcastAvailable(true)
        }

        if (broadcasterRegionalConfig.replayDelayHours && currentEventEndDateAndTime) {
            currentDateToCompare.getTime() > (currentEventEndDateAndTime.getTime() + (broadcasterRegionalConfig?.replayDelayHours * millisecondsInHour))
                ? setBroadcastAvailable(true)
                : setBroadcastAvailable(false)
        }
        return () => setBroadcastAvailable(false);
    }, [currentEvent?.endDateTime, broadcasterRegionalConfig, broadcasterRegionalConfig?.replayDelayHours])


    const contextState = useMemo((): CurrentRaceEventContextState => ({
        currentEvent,
        appSettings,
        isLoading: (!currentEvent && history.location.pathname !== '/eventStreams') && requestsPending,
        allRaceEvents,
        selectedRaceDayId,
        switchRaceEvent,
        raceType,
        nextEvent,
        nextScheduledEventDateAndTime,
        broadcasterRegionalConfig,
        resetEvent,
        streamsAvailableToUser,
        raceLiveStreamsSettings,
        isRaceFinished,
        broadcastAvailable,
        setBroadcastAvailable
    }), [appSettings, currentEvent, requestsPending, selectedRaceDayId, broadcasterRegionalConfig, streamsAvailableToUser, broadcastAvailable]);


    return (
        <CurrentRaceEventContext.Provider value={contextState}>
            {children}
        </CurrentRaceEventContext.Provider>
    );
};
