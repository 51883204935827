import React, {useContext, useMemo} from "react";
import {ChatterTypeEnum} from "../../../models/enums/chatterTypeEnum";
import {ReactComponent as OutOfRaceTeamIcon} from "../../../assets/leaderboard-team-icon-out-of-race.svg";
import {ReactComponent as MarkRoundingIcon} from "../../../assets/mark-24-px.svg";
import {ReactComponent as ProMessageIcon} from "../../../assets/proMsg.svg";
import {ReactComponent as TechMessageIcon} from "../../../assets/techMsg.svg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoIcon from "@mui/icons-material/Info";
import {DateTime} from "luxon";
import {ChatterAlertsContext} from "../../../context/chatterAlerts.context";
import {AwsChatterEntryModel} from "../../../models/awsDataModel";
import {CurrentRaceEventContext} from "../../../context/currentRaceEvent.context";


import "./AlertDisplay.scss";

export interface AlertDisplayProps {
    displayedAlertTypes: string[];
}

export const AlertDisplay = (props: AlertDisplayProps) => {
    const chatterAlerts = useContext(ChatterAlertsContext);

    const {currentEvent} = useContext(CurrentRaceEventContext);


    const displayedMessages = useMemo(() => {
        if (props.displayedAlertTypes.length > 0) {
            return chatterAlerts.filter(m => props.displayedAlertTypes.includes(m.chatterType));
        } else {
            return chatterAlerts;
        }
    }, [chatterAlerts, props.displayedAlertTypes]);

    function renderEventLocalTime(message: AwsChatterEntryModel): string {
        if (!currentEvent) {
            return '--';
        }
        return DateTime
            .fromMillis(message.time)
            .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .toFormat('HH:mm:ss');
    }

    return (
        <div className="alertDisplay">
            {displayedMessages.map((message, i) => (
                <li className="alertListItem" key={i}>
                    <span className="alert">
                        <span>
                            {(() => {
                                switch (message.chatterType) {
                                    case ChatterTypeEnum.MdssMsg:
                                        return (
                                            <OutOfRaceTeamIcon className="alertIcons boatIcons"/>
                                        );
                                    case ChatterTypeEnum.RaceStatus:
                                        return (
                                            <AccessTimeIcon className="alertIcons clockIcon"/>
                                        );
                                    case ChatterTypeEnum.MarkRounding:
                                        return (
                                            <MarkRoundingIcon className="alertIcons markRounding"/>
                                        );
                                    case ChatterTypeEnum.Penalty:
                                    case ChatterTypeEnum.Protest:
                                    case ChatterTypeEnum.PenMsg:
                                    case ChatterTypeEnum.Other:
                                        return (
                                            <span className={`alertIcons ${message.chatterType}`}>
                                                P
                                            </span>
                                        );
                                    case ChatterTypeEnum.PROMsg:
                                        return (
                                            <ProMessageIcon className="alertIcons personIcon"/>
                                        );
                                    case ChatterTypeEnum.None:
                                        return (
                                            <InfoIcon className="alertIcons infoIcon"/>
                                        );
                                    case ChatterTypeEnum.TechMsg:
                                        return (
                                            <TechMessageIcon className="alertIcons controlCameraIcon"/>
                                        );
                                    default:
                                        return null;
                                }
                            })()}
                        </span>
                        <span className="wrapper">
                            <span className="alertMessage">
                                {message.text}
                            </span>
                            <span className="alertTime">
                                {renderEventLocalTime(message)}
                            </span>
                        </span>
                    </span>
                </li>
            ))}
        </div>
    );
};
