import React, {useContext, useMemo, useState} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import "./VideoSelector.scss";
import {makeStyles} from "@mui/styles";
import {getStreamIdTitle} from "../../../../utils/getStreamIdTitle";
import {StreamDetailsContext} from "../../../../context/streamDetails.context";
import {RaceTypeEnum} from "../../../../context/currentRaceEvent.context";
import clsx from "clsx";
import {BroadcasterConfigModel} from "../../../../models/broadcasterConfigModel";

const useStyles = makeStyles({
    selectorBox: {
        border: "1px solid white",
        transform: "skewX(-10deg)",
        maxWidth: "50%"
    }
});

export interface AutocompleteOption {
    streamId: string;
    title: string;
}

export interface VideoSelectorProps {
    value: string | undefined;
    onChange: (streamId: string | undefined) => void;
    disabled?: boolean;
    raceType: RaceTypeEnum | undefined
    broadcasterRegionalConfig: BroadcasterConfigModel | undefined
}

export const VideoSelector = (props: VideoSelectorProps) => {
    const classes = useStyles();
    const streamDetails = useContext(StreamDetailsContext);
    const [inputValue, setInputValue] = useState("");

    const autocompleteOptions = useMemo((): AutocompleteOption[] | undefined => {
        if (streamDetails?.streamIds) {
            const streams = streamDetails.streamIds?.filter((stream: string) => stream !== "tata_sailgpspanishtv_ci_4bc9c1e6-bc42-4baf-9b29-fef6260933d4")
            return streams.map(id => ({
                streamId: id,
                title: getStreamIdTitle(id) ?? id
            }));

        } else if (streamDetails?.replayStreams) {
            return streamDetails.replayStreams.streams.map(stream => ({
                streamId: stream.url,
                title: stream.name
            }));
        } else {
            return undefined;
        }
    }, [streamDetails?.streamIds, streamDetails?.replayStreams]);

    autocompleteOptions?.sort((stream1, stream2) =>
        (stream1.title > stream2.title) ? 1 : -1)
        .sort((stream1) => (stream1.title === "Event TV") ? -1 : 1)
        .sort((stream1) => (stream1.title === "Helicopter") ? -1 : 1)
        .sort((stream1) => (stream1.title === "UMPAPP") ? -1 : 1)
        .sort((stream1) => (stream1.title === "World Feed") ? -1 : 1);

    const currentOption = useMemo((): AutocompleteOption | null =>
            autocompleteOptions?.find(opt => opt.streamId === props.value) ?? null
        , [autocompleteOptions, props.value]);

    return (
        <div
            className={clsx("videoSelectorContainer", {'liveRaceView': props.raceType === RaceTypeEnum.live && props?.broadcasterRegionalConfig?.broadcasterLogoUrl})}>
            {props?.broadcasterRegionalConfig?.broadcasterLogoUrl && props.raceType === RaceTypeEnum.live &&
                <div className="broadcasterImageWrapper">


                <span className="dot"></span>
                <span className="liveOn">Live on</span>
                <img
                    src={props?.broadcasterRegionalConfig?.broadcasterLogoUrl}
                    className="broadcasterLogo"
                    alt={`${props?.broadcasterRegionalConfig?.broadcasterName} logo`}
                />
            </div>
            }


            <Autocomplete<AutocompleteOption, undefined, boolean>
                className={classes.selectorBox}
                value={currentOption}
                disabled={props.disabled}
                onChange={(event, newValue) => props.onChange(newValue?.streamId)}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={autocompleteOptions ?? []}
                getOptionLabel={o => o.title}
                style={{width: 192}}
                disableClearable={true}
                noOptionsText={'No Video streams available in your region'}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined"/>
                )}
            />
        </div>
    );
};
