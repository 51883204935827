export const environmentVariables = {
    awsDataRoot: () => process.env.REACT_APP_AWS_ROOT,
    contentProxyRoot: () => process.env.REACT_APP_CONTENT_PROXY_ROOT,
    contentProxyUsername: () => process.env.REACT_APP_CONTENT_PROXY_USERNAME,
    contentProxyPassword: () => process.env.REACT_APP_CONTENT_PROXY_PASSWORD,
    googleAnalyticsMeasurementId: () => process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
    slackBotToken: () => process.env.REACT_APP_SLACK_BOT_TOKEN,
    googleCredentials: () => process.env.REACT_APP_GOOGLE_CREDENTIALS,
    userPermissions: () => process.env.REACT_APP_PERMISSIONS,
    appEnv: () => process.env.REACT_APP_ENV,
    contentfulEnv: () => process.env.REACT_APP_CONTENT_PROXY_ENV,
    specialVersion: () => process.env.REACT_APP_SPECIAL_VERSION
};
